import React from 'react';
import PropTypes from 'prop-types';
import AltColorPreview from './alt-color-preview.jsx';
import clsx from 'clsx';

import './alt-palette.css';

var CHROMA = [1, 0.05, 0.25, 0.5, 4, 2];

class AltPalette extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			chromaOpts: []
		};
	}

	static getDerivedStateFromProps(np, state) {
		if (!np.showAlternatives) {
			state.chromaOpts = [];
			return state;
		}

		if (state.chromaOpts.length < 1) {
			state.chromaOpts = CHROMA;
			return  state;
		}

		return null;
	}

	componentDidMount() {
		if (this.props.showAlternatives) {
			this.setState({chromaOpts: CHROMA});
		}
	}

	renderImages(chromaOpts) {
		var style = {height: chromaOpts.length > 1 ? '80%' : '100%', marginBottom: 5};
		return chromaOpts.map((cr, i) =>
			<React.Fragment key={i}>
				<AltColorPreview paletteSvc={this.props.paletteSvc} editable svg={this.props.svg} onSelect={this.props.onSelect} chroma={cr} hue={1} style={style} />
			</React.Fragment>
		);
	}

	render() {
		if (this.state.chromaOpts.length < 1) {return null;}
		return (
			<div className={clsx('alt-palette', this.props.className)}>
				{this.renderImages(this.state.chromaOpts)}
			</div>
		);
	}
}

AltPalette.propTypes = {
	paletteSvc: PropTypes.object.isRequired,
	className: PropTypes.string,
	svg: PropTypes.string,
	showAlternatives: PropTypes.bool,
	onSelect: PropTypes.func
};

AltPalette.defaultProps = {
	className: '',
	svg: '',
	showAlternatives: false
};

export default AltPalette;
