import React from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from '../../../lib/constants.js';
import { FileDrop, messageBox } from 'ui-core';
import Form from '../../form/form.jsx';
import AssetGalleryTile from '../asset-gallery-tile/asset-gallery-tile.jsx';

import './new-asset-view.css';

const MIME_TYPES = {
	[CONSTANTS.AssetTypes.Image]: 'image/jpeg,image/png',
	[CONSTANTS.AssetTypes.Video]: 'video/mp4',
	[CONSTANTS.AssetTypes.PDF]: 'application/pdf'
};

class NewAssetView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: {
				name: '',
				filename: ''
			}
		};

		this.form = null;
		this.file = null;
		this.previewUrl = '';
	};

	getMimeTypes() {
		var mimes = this.props.assetTypes.map(t => MIME_TYPES[t]).filter(String).join(',');
		return mimes;
	}

	getAssetType(mime) {
		if (!mime) { return; }
		let a = Object.keys(MIME_TYPES).filter(k => MIME_TYPES[k].includes(mime.toLowerCase()))[0];
		return a;
	}

	async getFileMetadata(url, type) {
		return new Promise(res => {
			if (type.startsWith('video')) {
				var vid = document.createElement('video');
				vid.src = url;
				vid.addEventListener('loadedmetadata', () => {
					let aspectRatio = (vid.videoWidth / vid.videoHeight);
					aspectRatio = Math.round(aspectRatio * 100) / 100;
					let duration = vid.duration;

					res({aspectRatio, duration});
				});
			} else {
				res(0);
			}
		});
	}

	clear() {
		this.file = null;
		this.previewUrl = null;
		this.setState({data: {name: '', filename: ''}});
	}

	commit() {
		if (this.file === null) {
			messageBox('File is required');
			return;
		}

		this.form.submit();
	}

	onSubmit(formData) {
		if (!formData) {
			return;
		}

		formData.file = this.file;
		this.props.onNewFileCommitted(formData);
	}

	async onFileDrop(file) {
		if (!file) { return; }
		window.URL.revokeObjectURL(this.file);
		this.file = file;
		this.previewUrl = window.URL.createObjectURL(this.file);
		let metadata = await this.getFileMetadata(this.previewUrl, this.file.type);
		this.file.aspectRatio = metadata.aspectRatio;
		this.file.duration = metadata.duration;

		let newFilename = this.file.name || '';
		let newName = newFilename.replace(/\.[^.]*$/, '');

		// If the user entered a name manually (meaning its not derived from filename), we don't change it
		let currDisplayFilename = this.state.data.filename.replace(/\.[^.]*$/, '');
		if (this.state.data.name && this.state.data.name !== currDisplayFilename) {
			newName = this.state.data.name;
		}

		this.setState({ data: { name: newName, filename: newFilename } });
	}

	componentWillUnmount() {
		window.URL.revokeObjectURL(this.file);
		this.file = null;
	}

	render() {
		var schema = {
			type: 'object',
			required: ['name'],
			properties: {
				id: { type: 'string', readOnly: true },
				filename: {type: 'string', readOnly: true},
				name: { type: 'string' },
			}
		};

		return (
			<div className="new-asset-view">
				<Form
					ref={f => { this.form = f; }}
					schema={schema}
					showButtons={false}
					formData={this.state.data}
					buttonsAlign='right'
					submit={this.onSubmit.bind(this)}
					onChange={data => this.setState({data})}
				/>
				{this.previewUrl &&
					<div style={{marginBottom: 10}}>
						<AssetGalleryTile
							allowEdit = { false }
							asset = {{type: this.getAssetType(this.file.type), src: this.previewUrl}}
						/>
					</div>
				}
				<div className='file-drop'>
					<FileDrop
						acceptMimes={this.getMimeTypes()}
						maxFileSize={this.props.maxFileSize}
						fileDrop={this.onFileDrop.bind(this)}/>
				</div>
			</div>
		);
	}
}

NewAssetView.propTypes = {
	assetTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
	maxFileSize: PropTypes.number,
	onNewFileCommitted: PropTypes.func,
};

function nop() { }
NewAssetView.defaultProps = {
	onChange: nop,
	onNewFileCommitted: nop,
};

export default NewAssetView;
