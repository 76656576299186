import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from '../../../lib/constants.js';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ToolbarIcon, snackbar } from 'ui-core';
import AssetPickerPreview from '../../media-asset-picker/asset-picker-preview.jsx';
import MediaAssetModal from '../../media-asset-picker/media-asset-modal/media-asset-modal.jsx';
import { utils } from 'client-services';

const IMG_MAX_SIZE = 1 * 1024 * 1024; // 1MB
const VID_MAX_SIZE = 1000 * 1024 * 1024; // 1GB

const useClasses = makeStyles()(theme => ({
	typesRadioGroup: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		'& > span': {
			marginRight: 10
		}
	},
	typeTitle: {
		color: '#646464',
		borderBottom: '1px solid #ccc',
		marginBottom: 10,
	},
	preview: {
		display: 'flex',
		flexDirection: 'row',
		'& > div': {
			marginRight: 40
		}
	},
	aspectData: {
		marginTop: 7,
		fontSize: '0.8em',
		color: '#777777'
	}
}));

function getThumbnailUrl(mediaAsset) {
	let url;
	if (mediaAsset.urlTemplate) {
		url = mediaAsset.urlTemplate.replace('%TRANS%', 't_media');
	}

	url = url || mediaAsset.thumbnail || mediaAsset.url;
	return url;
}

function ExpPreviewField(props) {
	let { classes } = useClasses();
	var [pickerType, setPickerType] = useState('');

	var { formData, registry } = props;
	var TitleField = registry.templates.TitleFieldTemplate;

	formData = formData || {};
	var { type } = formData;

	function onTypeChange(e) {
		let type = e.target.value;
		props.onChange(utils.extend(true, {}, formData, { type }));
	}

	function onAssetPickerClose(mediaAsset) {
		if (!mediaAsset?.url) {
			setPickerType('');
			return;
		}

		let data = {
			params: {}
		};

		// Make sure we don't override the aspect ratio of video poster with video
		// aspect ratio.
		if (pickerType === CONSTANTS.AssetTypes.Image) {
			data.aspectRatio = mediaAsset.aspectRatio || 0;
		}

		if (type === CONSTANTS.ExperiencePreviewType.Image) {
			if (pickerType === CONSTANTS.AssetTypes.Image) {
				data.params.url = getThumbnailUrl(mediaAsset);
			}
		} else {
			// For Video we set the appropriate params based on the picker used
			if (pickerType === CONSTANTS.AssetTypes.Image) {
				data.params.poster = getThumbnailUrl(mediaAsset);
			} else if (pickerType === CONSTANTS.AssetTypes.Video) {
				data.params.url = mediaAsset.url;
			}
		}

		props.onChange(utils.extend(true, {}, formData, data));

		setPickerType('');
	}

	return (
		<div>
			<TitleField title="Preview" />
			<Typography variant="caption" color="textSecondary">Experience page top element</Typography>
			<RadioGroup className={classes.typesRadioGroup} value={type || ''} onChange={onTypeChange}>
				<span>Element Type:</span>
				<FormControlLabel value={CONSTANTS.ExperiencePreviewType.Image} control={<Radio />} label="Image" />
				<FormControlLabel value={CONSTANTS.ExperiencePreviewType.Video} control={<Radio />} label="Video" />
			</RadioGroup>
			{type &&
				<div>
					<div className={classes.preview}>
						<MediaField
							uiElement={formData}
							assetType={CONSTANTS.AssetTypes.Image}
							openPicker={setPickerType}
						/>
						{type === CONSTANTS.ExperiencePreviewType.Video &&
							<MediaField
								uiElement={formData}
								assetType={CONSTANTS.AssetTypes.Video}
								openPicker={setPickerType}
							/>
						}
					</div>
					<div className={classes.aspectData}>
						<span>Aspect Ratio: { formData.aspectRatio }</span>
					</div>
				</div>
			}
			<MediaAssetModal
				open={!!pickerType}
				isExperience={pickerType === CONSTANTS.AssetTypes.Video} // Videos should go from the "experiences assets"
				maxFileSize={pickerType === CONSTANTS.AssetTypes.Image ? IMG_MAX_SIZE : VID_MAX_SIZE}
				mode={CONSTANTS.AssetViewModes.Edit}
				assetTypes={[pickerType]}
				onClose={onAssetPickerClose}
			/>
		</div>
	);
}

ExpPreviewField.propTypes = {
	registry: PropTypes.shape({
		templates: PropTypes.object.isRequired,
	}).isRequired,
	formData: PropTypes.object,
	onChange: PropTypes.func
};

export default ExpPreviewField;

function MediaField(props) {
	let { classes } = useClasses();
	var { uiElement, assetType, openPicker } = props;
	var { url, poster } = (uiElement.params || {});
	var src = url;
	if (uiElement.type === CONSTANTS.ExperiencePreviewType.Video && assetType === CONSTANTS.AssetTypes.Image) {
		src = poster;
	}

	var title = (assetType === CONSTANTS.AssetTypes.Image) ? 'Poster' : 'Video';
	return (
		<div>
			<div className={classes.typeTitle}>
				<span>{title}</span>
				<ToolbarIcon key="library_add" name="library_add" title="Manage" onClick={e => {
					e.stopPropagation();
					openPicker(assetType);
				}} />
				{src && <ToolbarIcon outlined key="content_copy" name="content_copy" title="Copy URL" onClick={e => {
					if (utils.copyToClipboard(src)) {
						snackbar('URL Copied');
					}
				}} />}
			</div>
			<AssetPickerPreview
				type={assetType}
				src={src}
				onClick={() => openPicker(assetType)}
			/>
		</div>
	);
}

MediaField.propTypes = {
	uiElement: PropTypes.object.isRequired,
	assetType: PropTypes.string.isRequired,
	openPicker: PropTypes.func.isRequired
};
