.layer-element {
    width: 200px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
}

.layer-element .toolbar {
    display: flex;
    margin-top: 5px;
    margin-bottom: 10px;
}

.layer-element .toolbar .text {
    flex:2;
}

.layer-element.new {
    height: 100%;    
}