import React from 'react';
import CONSTANTS from '../../../lib/constants.js';
import EntityPickerController from './entity-picker-controller.jsx';
import { api } from 'client-services';

function GalleryPicker(props) {
	return (
		<EntityPickerController
			entityName="image"
			getEntitiesApi={api.gallery.getImages}
			toModel={res => res.images.map(e => ({
				id: e.id,
				src: e.thumbnail,
				title: e.name
			}))}
			{...props}
		/>
	);
}

function FeedPicker(props) {
	return (
		<EntityPickerController
			entityName="feed"
			getEntitiesApi={api.feed.getFeeds}
			toModel={res => res.feeds.map(e => ({
				id: e.id,
				src: e.thumbnail || '', // ffu
				title: e.name
			}))}
			{...props}
		/>
	);
}

function ExperiencePicker(props) {
	return (
		<EntityPickerController
			entityName="experience"
			searchSchema={{
				name: {},
				tags: {},
				medium: {},
				status: { enum: Object.keys(CONSTANTS.ExperienceStatus) },
				accessLevel: { enum: Object.keys(CONSTANTS.ExperienceAccessLevel) },
				id: { $ref: '#/definitions/MongoObjectID' },
			}}
			getEntitiesApi={api.experience.getExperiences}
			toModel={res => res.experiences.map(e => ({
				id: e.id,
				src: e.thumbnail,
				title: e.name
			}))}
			{...props}
		/>
	);
}

function ExperienceFeedPicker(props) {
	return (
		<EntityPickerController
			entityName="experienceFeed"
			getEntitiesApi={api.experience.getExperienceFeeds}
			toModel={res => res.experienceFeeds.map(e => ({
				id: e.id,
				src: e.thumbnail || '', // ffu
				title: e.name
			}))}
			{...props}
		/>
	);
}

function ToolPicker(props) {
	return (
		<EntityPickerController
			entityName="tool"
			getEntitiesApi={api.experience.getTools}
			showFilter={true}
			toModel={res => res.tools.map(e => ({
				id: e.id,
				src: e.thumbnail,
				title: e.name
			}))}
			{...props}
		/>
	);
}

function InstructorPicker(props) {
	return (
		<EntityPickerController
			entityName="instructor"
			getEntitiesApi={api.experience.getInstructors}
			toModel={res => res.instructors.map(e => ({
				id: e.id,
				src: e.avatar,
				title: e.name
			}))}
			{...props}
		/>
	);
}

function PaperBackgroundPicker(props) {
	return (
		<EntityPickerController
			entityName="paperBackground"
			getEntitiesApi={api.experience.getPaperBackgrounds}
			toModel={res => res.paperBackgrounds.map(e => ({
				id: e.id,
				src: e.thumbnail,
				title: e.name
			}))}
			{...props}
		/>
	);
}

export { GalleryPicker };
export { FeedPicker };
export { ExperiencePicker };
export { ExperienceFeedPicker };
export { ToolPicker };
export { InstructorPicker };
export { PaperBackgroundPicker };
