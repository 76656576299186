import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { ToolbarIcon } from 'ui-core';
import * as EntityPickers from '../../entity-picker';

function SingleEntityPickerField(props) {
	var [pickerOpen, setPickerOpen] = useState(false);
	var { formData, registry, schema, uiSchema } = props;
	var TitleField = registry.templates.TitleFieldTemplate;
	var title = uiSchema['ui:title'] || schema.title || '';
	var desc = uiSchema['ui:description'] || schema.description || '';
	var opts = uiSchema['ui:options'] || {};

	function onClose(selected) {
		if (Array.isArray(selected)) {
			var data = selected.map(entity => entity.id);
			props.onChange(data[0]); // it's ok to be undefined if none selected
		}

		setPickerOpen(false);
	}

	let PickerComp = EntityPickers[opts.picker];
	if (!PickerComp) {
		throw new Error(`Picker ${opts.picker} not found, make sure to pass the picker under ui:options`);
	}

	return (
		<div>
			<TitleField title={
				<div>
					<span style={{ marginRight: 15, verticalAlign: 'middle' }}>{ title }</span>
					<ToolbarIcon key="library_add" name="library_add" title="Manage" onClick={e => {
						e.stopPropagation();
						setPickerOpen(true);
					}} />
				</div>}
			/>
			{desc && <div style={{ marginBottom: 10 }}>
				<Typography variant="caption" color="textSecondary">
					{desc}
				</Typography>
			</div>}
			<PickerComp
				showPreSelectedPreview={true}
				showPicker={pickerOpen}
				openPickerOnSelected={false}
				multiSelect={false}
				preSelected={formData ? [formData] : []}
				onCommit={onClose}
			/>
		</div>
	);
}

SingleEntityPickerField.propTypes = {
	registry: PropTypes.shape({
		templates: PropTypes.object.isRequired,
	}).isRequired,
	formData: PropTypes.string,
	schema: PropTypes.object,
	uiSchema: PropTypes.object,
	onChange: PropTypes.func
};

export default SingleEntityPickerField;
