import { makeStyles } from 'tss-react/mui';

const useClasses = makeStyles()(theme => ({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',

		'&.debug-mode .debug-pane': {
			display: 'block'
		},

		'.content': {
			flex: 1,
			overflow: 'auto',
			display: 'flex',
			flexDirection: 'row',
			userSelect: 'none'
		},

		'.qb': {
			overflow: 'auto',
			padding: 10,
			flex: 1,

			'.queryBuilder .ruleGroup.queryBuilder-invalid': {
				backgroundColor: '#ff000060',

				'& > .ruleGroup-header::after': {
					content: '"Empty groups are considered invalid. Avoid them by using addRuleToNewGroups."',
					color: 'white'
				},
			},

			'.queryBuilder .rule.queryBuilder-invalid .rule-value': {
				backgroundColor: '#ff000060'
			}
		},

		'.debug-pane': {
			display: 'none',
			overflow: 'auto',
			padding: 10,
			userSelect: 'text',
			fontSize: '0.9em'
		},

		'.buttons': {
			margin: '10px 20px 5px 0',
			display: 'flex',

			'& > button': {
				marginLeft: 10
			},

			'.chk-dbg': {
				marginLeft: 10
			},

			'.ai': {
				marginRight: 'auto',
			}
		}
	},
}));

export default useClasses;
