.asset-picker-view {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.asset-picker-view .view {
    flex: 1;
    overflow: auto;
}

.asset-picker-view .toolbar {
    padding: 5px 10px;
}