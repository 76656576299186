import React from 'react';
import PropTypes from 'prop-types';
import {messageBox, FileDrop} from 'ui-core';
import clsx from 'clsx';

const MAX_FILE_SIZE = 2 * 1024 * 1024;
const RES = [[1020, 1020], [1440, 1020], [1020, 1440]];

import './image-dropzone.css';

var DROP_IMAGE_TYPES = 'image/jpeg,image/png,image/gif,image/svg+xml';

class ImageDropZone extends React.Component {
	constructor(props) {
		super(props);

		this.onImageDropped = this.onImageDropped.bind(this);
	}

	loadImage(file) {
		let preview = window.URL.createObjectURL(file);
		var img = document.createElement('img');
		img.onload = async() => {
			let matchRes = RES.some(r => img.naturalWidth === r[0] && img.naturalHeight === r[1]);
			if (!matchRes) {
				let allowed = RES.map(r => `${r[0]} x ${r[1]}`).join('\n');
				let b = <div>Allowed image resolutions: <pre>{allowed}</pre></div>;
				messageBox('Invalid Resolution', b);
				window.URL.revokeObjectURL(preview);
				return;
			}

			if (img.naturalWidth !== img.naturalHeight) {
				let res = await messageBox('Image is not square, are you sure?', '', [messageBox.Buttons.Yes, messageBox.Buttons.No]).promise;
				if (res === messageBox.Buttons.No) { return; }
			}

			file.preview = preview;
			this.props?.onFileAdded(file);
		};

		img.src = preview;
	}

	onImageDropped(file) {
		if (this.props.disabled || !file) {return;}
		this.loadImage(file);
	}

	render() {
		const {disabled, url} = this.props;

		return (
			<div className={clsx('image-dropzone', {empty: !url})}>
				<div className="drop-wrap">
					<FileDrop
						className={clsx('drop-zone', { empty: !!url })}
						disabled={disabled}
						fileDrop={this.onImageDropped}
						acceptMimes={DROP_IMAGE_TYPES}
						maxFileSize={MAX_FILE_SIZE}
					/>
					{url && <img src={url} />}
				</div>
			</div>
		);
	}
}

ImageDropZone.propTypes = {
	url: PropTypes.string,
	disabled: PropTypes.bool,
	onFileAdded: PropTypes.func
};

ImageDropZone.defaultProps = {
	disabled: false,
	url: ''
};

export default ImageDropZone;
