import React from 'react';
import PropTypes from 'prop-types';
import {messageBox, ToolbarIcon} from 'ui-core';
import {TextField} from '@mui/material';
import ImageDropZone from '../../image-dropzone/image-dropzone.jsx';
import clsx from 'clsx';

import './layer-element.css';

var ACCEPT_IMAGE_TYPES = '.jpeg,.jpg,.png,.gif,.svg';

class LayerElement extends React.Component {
	constructor(props) {
		super(props);

		this.fileInput = null;
		this.onBrowse = this.onBrowse.bind(this);
		this.onFileSelected = this.onFileSelected.bind(this);
		this.onLayerAdded = this.onLayerAdded.bind(this);
	}

	showError(err) {
		messageBox('Error', err, messageBox.Buttons.OK);
	}

	onBrowse() {
		if (!this.fileInput) {
			return;
		}

		var event = new MouseEvent('click');
		this.fileInput.dispatchEvent(event);
	}

	onFileSelected() {
		if (this.fileInput.files.length < 1) { return; }
		var file = this.fileInput.files[0];

		if (file.size > this.props.maxFileSize) {
			this.showError('File size must be less than ' + (this.props.maxFileSize / 1024 / 1024) + 'MB');
			return;
		}

		var fileUrl = {
			preview: window.URL.createObjectURL(file)
		};

		this.onLayerAdded(fileUrl);
	}

	onLayerAdded(file) {
		this.props?.onLayerAdded(file, this.props.elementKey);
	}

	render() {
		const { url, name } = this.props;
		const className = clsx('layer-element', {new: url === ''}) + this.props.className;

		return (
			<div className= {className}>
				<ImageDropZone
					url= {url}
					name= {name}
					onFileAdded={this.onLayerAdded}
				/>
				<div className='toolbar'>
					<TextField value={name} className='text' variant='outlined' size='small' placeholder='Layer name' onChange={(event) => this.props?.onLayerNameChange(event.target.value, this.props.elementKey)}></TextField>
					<ToolbarIcon name='open_in_browser' size='small' title='Open Attachments' onClick={this.onBrowse}/>
					<input ref={i => { this.fileInput = i; }} type="file" style={{ display: 'none' }} accept={ACCEPT_IMAGE_TYPES} onChange={this.onFileSelected} />
					<ToolbarIcon name='delete' size='small' title='Delete' disabled={this.props.disableDelete} style={{ color: 'red' }} onClick= {this.props.onDelete} />
				</div>
			</div>
		);
	}
}

LayerElement.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	url: PropTypes.string,
	disableDelete: PropTypes.bool,
	elementKey: PropTypes.number,
	maxFileSize: PropTypes.number,
	onLayerAdded: PropTypes.func,
	onDelete: PropTypes.func,
	onLayerNameChange: PropTypes.func
};

LayerElement.defaultProps = {
	className: '',
	name: '',
	url: '',
	disableDelete: false,
	maxFileSize: Number.MAX_VALUE
};

export default LayerElement;
