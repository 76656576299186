import React from 'react';
import { messageBox, Panels, Panel, FileDrop} from 'ui-core';
import { BasePage, ScaffoldContext } from 'app-center-common';
import Audit from './audit.jsx';
import ImageColors from './image-colors.jsx';
import AltPalette from './alt-palette.jsx';
import Toolbar from './toolbar.jsx';
import PalettePicker from '../palette-picker/palette-picker.jsx';
import SVGImage from '../../svg-image/svg-image.jsx';
import paletteSvc from '../../../lib/palette-service.js';
import {utils, logger, SVGTools, Keyboard, storage, compression} from 'client-services';

import './image-editor.css';

class ImageEditor extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			fileName: '',
			svg: '',
			selectedElements: [],
			selectedCubes: [],
			showPaletteAlt: false,
			palettePreset: null,
			showAudit: false,
			auditResult: [],
			auditSVG: '',

			// options
			gridColor: 'transparent',
			gridWidth: 1,
			selectionBGColor: 'white',
			hideSelection: false,
			isolateSelection: true
		};

		this.paletteSvc = null;
		this.keyboard = null;
		this.svgTools = new SVGTools(document);
		this.svgImageElem = null;
		this.reducedPalette = null;
		this.solids = null;
		this.history = []; // don't save on state as immutable array with large elements won't be affective
		this.redo = [];

		this.imageColors = null;

		this.onToolbarAction = this.onToolbarAction.bind(this);
		this.onHighlightColor = this.onHighlightColor.bind(this);
		this.onSelectElement = this.onSelectElement.bind(this);
		this.onColorPaletteSelect = this.onColorPaletteSelect.bind(this);
		this.onReplaceSelectedElemColor = this.onReplaceSelectedElemColor.bind(this);
		this.onDeselectAll = this.onDeselectAll.bind(this);
		this.onToggleHideSelection = this.onToggleHideSelection.bind(this);
		this.onToggleIsolateSelection = this.onToggleIsolateSelection.bind(this);
		this.onUndo = this.onUndo.bind(this);
		this.onRedo = this.onRedo.bind(this);
	}

	// <editor-fold desc="// Methods {...}">

	async loadData() {
		var mb = messageBox('Loading...', '', messageBox.Buttons.OK, true);
		try {
			// Init paletteSvc
			this.paletteSvc = paletteSvc('AcrylicGeneric');
			this.paletteSvc.load().then(() => {
				this.reducedPalette = this.paletteSvc.getPaletteColors();
				this.solids = this.paletteSvc.getPaintableSolids();
				mb.close(100);
				this.setState({});
			});
		} catch (e) {
			logger.error('Error:', e);
			mb.setTitle('Error', false);
			mb.setBody(e.message);
		}
	}

	loadSVG(svg) {
		var start = svg.indexOf('<svg');
		var end = svg.indexOf('</svg>');
		if (start < 0 || end < 0) {
			throw new Error('Invalid svg data, could not find opening nor closing `<svg>` tag');
		}

		this.svgTools.svg = svg;
		this.svgTools.setIDsOnGeometries();
	}

	updateSelection(ids, modifiers = {}) {
		var {shift, alt} = modifiers;

		// shift = add to selection, alt = remove from selection, none = toggle ids from selection
		var selectedElements = (!shift && !alt) ? [] : [].concat(this.state.selectedElements);
		ids.forEach(id => {
			var idx = (!shift && !alt) ? this.state.selectedElements.indexOf(id) : selectedElements.indexOf(id);
			if (idx < 0 && (shift || !alt)) {
				selectedElements.push(id);
			} else if (idx >= 0 && (alt || !shift)) {
				selectedElements.splice(idx, 1);
			}
		});

		var hideSelection = selectedElements.length < 1 ? false : this.state.hideSelection; // If we deselect all reset hideSelection flag
		var isolateSelection = selectedElements.length < 1 ? true : this.state.isolateSelection;
		this.setState({selectedElements, hideSelection, isolateSelection});
	}

	pushSVGToHistory(svg) {
		var hist = compression.compress(svg);
		this.history.push(hist);
		this.redo = [];
		if (this.history.length > 100) {
			this.history.shift();
		}

		this.setState({}); // Force refresh to draw the unde/redo icons
	}

	popSVGFromHistory() {
		if (this.history.length < 1) {return;}
		var forRedo = this.svgTools.svg;
		var comp = this.history.pop();
		var svg = compression.decompress(comp);
		this.svgTools.svg = svg;
		this.setState({svg}, () => {
			var redo = compression.compress(forRedo);
			this.redo.push(redo);
			this.setState({});
		});
	}

	popSVGFromRedo() {
		if (this.redo.length < 1) {return;}
		this.history.push(compression.compress(this.svgTools.svg));
		var comp = this.redo.pop();
		var svg = compression.decompress(comp);
		this.svgTools.svg = svg;
		this.setState({svg});
	}

	openBlendingPage() {
		var recipes = this.imageColors.getRecipes();
		if (recipes.includes(null)) {
			messageBox('Some colors are not from the Cupixel Palette', 'Cannot open the blending page when not all the colors are from the palette');
			return;
		}

		var id = Date.now();
		storage.local.set(`blending_${id}`, JSON.stringify(recipes));

		var url = '/blending/' + id;
		utils.openWindow(url);
	}

	appendRecipes(formData) {
		var recipes = this.imageColors.getRecipes();
		for (var i = 0; i < recipes.length; i++) {
			var r = recipes[i];
			if (!r) {
				throw new Error('Some colors are not from the Cupixel Palette');
			}

			formData.append(`colors[${i}][color]`, r.color);
			formData.append(`colors[${i}][name]`, r.name);
			formData.append(`colors[${i}][code]`, r.code);

			if (r.containers) { formData.append(`colors[${i}][containers]`, r.containers); }
			if (r.requiredPaint) { formData.append(`colors[${i}][requiredPaint]`, r.requiredPaint); }
			if (Array.isArray(r.mixture)) {
				r.mixture.forEach((m, j) => {
					formData.append(`colors[${i}][mixture][${j}][part]`, m.part);
					formData.append(`colors[${i}][mixture][${j}][tube]`, m.tube.code);
					formData.append(`colors[${i}][mixture][${j}][name]`, m.tube.name);
					m.spoons.forEach((s, k) => {
						formData.append(`colors[${i}][mixture][${j}][spoons][${k}]`, s.id);
					});
				});
			}
		}
	}

	downloadSVG() {
		if (!this.svgTools) {return;}
		var name = `editor-v${Date.now()}` + '_svg.svg';
		this.downloadFile(this.svgTools.svg, name);
	}

	downloadPNG() {
		if (!this.svgTools) {return;}
		var name = `editor-v${Date.now()}` + '_png.png';
		this.svgToPng().then(blob => {
			this.downloadFile(blob, name);
		});
	}

	openFileFromDisk() {
		var i = document.createElement('input');
		i.type = 'file';
		i.accept = '.svg';
		i.style.display = 'none';
		document.body.appendChild(i);

		i.onchange = ev => {
			if (!ev || !ev.target) {return;}
			// @ts-ignore
			this.loadFromFileObject(ev.target.files[0]);
		};

		var event = new MouseEvent('click');
		i.dispatchEvent(event);
	}

	reloadPalette() {
		messageBox('Reload Palette', 'Reloading the palette will refresh the browser, all changes will be lost', [messageBox.Buttons.Cancel, messageBox.Buttons.OK]).promise.then(res => {
			if (res !== messageBox.Buttons.OK) {return;}
			this.paletteSvc.clearCache();
			window.location.reload();
		});
	}

	loadFromFileObject(file) {
		if (!file) {return;}
		var reader = new FileReader();
		reader.onload = res => {
			if (res && res.target && res.target.result) {
				try {
					this.loadSVG(res.target.result);
					this.setState({svg: this.svgTools.svg, fileName: file.name});
				} catch (e) {
					messageBox('Error', e.message);
				}
			}
		};

		reader.readAsText(file);
	}

	downloadFile(blob, name) {
		blob = typeof blob === 'string' ? new Blob([blob]) : blob;
		utils.downloadBlob(blob, name);
	}

	svgToPng(outline) {
		return new Promise(resolve => {
			var svgTools = this.svgTools;
			if (outline) {
				svgTools = new SVGTools(document, this.svgTools.svg);
				svgTools.toggleElementsFill(true, 'none', this.isGray);
			}

			var pngW = svgTools.width;
			var pngH = svgTools.height;

			var image = document.createElement('img');
			image.onload = () => {
				var canvas = document.createElement('canvas');
				canvas.width = pngW;
				canvas.height = pngH;
				var ctx = canvas.getContext('2d');
				ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
				canvas.toBlob(blob => {
					resolve(blob);
				}, 'image/png');
			};

			image.src = 'data:image/svg+xml;base64,' + btoa(svgTools.svg);
		});
	}

	isGray(hex) {
		return hex.length === 7 && (hex.substr(1, 2) === hex.substr(3, 2)) && (hex.substr(1, 2) === hex.substr(5, 2));
	}

	getRecipes() {
		return this.imageColors.getRecipes().map(r => {
			if (!r) {return r;}
			r.mixture.forEach(m => {
				m.tube = m.tube.code;
				m.spoons = m.spoons.map(s => s.id);
			});
		});
	}

	// </editor-fold> // Methods

	// <editor-fold desc="// EventHandlers {...}">

	onToolbarAction(action, ...params) {
		switch (action) {
			case Toolbar.Actions.GridColor:
				this.setState({gridColor: params[0]});
				break;
			case Toolbar.Actions.GridWidth:
				this.setState({gridWidth: params[0]});
				break;
			case Toolbar.Actions.SelectionBGColor:
				this.setState({selectionBGColor: params[0]});
				break;
			case Toolbar.Actions.Open:
				this.openFileFromDisk();
				break;
			case Toolbar.Actions.Center:
				if (this.svgImageElem) {this.svgImageElem.center();}
				break;
			case Toolbar.Actions.ShowPaletteAlt:
				this.setState({showPaletteAlt: true});
				break;
			case Toolbar.Actions.ToggleBGFill:
				this.setState({isolateSelection: !this.state.isolateSelection});
				break;
			case Toolbar.Actions.Undo:
				this.popSVGFromHistory();
				break;
			case Toolbar.Actions.Redo:
				this.popSVGFromRedo();
				break;
			case Toolbar.Actions.BlendingPage:
				this.openBlendingPage();
				break;
			case Toolbar.Actions.Audit:
				this.setState({showAudit: true});
				break;
			case Toolbar.Actions.DownloadSVG:
				this.downloadSVG();
				break;
			case Toolbar.Actions.DownloadPNG:
				this.downloadPNG();
				break;
			case Toolbar.Actions.ReloadPalette:
				this.reloadPalette();
				break;
		}
	}

	onSelectElement(elem, modifiers) {
		if (elem && !elem.id) {return;}
		this.updateSelection(elem ? [elem.id] : [], modifiers);
	}

	onHighlightColor(c, modifiers) {
		var ids = this.svgTools.getElementsWithFill(c);
		this.updateSelection(ids, modifiers);
	}

	onColorPaletteSelect(svg, palettePreset) {
		try {
			this.loadSVG(svg);
			this.setState({svg: this.svgTools.svg, palettePreset, showPaletteAlt: false, selectedElements: [], hideSelection: false, isolateSelection: true});
		} catch (e) {
			messageBox('Error', e.message);
		}
	}

	onReplaceSelectedElemColor(color, fromPalette) {
		if (this.state.selectedElements.length < 1) {return;}
		var toSave = this.svgTools.svg;

		if (color === 'none') {
			this.svgTools.removeElements(this.state.selectedElements);
			this.onDeselectAll();
		} else {
			this.svgTools.replaceElementsFill(this.state.selectedElements, color);
		}

		this.setState({svg: this.svgTools.svg}, () => this.pushSVGToHistory(toSave));
	}

	onDeselectAll() {
		this.setState({selectedElements: [], selectedCubes: [], hideSelection: false, isolateSelection: true});
	}

	onToggleHideSelection() {
		if (this.state.selectedElements.length < 1) {return;}
		this.setState({hideSelection: !this.state.hideSelection});
	}

	onToggleIsolateSelection() {
		if (this.state.selectedElements.length < 1) {return;}
		this.setState({isolateSelection: !this.state.isolateSelection});
	}

	onUndo() {
		this.popSVGFromHistory();
	}

	onRedo() {
		this.popSVGFromRedo();
	}

	// </editor-fold> // EventHandlers

	// <editor-fold desc="// Lifecycle {...}">

	componentDidMount() {
		this.keyboard = new Keyboard(document);
		this.keyboard.on('ctrl+d', this.onDeselectAll);
		this.keyboard.on('ctrl+h', this.onToggleHideSelection);
		this.keyboard.on('ctrl+x', this.onToggleIsolateSelection);
		this.keyboard.on('ctrl+z', this.onUndo);
		this.keyboard.on('ctrl+y', this.onRedo);

		this.loadData();

		// @ts-ignore
		this.context.toggleSideBar(false);
	}

	componentWillUnmount() {
		if (this.keyboard) {
			this.keyboard.dispose();
		}
	}

	// </editor-fold> // Lifecycle

	// <editor-fold desc="// Render {...}">

	renderAudit() {
		if (!this.state.showAudit) {return null;}
		return (
			<Panel title="Audit Result" closeButton={true} onClose={() => this.setState({showAudit: false, selectedElements: [], selectedCubes: []})}>
				<Audit
					paletteSvc={this.paletteSvc}
					svg={this.state.svg}
					onAuditResult={(result, svg) => this.setState({auditResult: result, auditSVG: svg})}
					onErrorSelected={data => this.setState({selectedElements: data.elements || [], selectedCubes: data.cubes || []})}
				/>
			</Panel>
		);
	}

	renderAltPalette() {
		if (!this.state.showPaletteAlt) {return null;}
		return (
			<Panel title="Palettes">
				<AltPalette paletteSvc={this.paletteSvc} svg={this.state.svg} showAlternatives={this.state.showPaletteAlt} onSelect={this.onColorPaletteSelect} />
			</Panel>
		);
	}

	renderPalettePicker(color) {
		if (this.state.showAudit || this.state.selectedElements.length < 1) {
			return null;
		}

		return (
			<Panel title="Palette Picker">
				<div style={{width: 250, margin: 'auto'}}>
					<PalettePicker
						palette={this.reducedPalette}
						swatches={this.solids}
						color={color}
						showEmptyColorSwatch={true}
						onChangeComplete={this.onReplaceSelectedElemColor}
					/>
				</div>
			</Panel>
		);
	}

	renderImageArea() {
		var comp;
		if (this.state.svg) {
			comp = <SVGImage
				ref={e => {this.svgImageElem = e;}}
				svg={this.state.svg}
				grid={[3, 3]}
				gridColor={this.state.gridColor}
				gridWidth={this.state.gridWidth}
				selectionBGColor={this.state.selectionBGColor}
				selectedElements={this.state.selectedElements}
				selectedCubes={this.state.selectedCubes}
				hideSelection={this.state.hideSelection}
				isolateSelection={this.state.isolateSelection}
				elemClick={this.onSelectElement}
			/>;
		} else {
			comp = <FileDrop
				className="dropzone"
				acceptMimes="image/svg+xml"
				fileDrop={file => this.loadFromFileObject(file)}
			/>;
		}

		return comp;
	}

	render() {
		if (!this.paletteSvc) {
			return 'Loading...';
		}

		var selectedColors = this.state.selectedElements.length < 1 ? [] : this.svgTools.getElemFill(this.state.selectedElements);
		var hasSelection = this.state.selectedElements.length > 0;
		var hasHistory = this.history.length > 0;
		var hasRedo = this.redo.length > 0;
		return (
			<BasePage
				className="image-editor"
				title="Image Editor"
				subTitle={this.state.fileName || ''}
			>
				<Panels orientation="row" className="panels">
					<Panels orientation="column" flex="0.7">
						<Panel className="image-container" flex="0.8" header={false}>
							<Toolbar onAction={this.onToolbarAction} hasSelection={hasSelection} hasHistory={hasHistory} hasRedo={hasRedo} isolateSelection={this.state.isolateSelection} />
							<div className="svg-image-wrap">
								{this.renderImageArea()}
							</div>
						</Panel>
						<Panel header={false}>
							<ImageColors ref={e => {this.imageColors = e;}} paletteSvc={this.paletteSvc} svg={this.state.svg} validate={true} selectedColors={selectedColors} onClick={this.onHighlightColor} />
						</Panel>
					</Panels>
					<Panels orientation="column" flex="0.3">
						{this.renderAudit()}
						{this.renderAltPalette()}
						{this.renderPalettePicker(selectedColors[0] || '#000000')}
					</Panels>
				</Panels>
			</BasePage>
		);
	}

	// </editor-fold> // Render
}

ImageEditor.contextType = ScaffoldContext;

export default ImageEditor;
