import React from 'react';
import PropTypes from 'prop-types';
import EntityPicker from '../entity-picker.jsx';
import { logger } from 'client-services';

class EntityPickerController extends React.Component {
	constructor(props) {
		super(props);

		this.loadEntities = this.loadEntities.bind(this);
		this.loadEntitiesByIds = this.loadEntitiesByIds.bind(this);
	}

	// #region Methods

	async loadEntities(count, skip, q) {
		logger.trace('Fetching `%s`, count: %s, skip: %s, q:', this.props.entityName, count, skip, q);
		var res = await this.props.getEntitiesApi({ count, skip, q }).send();
		var entities = this.props.toModel(res);

		return entities;
	}

	loadEntitiesByIds(ids) {
		var q = ids.map(id => `id:${id}`).join(' ');
		return this.loadEntities(ids.length, 0, q);
	}

	// #endregion Methods

	render() {
		var { onCommit, ...rest } = this.props;
		return (
			<EntityPicker
				loadEntitiesByIds={this.loadEntitiesByIds}
				loadEntities={this.loadEntities}
				onCommit={selected => onCommit(selected ? selected.map(e => ({ id: e.id, thumbnail: e.src, name: e.title })) : null)}
				{...rest}
			/>
		);
	}
}

EntityPickerController.propTypes = {
	entityName: PropTypes.string.isRequired,
	searchSchema: PropTypes.object,
	getEntitiesApi: PropTypes.func.isRequired,
	toModel: PropTypes.func.isRequired,
	onCommit: PropTypes.func,
};

EntityPickerController.defaultProps = {
	onCommit: function nop() { }
};

export default EntityPickerController;
