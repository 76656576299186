import { compileSchema } from 'ui-core';

/** @type {import("@rjsf/utils").RJSFSchema} */
var schema = {
	type: 'object',
	required: ['name'],
	properties: {
		id: {
			type: 'string',
			readOnly: true
		},
		name: {
			type: 'string'
		},
		lod: {
			type: 'number',
			title: 'LOD',
			oneOf: [
				{ enum: [1], title: 'Beginner' },
				{ enum: [2], title: 'Intermediate' },
				{ enum: [3], title: 'Advanced' },
				{ enum: [9999], title: 'None' }
			],
			default: 1
		},
		artist: {
			$ref: '#/definitions/artist'
		},
		groups: {
			$ref: '#/definitions/tags',
			title: 'Groups (related images)',
		},
		tags: {
			$ref: '#/definitions/tags'
		}
	},
	definitions: {
		artist: {
			type: ['object', 'string', 'null'],
			default: {},
			properties: {
				name: { type: 'string' },
				email: { type: 'string' }
			}
		},
		tags: {
			type: 'array',
			items: { type: 'string' },
			default: []
		}
	}
};

export default compileSchema(schema);
