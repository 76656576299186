import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { MaterialIcon } from 'ui-core';
import AssetGalleryTile from './asset-gallery-tile/asset-gallery-tile.jsx';

const useClasses = makeStyles()(theme => ({
	empty: {
		width: 75,
		height: 100,
		border: '1px dashed black',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer'
	}
}));

function AssetPickerPreview(props) {
	let { classes } = useClasses();
	var { type, src, onClick } = props;
	return (
		src ?
			<AssetGalleryTile
				allowEdit = { false }
				asset = {{type, src}}
			/>
			:
			<div className={classes.empty} onClick={e => onClick?.(e)}>
				<MaterialIcon name="add" />
			</div>
	);
}

AssetPickerPreview.propTypes = {
	type: PropTypes.string.isRequired,
	src: PropTypes.string,
	onClick: PropTypes.func
};

export default AssetPickerPreview;
