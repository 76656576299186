import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { messageBox, SearchBox, InfiniteScroll, FilePicker } from 'ui-core';
import { BasePage } from 'app-center-common';
import { api, image } from 'client-services';
import FilterPopup from './filter-popup.jsx';

import './experiences-search-page.css';

let FILTER_CONF_CACHE = null;

class ExperiencesSearchPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			search: '',
			photo: '',
			filterConfig: null,
			hasMore: true,
			experiences: [],
			alternatives: [],
			similar: []
		};

		this.onSearch = this.onSearch.bind(this);
		this.onPhotoSearch = this.onPhotoSearch.bind(this);
		this.onExpClick = this.onExpClick.bind(this);
		this.onTypeChange = this.onTypeChange.bind(this);
		this.onFilterItemClick = this.onFilterItemClick.bind(this);
		this.onFilterSubmit = this.onFilterSubmit.bind(this);
		this.loadMore = this.loadMore.bind(this);

		this.page = 0;
	}

	// #region Methods

	async reset(resetSearchTerm = true, resetFilter = true) {
		let resolve;
		let p = new Promise(res => { resolve = res; });

		this.page = 0;
		let search = resetSearchTerm ? '' : this.state.search;
		let photo = resetSearchTerm ? '' : this.state.photo;
		let filterConfig = resetFilter ? await this.fetchConfig() : this.state.filterConfig;
		this.setState({ experiences: [], alternatives: [], similar: [], search, photo, hasMore: true, filterConfig }, resolve);

		return p;
	}

	async fetchConfig() {
		if (!FILTER_CONF_CACHE) {
			let res = await api.experience.getExperiencesFilterConfig().send();
			FILTER_CONF_CACHE = res?.experienceFilterConfig;
		}

		let expConfig = FILTER_CONF_CACHE;
		if (!expConfig) { return null; }
		let config = {
			medium: [],
			content: [],
			type: [],
			artist: []
		};

		if (Array.isArray(expConfig.medium)) {
			config.medium = expConfig.medium.map(item => ({ filterProp: 'medium', ...item }));
		}

		if (Array.isArray(expConfig.lod)) {
			config.content = expConfig.lod.map(item => ({ filterProp: 'lod', ...item }));
		}

		if (Array.isArray(expConfig.instructor)) {
			config.artist = expConfig.instructor.map(item => ({ filterProp: 'instructor', ...item }));
		}

		['enableTrace', 'accessLevel', 'tags:collection'].forEach(p => {
			if (Array.isArray(expConfig[p])) {
				expConfig[p].forEach(item => {
					config.type.push({ filterProp: p, ...item });
				});
			}
		});

		return config;
	}

	async loadMore() {
		let { search, photo } = this.state;
		let filter = this.getFilter();
		let page = this.page;

		// check if this is a photo search (photo + no search)
		photo = (!!photo && !search) ? photo : undefined;
		if (page > 0 && photo) { return; } // no paging on photo search

		let mb = messageBox('Searching...');
		let res;
		try {
			res = await api.experience.searchExperiences({ search, photo, filter, page }).send();
			mb.close();
		} catch (ex) {
			mb.setTitle('Error');
			mb.setBody(ex.message);
			return;
		}

		let exps = res?.experiences ?? [];
		let alternatives = res?.alternatives ?? [];
		let hasMore = !!res?.hasMore;
		++this.page;
		this.setState({ experiences: [...this.state.experiences, ...exps], alternatives, hasMore });
	}

	getFilter() {
		// Create the filter object from all the selected filter items
		let filter = {};
		Object.values(this.state.filterConfig).flatMap(c => c).filter(i => !!i.selected).forEach(i => {
			filter[i.filterProp] = [...(filter[i.filterProp] || []), ...i.values];
		});

		return filter;
	}

	// #endregion Methods

	// #region EventHandlers

	async onSearch(q) {
		if (this.state.search === q) { return; }
		await this.reset();
		this.setState({ search: q }, () => this.loadMore());
	}

	async onPhotoSearch(files) {
		if (files.length < 1) { return; }
		try {
			let photo = await image(files[0]).resize(300).jpg(0.8).toBase64();
			await this.reset();
			this.setState({ photo }, () => this.loadMore());
		} catch (ex) {
			messageBox('Error', ex.message);
		}
	}


	async onExpClick(exp) {
		let mb = messageBox('Searching...');
		let res;
		try {
			res = await api.experience.findSimilarExperiences({ id: exp.id }).send();
		} catch (ex) {
			mb.setTitle('Error');
			mb.setBody(ex.message);
			return;
		}

		let experiences = res?.experiences ?? [];
		this.setState({ similar: experiences });
		mb.close();
	}

	async onTypeChange(e) {
		let type = e.target.value;
		await this.reset(false);
		this.setState({ type }, () => this.loadMore());
	}

	onFilterItemClick(cat, f) {
		let conf = Object.assign({}, this.state.filterConfig);
		let item = conf[cat].find(i => i.name === f.name);
		if (!item) { return; }
		item.selected = !item.selected;
		this.setState({ filterConfig: conf });
	}

	async onFilterSubmit() {
		await this.reset(false, false);
		this.loadMore();
	}

	// #endregion EventHandlers

	// #region Component Lifecycle

	async componentDidMount() {
		let filterConfig = (await this.fetchConfig()) || {};
		this.setState({ filterConfig }, () => this.loadMore());
	}

	// #endregion Component Lifecycle

	// #region Render

	render() {
		let { search, filterConfig, experiences, alternatives, similar, hasMore } = this.state;
		if (!filterConfig) { return 'Loading...'; }
		let useAlt = (experiences.length < 1 && alternatives.length > 0);
		experiences = useAlt ? alternatives : experiences;

		return (
			<BasePage
				title="Search"
				className="exp-search-page"
			>
				<div className="tools">
					<SearchBox search={search} searchChanged={this.onSearch} />
					<FilePicker
						acceptMimes=".jpg,.png"
						toolbarIconProps={{
							color: 'primary',
							name: 'add_photo_alternate',
							title: 'Search with photo',
							size: 'medium'
						}}
						onFiles={this.onPhotoSearch}
					/>
					<FilterPopup
						config={filterConfig}
						onFilterItemClick={this.onFilterItemClick}
						onSubmit={this.onFilterSubmit}
					/>
				</div>
				{useAlt && <div className="alt-notice">We did not find exactly what you were looking for, but maybe you will like these ones...</div>}
				<InfiniteScroll className="scroller" scrollOn=".results" hasMore={hasMore} loadMore={this.loadMore}>
					<div className="results">
						{experiences.length > 0 && experiences.map(e =>
							<ExpItem key={e.id} experience={e} onClick={this.onExpClick} />
						)}
					</div>
				</InfiniteScroll>
				{similar.length > 0 &&
					<div className="similar">
						<div className="title">
							<span>Similar Experiences</span>
							<span className="close" onClick={() => this.setState({similar: []})}>[close]</span>
						</div>
						<div className="list">
							{similar.map(e => <ExpItem key={e.id} experience={e} />)}
						</div>
					</div>
				}
			</BasePage>
		);
	}

	// #endregion Render
}

ExperiencesSearchPage.propTypes = {
};

ExperiencesSearchPage.defaultProps = {
};

export default ExperiencesSearchPage;

function ExpItem(props) {
	let { experience, onClick } = props;
	return (
		<div className="exp">
			<Link to={`/experience/${experience.id}`} target="_blank">
				<img className="thumb" src={experience.thumbnail} />
			</Link>
			<div className="bottom-line">
				<div className="name" title={experience.name}>{experience.name}</div>
				{onClick && <div className="similar-link" onClick={() => onClick(experience)}>[similar]</div>}
			</div>
		</div>
	);
}

ExpItem.propTypes = {
	experience: PropTypes.object,
	onClick: PropTypes.func
};

