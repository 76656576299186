import React from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from '../../../lib/constants.js';
import { Button } from '@mui/material';
import { ChecklistPop, snackbar, messageBox, Toolbar, ToolbarIcon, SearchBox } from 'ui-core';
import NewAssetView from './new-asset-view.jsx';
import AssetGalleryView from './asset-gallery-view.jsx';
import { logger } from 'client-services';

import './asset-picker-view.css';

let SHOW_ALL_SEARCH = '*';
let FETCH_COUNT = 50;

class AssetPickerView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			assets: [],
			mode: props.mode,
			checkedAssetTypes: [...props.assetTypes],
			searchStr: '',
			selectedId: '',
			hasMore: true,
		};

		this.page = 0;
		this.newAssetView = null;

		this.onSelect = this.onSelect.bind(this);
		this.onAssetTypeChange = this.onAssetTypeChange.bind(this);
		this.onNewAsset = this.onNewAsset.bind(this);
		this.onNewFileCommitted = this.onNewFileCommitted.bind(this);
		this.onSearchChange = this.onSearchChange.bind(this);
		this.onTitleChanged = this.onTitleChanged.bind(this);
		this.onSelectedToggle = this.onSelectedToggle.bind(this);
		this.onDelete = this.onDelete.bind(this);
	}

	// #region Methods

	loadMore(searchChanged) {
		// If search changed we have to first reset gallery and re-render gallery with no assets in-order to reset scroller
		if (searchChanged) {
			this.page = 0;
			this.setState({ assets: [], hasMore: true }, () => this.doSearch());
		} else {
			this.doSearch();
		}
	}

	async doSearch() {
		let { searchStr, checkedAssetTypes } = this.state;

		let skip = this.page * FETCH_COUNT;

		// Since media asset gallery contains a lot of garbage we do not allow fetching
		// the gallery without a search. There is a backdoor though, one can search for "*"
		if (searchStr.trim() === '' || checkedAssetTypes?.length === 0) {
			this.setState({ assets: [], searchStr: '' });
			return;
		}

		let search = searchStr;
		// ignore empty search filter
		if (searchStr.toLowerCase() === SHOW_ALL_SEARCH) {
			search = '';
		}

		if (checkedAssetTypes?.length > 0) {
			search = search + ' ' + checkedAssetTypes.map(t => `+type:${t}`).join(' ');
		}

		try {
			let assets = await this.props.loadData(FETCH_COUNT, skip, search);
			let hasMore = (assets.length === FETCH_COUNT);
			++this.page;
			this.setState({ assets: [...this.state.assets, ...assets], hasMore });

		} catch (ex) {
			messageBox('Error loading media assets', ex.message);
			return;
		}
	}

	toViewModel(asset) {
		return {
			id: asset.id,
			src: asset.url,
			thumbnail: asset.thumbnail,
			title: asset.name,
			type: asset.type,
			isDeleted: asset.status === CONSTANTS.MediaAssetStatus.Deleted,
			isSelected: asset.id === this.state.selectedId
		};
	}

	// #endregion Methods

	// #region Event Handlers

	onSelect() {
		// Handles either the "Select" or "Upload" button
		let {mode, selectedId} = this.state;

		if (mode === CONSTANTS.AssetViewModes.Gallery) {
			if (!selectedId) {
				messageBox('no asset was selected');
				return;
			}

			let asset = this.state.assets.find(item => item.id === selectedId);
			if (!asset) {
				// weird...
				messageBox('Weird, but selected asset not found...');
				this.setState({ selectedId: '' });
				return;
			}

			this.props.onPickAsset(asset);
		} else if (mode === CONSTANTS.AssetViewModes.Edit) {
			// Upload was tapped, submit the new asset form
			this.newAssetView.commit();
		}
	}

	async onNewFileCommitted(data) {
		if (!data) { return; }
		let asset = await this.props.onUpload(data);
		if (!asset) { return; }

		this.props.onPickAsset(asset);
		this.newAssetView?.clear(); // clear form after file upload (needed if we are not in modal)
	}

	async onTitleChanged(newVal, id) {
		let {assets} = this.state;
		let target = assets.find(item => item.id === id);

		if (!target) {
			logger.error(`Error while trying to Change asset title of id:${id} from ${target.title} to ${newVal}`);
			snackbar('Unknown error updating name');
			return;
		}

		let oldTitle = target.title;
		logger.trace(`Changing asset title of id:${id} from ${oldTitle} to ${newVal}`);
		target.title = newVal;
		try {
			await this.props.onTitleChanged(target);
		} catch (ex) {
			logger.error('Error: ', ex.message);
			snackbar('Error updating name');
			target.title = oldTitle;
			this.setState({ assets: [...assets] });
		}
	}

	onSelectedToggle(id) {
		if (!id) { return; }

		let {selectedId} = this.state;
		if (selectedId === id) {
			selectedId = '';
		} else {
			selectedId = id;
		}

		this.setState({ selectedId });
	}

	onSearchChange(searchStr) {
		this.setState({ selectedId: '', mode: CONSTANTS.AssetViewModes.Gallery, searchStr }, () => { this.loadMore(true); });
	}

	onAssetTypeChange(newTypes) {
		const currentSelectedTypes = this.state.checkedAssetTypes;
		let changed = newTypes.length !== currentSelectedTypes.length || newTypes.filter(e => currentSelectedTypes.indexOf(e) >= 0).length !== currentSelectedTypes.length;
		if (!changed) { return; }

		this.setState({ mode: CONSTANTS.AssetViewModes.Gallery, checkedAssetTypes: newTypes }, () => { this.loadMore(true); });
	}

	onNewAsset() {
		if (this.state.mode === CONSTANTS.AssetViewModes.Edit) { return; }
		this.setState({mode: CONSTANTS.AssetViewModes.Edit, selectedId: '', searchStr: ''});
	}

	async onDelete(...params) {
		let reload = await this.props.onDelete(...params);
		// be explicit here that we got a boolean
		if (typeof reload === 'boolean' && reload) {
			this.page = 0;
			this.setState({ assets: [], hasMore: true }, () => this.doSearch());
		}
	}

	// #endregion Event Handlers

	// #region Render

	renderToolbar() {
		let selectText = '';
		if (this.state.mode === CONSTANTS.AssetViewModes.Gallery && this.state.selectedId) {
			selectText = 'Select';
		} else if (this.state.mode === CONSTANTS.AssetViewModes.Edit) {
			selectText = 'Upload';
		}

		return (
			<Toolbar primary shadow className="toolbar">
				<ToolbarIcon name="playlist_add" onClick={this.onNewAsset} />
				<ChecklistPop toggleAll options={this.props.assetTypes} disabled={this.props.assetTypes.length === 1} checked={this.state.checkedAssetTypes} onClose={this.onAssetTypeChange} />
				<SearchBox search={this.state.searchStr} searchChanged={this.onSearchChange} />
				{selectText && <Button variant="outlined" color="secondary" onClick={this.onSelect}>{selectText}</Button>}
			</Toolbar>
		);
	}

	renderContent() {
		let content;
		const {mode, assets, hasMore} = this.state;

		if (mode === CONSTANTS.AssetViewModes.Gallery) {
			content = <AssetGalleryView
				assets={assets.map(a => this.toViewModel(a))}
				hasMore={hasMore}
				allowSelect={this.props.allowSelect}
				allowDelete={this.props.allowDelete}
				loadMore={() => this.loadMore}
				onTitleChanged={this.onTitleChanged}
				onSelectedToggle={this.onSelectedToggle}
				onDelete={this.onDelete}
			/>;
		} else if (mode === CONSTANTS.AssetViewModes.Edit) {
			content = <NewAssetView
				ref={view => { this.newAssetView = view; }}
				maxFileSize={this.props.maxFileSize}
				assetTypes={this.props.assetTypes}
				onNewFileCommitted= {this.onNewFileCommitted}
			/>;
		}

		return content;
	}

	render() {
		return (
			<div className='asset-picker-view'>
				{this.renderToolbar()}
				<div className='view'>
					{this.renderContent()}
				</div>
			</div>);
	}

	// #endregion
}

AssetPickerView.propTypes = {
	mode: PropTypes.string,
	allowSelect: PropTypes.bool,
	allowDelete: PropTypes.bool,
	maxFileSize: PropTypes.number,
	assetTypes: PropTypes.arrayOf(PropTypes.string),
	onUpload: PropTypes.func,
	loadData: PropTypes.func,
	onTitleChanged: PropTypes.func,
	onPickAsset: PropTypes.func,
	onDelete: PropTypes.func,
};

function nop() { }
AssetPickerView.defaultProps = {
	mode: CONSTANTS.AssetViewModes.Gallery,
	assetTypes: Object.values(CONSTANTS.AssetTypes),
	onUpload: nop,
	loadData: nop,
	onTitleChanged: nop,
	onPickAsset: nop,
	onDelete: nop,
};

export default AssetPickerView;
