import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@mui/material';
import ReactJson from 'react18-json-view';
import {messageBox, Panels, Panel, MaterialIcon} from 'ui-core';
import {config} from 'client-services';
import clsx from 'clsx';
import * as Rules from '../../../lib/audit/rules';

import 'react18-json-view/src/style.css';
import './audit.css';

const KIT = {
	cubes: [3, 3],
	cubeResolution: 340
};
var auditConfig = config.get('services:audit');

class Audit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			svg: this.props.svg || '',
			auditing: false,
			shouldAudit: false,
			results: [],
			selectedError: null
		};

		this.rules = [];
	}

	// <editor-fold desc="// Methods {...}">

	runAudit() {
		var data = {
			svg: this.state.svg,
			kit: KIT,
			paletteSvc: this.props.paletteSvc
		};

		this.setState({auditing: true, shouldAudit: false, results: [], selectedError: null}, () => {
			var promises = this.rules.map(r => r(data, auditConfig));
			Promise.all(promises).then(results => {
				this.setState({auditing: false, results}, () => {
					this.props.onAuditResult(results, this.state.svg);
				});
			}).catch(e => {
				messageBox('Error running audit rules', e.message);
				this.setState({auditing: false});
			});
		});
	}

	getSeverityIcon(severity) {
		var res = '';
		switch (severity) {
			case 'Error':
				res = 'error';
				break;
			case 'Warn':
				res = 'warning';
				break;
		}

		return res;
	}

	// </editor-fold> // Methods

	// <editor-fold desc="// EventHandlers {...}">

	onErrorClick(e) {
		if (!e.params) {return;}
		var selectedError = (this.state.selectedError && e.key === this.state.selectedError.key) ? null : e; // Toggle
		var elements = [];
		var cubes = [];
		if (e.params && Array.isArray(e.params.ids) && e.params.ids.length > 0 && selectedError) {
			elements = e.params.ids;
		}

		if (e.params && Array.isArray(e.params.cubes) && e.params.cubes.length > 0 && selectedError) {
			cubes = e.params.cubes;
		}

		this.setState({selectedError}, () => {
			this.props.onErrorSelected({elements, cubes});
		});
	}

	// </editor-fold> // EventHandlers

	// <editor-fold desc="// Lifecycle {...}">

	static getDerivedStateFromProps(np, state) {
		if (np.svg !== state.svg) {
			state.svg = np.svg;
			state.shouldAudit = true;
			return state;
		}

		return null;
	}

	componentDidMount() {
		// Load rules
		var rulesToLoad = Object.keys(Rules);
		for (var i = 0; i < rulesToLoad.length; i++) {
			var r = rulesToLoad[i];
			var fn = Rules[r];
			if (typeof fn !== 'function') {
				messageBox(`Rule \`${r}\` not found`);
				break;
			}

			this.rules.push(fn);
		}

		if (this.state.svg) {
			this.runAudit();
		}
	}

	// </editor-fold> // Lifecycle

	// <editor-fold desc="// Render {...}">

	renderErrorDetails() {
		if (!this.state.selectedError || !this.state.selectedError.params) {return null;}
		// eslint-disable-next-line no-unused-vars
		var {ids, cubes, ...params} = this.state.selectedError.params; // We don't want to display the ids
		if (Object.keys(params).length < 1) {return;}
		return (
			<Panel className="error-details" flex="0.4" title="Details">
				<ReactJson src={params} collapsed={1} />
			</Panel>
		);
	}

	render() {
		if (!this.state.svg) {return '';}
		if (this.state.auditing) {return <LinearProgress/>;}
		if (this.state.shouldAudit) {
			setTimeout(this.runAudit.bind(this), 10);
			return '';
		}

		var results = this.state.results;
		if (results.length < 1) {return 'No audit results found, that is weird';}

		return (
			<Panels className="audit" orientation="column" flex="0.4">
				<Panel className="results" header={false}>
					{results.map(r => {
						if (r.errors.length < 1) {
							return (
								<div key={r.name} className="result passed">
									<MaterialIcon name="check_circle" />
									<span>{r.name}</span>
								</div>
							);
						}

						return r.errors.map((e, i) => {
							e.key = `${e.code}_${i}`;
							var className = clsx('result', e.severity.toLowerCase(), {clickable: !!e.params, selected: this.state.selectedError && this.state.selectedError.key === e.key});
							return (
								<div key={e.key} onClick={() => this.onErrorClick(e)} className={className}>
									<MaterialIcon name={this.getSeverityIcon(e.severity)} />
									<span>{e.message} ({`${r.name} - ${e.code}`})</span>
								</div>
							);
						});
					})}
				</Panel>
				{this.renderErrorDetails()}
			</Panels>
		);
	}

	// </editor-fold> // Render
}

Audit.propTypes = {
	paletteSvc: PropTypes.object.isRequired,
	svg: PropTypes.string,
	onAuditResult: PropTypes.func,
	onErrorSelected: PropTypes.func
};

function nop() {}
Audit.defaultProps = {
	onAuditResult: nop,
	onErrorSelected: nop
};

export default Audit;
