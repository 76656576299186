.entity-picker {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.entity-picker .toolbar {
	padding: 5px 10px;
    z-index: 1000;
}

.entity-picker .toolbar .search-tools {
	display: flex;
	margin-left: auto;
}

.entity-picker .toolbar .search-tools.hidden {
	display: none;
}

.entity-picker .content {
	flex: 1;
	overflow: auto;
	display: flex;
	flex-direction: column;
	outline: none; /* div get focus due to keyup and we dont want outline */
}

.entity-picker .content .scroller {
	flex: 1;
	overflow: auto;
}

.entity-picker .content .filter {
	align-self: flex-start;
	margin: 10px 5px;
}

.entity-picker .footer {
	padding: 10px;
	border-top: 1px solid gray;
	display: flex;
    justify-content: flex-end;
}

.entity-picker .footer > button {
	margin-left: 10px;
}