import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { messageBox, snackbar, InfiniteScroll } from 'ui-core';
import { BasePage } from 'app-center-common';
import SpacePostsList from '../space-posts-list/space-posts-list.jsx';
import { api, logger } from 'client-services';

import './space-posts-page.css';

const FILTER = {
	Posts: 'Posts',
	Comments: 'Comments',
	Featured: 'Featured'
};

class SpacePostsPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			filterTime: 3,
			postsTypeFilter: FILTER.Posts,
			author: '',
			username: '',
			space: props?.searchParams?.get('space') ?? '',
			hasMore: true,
			lastSpacePostId: null,
			spacePosts: []
		};

		this.loadMore = this.loadMore.bind(this);
		this.onReply = this.onReply.bind(this);
		this.onFeaturedChanged = this.onFeaturedChanged.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onToggleSelectedAuthor = this.onToggleSelectedAuthor.bind(this);
		this.onToggleSpaceFilter = this.onToggleSpaceFilter.bind(this);
	}

	// #region Methods

	async loadMore() {
		try {
			let {filterTime, postsTypeFilter, lastSpacePostId, author, space} = this.state;
			let params = { lastHours: filterTime, filter: { postsTypeFilter, author, space }, nextCursor: lastSpacePostId};

			logger.trace('Fetching spacePosts with params:', params);
			let spacePosts = await api.spaces.getLastSpacePosts(params).send();
			logger.trace('Got fetching spacePosts response:', spacePosts);

			let hasMore = false;
			if (spacePosts.length > 0) {
				hasMore = true;
				lastSpacePostId = spacePosts[spacePosts.length - 1].id;
			}

			this.setState({ hasMore, lastSpacePostId, spacePosts: [...this.state.spacePosts, ...spacePosts] });
		} catch (ex) {
			logger.error('Error loading spacePosts, error:', ex);
			messageBox('Error loading spacePosts', ex.message);
		}
	}

	// #endregion Methods

	// #region Event Handlers

	onFilterByTypeClick(postsTypeFilter) {
		this.setState({ postsTypeFilter, lastSpacePostId: null, spacePosts: [] }, () => {this.loadMore();});
	}

	onTimeFilterChanged(val) {
		this.setState({ filterTime: val, lastSpacePostId: null, spacePosts: [] }, () => {this.loadMore();});
	}

	onToggleSelectedAuthor(author, username) {
		this.setState({ author, username, lastSpacePostId: null, spacePosts: [] }, () => {this.loadMore();});
	}

	onToggleSpaceFilter(space) {
		this.setState({ space, lastSpacePostId: null, spacePosts: [] }, () => {this.loadMore();});
	}

	async onReply(spacePost) {
		if (spacePost.parent) {
			messageBox('Cannot add reply to a comment');
			return;
		}

		let msg = <div>Reply to user {spacePost.username}</div>;
		let msgBoxRes = await messageBox.prompt(msg, 'Reply', [messageBox.Buttons.Cancel, 'reply']);
		if (msgBoxRes.result !== 'reply') {return;}
		let message = msgBoxRes.values['Reply'];

		if (message.length === 0) {
			messageBox('Please add comment');
			return;
		}

		let params = {
			id: spacePost.id,
			message
		};

		logger.trace('Adding new comment to space post. params: ', params);
		let comment = await api.spaces.commentAsModerator(params).send();
		logger.trace('New saved comment: ', comment);

		let spacePosts = this.state.spacePosts;
		let idx = spacePosts.findIndex(p => p.id === spacePost.id);
		if (idx >= 0) {
			spacePosts[idx].commentsCount = (spacePosts[idx].commentsCount || 0) + 1;
		}

		this.setState({ spacePosts: [...spacePosts] });

		snackbar('Comment added successfully');
	}

	async onFeaturedChanged(spacePost) {
		let params = {
			spacePostId: spacePost.id,
			featured: !spacePost.featured
		};

		let spacePosts = this.state.spacePosts;
		let idx = spacePosts.findIndex(p => p.id === spacePost.id);
		if (idx < 0) {
			messageBox('Oops', 'Space post not found in list, that is really weird...');
			return;
		}

		try {
			// optimistic update
			spacePosts[idx].featured = params.featured;
			this.setState({ spacePosts: [...spacePosts] });

			let apiRes = await api.spaces.setSpacePostFeatured(params).send();

			// Theoretically we shouldn't change it if we didn't get error, but just to be
			// sure we reflect the server value we update ui with response
			spacePosts[idx].featured = apiRes.spacePost.featured;
			this.setState({ spacePosts: [...spacePosts] });
		} catch (ex) {
			messageBox('Error', ex.message);
			// reset ui
			spacePosts[idx].featured = spacePost.featured;
			this.setState({ spacePosts: [...spacePosts] });
			return;
		}
	}

	async onDelete(spacePost) {
		let type = spacePost.parent ? 'comment' : 'post';
		let msgBoxRes = await messageBox(`Delete ${type} ?`, spacePost.message, [messageBox.Buttons.Cancel, 'delete']).promise;
		if (msgBoxRes !== 'delete') { return; }

		logger.trace('Deleting spacePost with id:', spacePost.id);

		let params = {id: spacePost.id};
		try {
			if (spacePost.parent) {
				await api.spaces.deleteComment(params).send();
			} else {
				await api.spaces.deleteSpacePost(params).send();
			}
		} catch (ex) {
			messageBox('Error', ex.message);
			return;
		}

		// See if we deleted a post/comment from the main list
		let spacePosts = this.state.spacePosts;
		let idx = spacePosts.findIndex(p => p.id === spacePost.id);
		if (idx >= 0) {
			spacePosts.splice(idx, 1);
		} else if (spacePost.parent) {
			// maybe we deleted a comment of a post so search for the parent in the main list
			idx = spacePosts.findIndex(p => p.id === spacePost.parent);
			if (idx >= 0) {
				spacePosts[idx].commentsCount = Math.max(0, (spacePosts[idx].commentsCount || 0) - 1);
			}
		}

		this.setState({ spacePosts: [...spacePosts] });
		snackbar(`${type} deleted successfully`);
	}

	// #endregion Event Handlers

	// #region Component Lifecycle

	componentDidMount() {
		this.loadMore();
	}

	// #endregion Component Lifecycle

	// #region Render

	renderToolbar() {
		let { postsTypeFilter, username, space } = this.state;
		return (
			<div className="space-posts-page-toolbar">
				{Object.keys(FILTER).map(f =>
					<Chip key={f} label={f} className="btn-filter" color="primary" variant={postsTypeFilter === f ? 'filled' : 'outlined'} onClick={() => this.onFilterByTypeClick(f)} />
				)}
				{username && <Chip label={username} variant="outlined" onDelete={() => this.onToggleSelectedAuthor()} />}
				{space && <Chip label={space} variant="outlined" onDelete={() => this.onToggleSpaceFilter('')} />}
				<div className='filter-time'>
					<span>Show last</span>
					<select onChange={e => this.onTimeFilterChanged(e.target.value)}>
						<option value="3">3 hours</option>
						<option value="6">6 hours</option>
						<option value="12">12 hours</option>
						<option value="24">24 hours</option>
						<option value="48">48 hours</option>
						<option value="168">Week</option>
						<option value="730">Month</option>
						<option value="8760">Year</option>
						<option value="43800">5 Years</option>
					</select>
				</div>
			</div>
		);
	}

	render() {
		let { spacePosts, hasMore, postsTypeFilter } = this.state;
		let isComments = postsTypeFilter === FILTER.Comments;

		return (
			<BasePage
				title="Spaces"
				toolbar={this.renderToolbar()}
				toolbarOpen={true}
				className="space-posts-page"
			>
				<div className="posts">
					<InfiniteScroll scrollOn=".space-posts-page" hasMore={hasMore} loadMore={this.loadMore}>
						<SpacePostsList
							spacePosts={spacePosts}
							asCommentsList={isComments}
							showImg={true}
							parentLink={true}
							commentsLink={true}
							onReply={this.onReply}
							onFeaturedChanged={this.onFeaturedChanged}
							onAuthorClick={this.onToggleSelectedAuthor}
							onSpaceClick={this.onToggleSpaceFilter}
							onDelete={this.onDelete}
						/>
					</InfiniteScroll>
				</div>
			</BasePage>
		);
	}

	// #endregion Render
}

SpacePostsPage.propTypes = {
	searchParams: PropTypes.object
};

export default function WrappedSpacePostsPage(props) {
	let [searchParams] = useSearchParams();
	return <SpacePostsPage searchParams={searchParams} {...props} />;
}
