.asset-gallery-view {
    width: 100%;
    height: 100%;

    .no-results {
        padding: 10px;

        & > div {
            padding: 5px 0;
        }
    }

    .gallery {
        display: flex;
        flex-wrap: wrap;
        user-select: none;

        .item {
            max-width: 200px;
            position: relative;
            margin: 8px;

            &:hover .selectorBackdrop {
                background: linear-gradient(180deg, #00000050, transparent);
                flex-direction: row;

				&.selected {
					background: transparent;
				}
            }

			&:hover .selector {
				opacity: 1.0;
			}

			.selectorBackdrop {
                display: flex;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 30px;

				&.no-select {
					display: none;
				}

				&.selected .selector {
					background-color: #4285f4;
					opacity: 1.0;

					& > i {
						color: white;
					}
				}
			}

			.selector {
				background-color: #eeeeee;
				border-radius: 100%;
				width: 20px;
				height: 20px;
				padding: 1px;
				margin: 5px;
				cursor: pointer;
				opacity: 0;

				& i {
					font-size: 20px;
					color: #999999;
				}

				&.del {
					margin-left: auto;
					background-color: #ff000050;

					& > i {
						color: red;
					}
				}
			}
        }
    }
}
