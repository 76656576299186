import utils from 'client-services/lib/utils-service.js';

var Severity = {
	Ignore: 'Ignore',
	Warn: 'Warn',
	Error: 'Error'
};

class AuditError {
	constructor(severity, code, msg = '', params = null) {
		this.severity = severity;
		this.code = code.toString();
		this.message = msg;
		this.params = params;
	}

	formatMessage(...args) {
		this.message = utils.format(this.message, ...args);
		return this;
	}
}

export {Severity};

export default {
	// illustrator
	illustrator: function(severity, params) {return new AuditError(severity || Severity.Error, 100, 'No Cupixel Illustrator Script stamp found', params);},

	// image-ratio
	viewBoxX: function(severity, params) {return new AuditError(severity || Severity.Error, 200, 'viewBox x value must be %s', params);},
	viewBoxY: function(severity, params) {return new AuditError(severity || Severity.Error, 201, 'viewBox y value must be %s', params);},
	boundingBoxWidth: function(severity, params) {return new AuditError(severity || Severity.Error, 202, 'bounding box width does not match viewBox width', params);},
	boundingBoxHeight: function(severity, params) {return new AuditError(severity || Severity.Error, 203, 'bounding box height does not match viewBox height', params);},
	imageRatio: function(severity, params) {return new AuditError(severity || Severity.Error, 204, 'Invalid image ratio, expected: %s, actual: %s', params);},

	// image-resolution
	viewBoxWidth: function(severity, params) {return new AuditError(severity || Severity.Error, 300, 'Invalid viewBox width, expected: %s, actual: %s', params);},
	viewBoxHeight: function(severity, params) {return new AuditError(severity || Severity.Error, 301, 'Invalid viewBox height, expected: %s, actual: %s', params);},

	// colors
	invalidColor: function(severity, params) {return new AuditError(severity || Severity.Warn, 400, 'Image contains colors not from palette', params);},
	transparentShapes: function(severity, params) {return new AuditError(severity || Severity.Error, 401, 'Image contains shapes with transparent fill', params);},
	tooMuchColors: function(severity, params) {return new AuditError(severity || Severity.Warn, 402, 'Image contains too much colors, maximum allowed: %s, actual: %s', params);},
	tooMuchPaint: function(severity, params) {return new AuditError(severity || Severity.Warn, 403, 'Image contains too much paint of the same color', params);},

	// mc
	highLOD: function(severity, params) {return new AuditError(severity || Severity.Warn, 500, 'Cube %s has high lod, max allowed: %s, actual: %s', params);},
	tinyShapes: function(severity, params) {return new AuditError(severity || Severity.Warn, 501, 'Cube %s has some tiny shapes in it', params);},
	colorsPerMC: function(severity, params) {return new AuditError(severity || Severity.Warn, 502, 'Cube %s has too much colors, maximum allowed: %s, actual: %s', params);},

	// topology
	sameColorAdjacent: function(severity, params) {return new AuditError(severity || Severity.Warn, 600, 'There are adjacent shapes with the same color - merge required', params);},
};
