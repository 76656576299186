import React from 'react';
import PropTypes from 'prop-types';
import {Toolbar, ToolbarIcon} from 'ui-core';
import SVGImage from '../../svg-image/svg-image.jsx';
import {logger, SVGTools} from 'client-services';
import clsx from 'clsx';

import './alt-color-preview.css';

var MIN_DIST_PROP = 0.05;
var MAX_DIST_PROP = 4;

class AltColorPreview extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lastSVG: this.props.svg,
			svg: '',
			de: -1,
			chroma: this.props.chroma,
			hue: this.props.hue,
			maximized: false,
			error: '',
			errorTooltip: ''
		};

		this.svgImage = null;
		this.svgTools = new SVGTools(document);
		this.debounceTimer = undefined;
		this.elem = null;

		this.onToolbarClick = this.onToolbarClick.bind(this);
		this.onCalcPropChange = this.onCalcPropChange.bind(this);
	}

	static getDerivedStateFromProps(np, state) {
		if (np.svg !== state.lastSVG) {
			state.lastSVG = np.svg;
			state.svg = '';
			return state;
		}

		return null;
	}

	replaceColors() {
		this.svgTools.svg = this.props.svg;
		this.svgTools.getColors().then(colors => {
			var error = '';
			var errorTooltip = '';
			var alternatives = this.getAltPalette(colors);
			var notFound = this.svgTools.replaceColors(alternatives.replacements);
			logger.trace('replacement colors generated, result: %O, not found colors: %O', alternatives.replacements, notFound);
			if (notFound.length > 0) {
				error = 'Some colors were not replaced - this is not good';
				errorTooltip = notFound.join(', ');
			}

			var svg = this.svgTools.svg;
			var de = alternatives.de;

			// Reset to original colors
			this.svgTools.svg = this.props.svg;

			this.setState({svg, de, error, errorTooltip});
		});
	}

	getAltPalette(colors) {
		var {chroma, hue} = this.state;
		logger.trace('Generating replacement colors with chroma %s and hue %s for %s colors', chroma, hue, colors.length);
		/** @type {Object.<string, string>} */
		var replacements = {};
		var sumDelta = 0;
		colors.forEach(c => {
			var {hex, de} = this.props.paletteSvc.findBestMatch(c.int, chroma, hue);
			replacements[c.hex] = hex;
			sumDelta += de;
		});

		return {replacements, de: Math.floor(sumDelta / colors.length)};
	}

	onToolbarClick(toolbar, oper, eventTarget) {
		switch (oper) {
			case 'Center':
				this.svgImage && this.svgImage.center();
				break;
			case 'Resize':
				this.setState({maximized: !this.state.maximized}, () => {
					this.elem && this.elem.scrollIntoView();
				});

				break;
			case 'Select':
				this.props.onSelect(this.state.svg, {chroma: this.state.chroma, hue: this.state.hue});
				break;
		}
	}

	onCalcPropChange(ev) {
		var value = parseFloat(ev.target.value);
		var name = ev.target.name;
		if (isNaN(value)) {return;}

		this.setState({[name]: value});
		clearTimeout(this.debounceTimer);
		this.debounceTimer = setTimeout(() => {
			var chroma = Math.max(MIN_DIST_PROP, Math.min(MAX_DIST_PROP, this.state.chroma));
			var hue = Math.max(MIN_DIST_PROP, Math.min(MAX_DIST_PROP, this.state.hue));
			this.setState({svg: '', chroma, hue});
		}, 500);
	}

	renderToolbar() {
		var {de, chroma, hue, maximized} = this.state;
		return (
			<Toolbar primary onAction={this.onToolbarClick}>
				<span className="label">Chroma:</span>
				{this.props.editable ? <input type="number" name="chroma" min={MIN_DIST_PROP} max={MAX_DIST_PROP} step={0.05} value={chroma} onChange={this.onCalcPropChange} /> : <span>{chroma}</span>}
				<span className="label">Hue:</span>
				{this.props.editable ? <input type="number" name="hue" min={MIN_DIST_PROP} max={MAX_DIST_PROP} step={0.05} value={hue} onChange={this.onCalcPropChange} /> : <span>{hue}</span>}
				<span className="label">&Delta;E: {de}</span>
				<ToolbarIcon name="my_location" right title="Center" oper="Center"/>
				<ToolbarIcon name={maximized ? 'fullscreen_exit' : 'fullscreen'} title={maximized ? 'Restore' : 'Maximize'} oper="Resize"/>
				<ToolbarIcon name="check_circle" title="Select this" oper="Select" />
			</Toolbar>
		);
	}

	renderError() {
		if (!this.state.error) {return null;}
		return (
			<div title={this.state.errorTooltip}>{this.state.error}</div>
		);
	}

	renderContent(svg) {
		if (svg) {
			return <SVGImage ref={e => {this.svgImage = e;}} className="image-wrap" svg={svg} minScale={0.05} />;
		}

		return <div className="processing">
			Processing...
		</div>;
	}

	render() {
		var {svg, maximized} = this.state;
		if (!svg) {
			// Important to run this on next-tick to avoid circular render call
			setTimeout(this.replaceColors.bind(this), 100);
		}

		return (
			<div ref={e => {this.elem = e;}} className={clsx('alt-color-preview', {maximized})} style={this.props.style}>
				{this.renderToolbar()}
				{this.renderError()}
				{this.renderContent(svg)}
			</div>
		);

	}
}

AltColorPreview.propTypes = {
	paletteSvc: PropTypes.object.isRequired,
	className: PropTypes.string,
	style: PropTypes.object,
	svg: PropTypes.string,
	editable: PropTypes.bool,
	chroma: PropTypes.number,
	hue: PropTypes.number,
	de: PropTypes.number,
	onSelect: PropTypes.func
};

AltColorPreview.defaultProps = {
	className: '',
	style: {},
	chroma: 1,
	hue: 1,
	svg: '',
	editable: false,
	onSelect: function nop() {}
};

export default AltColorPreview;
