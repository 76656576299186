.audit {
	height: 100%;
}

.audit .results .result {
	display: flex;
	align-items: center;
	font-size: 14px;
	padding: 5px;
	margin: 5px 0;
	background-color: #f0f0f0;
}

.audit .results .result.selected {
	background-color: yellow;
}

.audit .results .result.clickable {
	cursor: pointer;
}

.audit .results .result i {
	font-size: 18px;
	margin-right: 5px;
}

.audit .results .result.passed i {
	color: green;
}

.audit .results .result.warn i {
	color: #ff7800;
}

.audit .results .result.error i {
	color: red;
}
