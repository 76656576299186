import React from 'react';
import { Login, withProtected } from 'app-center-common';;
import TestPage from '../test-page.jsx';
import HomepageDashboard from '../../components/homepage-dashboard/homepage-dashboard.jsx';
import { GalleryGrid, ImageEditor, ImagePage, BlendingPage } from '../../components/gallery';
import ExperienceGallery from '../../components/experience-gallery/experience-gallery.jsx';
import ExperiencesSearchPage from '../../components/experiences-search/experiences-search-page.jsx';
import ExperiencePage from '../../components/experience-page/experience-page.jsx';
import ExperienceFeedGrid from '../../components/experiences-feed-grid/experiences-feed-grid.jsx';
import ExperienceFeedPage from '../../components/experience-feed-page/experience-feed-page.jsx';
import FeedsGrid from '../../components/feeds/feeds-grid.jsx';
import FeedPage from '../../components/feed-page/feed-page.jsx';
import FeedItemPage from '../../components/feed-item-page/feed-item-page.jsx';
import InstructorsPage from '../../components/instructors/instructors-page.jsx';
import PaperBackgrounds from '../../components/paperBackground/paperBackground-page.jsx';
import MediaLibraryPage from '../../components/media-library/media-library-page.jsx';
import SpacePostsPage from '../../components/space-posts/space-posts-page/space-posts-page.jsx';
import SinglePostPage from '../../components/space-posts/single-post-page/single-post-page.jsx';
import EventsList from '../../components/events-list/events-list.jsx';
import LivePage from '../../components/live-page/live-page.jsx';
import ExperiencesFilterConfig from '../../components/experiences-filter-config/experiences-filter-config.jsx';

function getRoutes() {
	let rootRoutes = [
		{ path: 'login', element: <Login /> },
		{ path: 'blending/:id', element: withProtected(<BlendingPage />) },
		{ path: 'test', element: withProtected(<TestPage />) }
	];

	let contentRoutes = [
		{ index: true, element: withProtected(<div>&lt;== Select item from the menu</div>) },
		{ path: '/dashboard', element: withProtected(<HomepageDashboard />) },
		{ path: '/gallery/image/:imageId', element: withProtected(<ImagePage />) },
		{ path: '/gallery', element: withProtected(<GalleryGrid/>)},
		{ path: '/image/editor', element: withProtected(<ImageEditor/>)},
		{ path: '/feeds', element: withProtected(<FeedsGrid />)},
		{ path: '/feed/:fid/item/:fiid', element: withProtected(<FeedItemPage />)},
		{ path: '/feed/:fid?', element: withProtected(<FeedPage />)},
		{ path: '/experiences', element: withProtected(<ExperienceGallery />)},
		{ path: '/experiences/search', element: withProtected(<ExperiencesSearchPage />)},
		{ path: '/experience/:eid', element: withProtected(<ExperiencePage />)},
		{ path: '/experienceFeed/:id', element: withProtected(<ExperienceFeedPage />)},
		{ path: '/experienceFeed', element: withProtected(<ExperienceFeedGrid />)},
		{ path: '/instructors', element: withProtected(<InstructorsPage/>)},
		{ path: '/live', element: withProtected(<LivePage/>)},
		{ path: '/papers', element: withProtected(<PaperBackgrounds/>)},
		{ path: '/events', element: withProtected(<EventsList />)},
		{ path: '/media-lib', element: withProtected(<MediaLibraryPage/>)},
		{ path: '/space-posts', element: withProtected(<SpacePostsPage/>)},
		{ path: '/space/:spaceId/post/:id', element: withProtected(<SinglePostPage/>)},
		{ path: '/experiences/filter/config', element: withProtected(<ExperiencesFilterConfig/>)}
	];

	return { rootRoutes, contentRoutes };
}

export default getRoutes;