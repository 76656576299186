import {GridFormatters} from 'ui-core';

var InstructorGridHelper = {
	rowHeight: 70
};

InstructorGridHelper.getColumns = function getColumns() {
	return [
		{
			key: 'avatar',
			width: Math.floor(InstructorGridHelper.rowHeight * (15 / 11)),
			formatter: GridFormatters.image({maxHeight: InstructorGridHelper.rowHeight, verticalAlign: 'middle', padding: '5px', borderRadius: '50%'})

		},
		{
			key: 'id@250',
			formatter: GridFormatters.copyToClipboard(),
		},
		{
			key: 'name@300',
		},
		{
			key: 'email',
			formatter: GridFormatters.email(),
		},
	];
};

export default InstructorGridHelper;
