import React from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from '../../lib/constants.js';
import { useSearchParams } from 'react-router-dom';
import { BasePage } from 'app-center-common';
import ExperienceGrid from './experience-grid.jsx';
import { messageBox, snackbar, AddButtonFAB, InfiniteScroll, toEnumSchema } from 'ui-core';
import { config, api, logger, utils } from 'client-services';
import { spliceImmutable } from '../../lib/immutable-utils.js';

import './experience-gallery.css';

const EXP_DEEP_LINK = (config.get('services:experience:expDeepLinkTemplate') || '').replace(/\/$/, '');

var FETCH_IMG_COUNT = 50;
let SEARCH_SCHEMA = {
	name: {},
	tags: {},
	medium: {},
	status: {enum: Object.keys(CONSTANTS.ExperienceStatus)},
	accessLevel: {enum: Object.keys(CONSTANTS.ExperienceAccessLevel)},
	shareWithPartners: toEnumSchema(CONSTANTS.SharedPartners),
	id: { $ref: '#/definitions/MongoObjectID' },
};

class ExperienceGallery extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			experiences: [],
			search: this.props.searchParams.get('q') || '',
			total: 0,
		};

		this.page = 0;

		this.loadMore = this.loadMore.bind(this);
		this.searchChanged = this.searchChanged.bind(this);
		this.onExpAction = this.onExpAction.bind(this);
		this.onExpClick = this.onExpClick.bind(this);
	}

	// #region Event Handlers

	onExpClick(idx) {
		if (this.state.experiences[idx]?.id) {
			window.open(`/experience/${this.state.experiences[idx].id}`);
		}
	}

	async onExpAction(oper, id) {
		var exp = this.state.experiences.find(i => i.id === id);
		if (!exp) {
			logger.error('Got %s operation on exp `%s` which was not found in the exp list', oper, id);
			return;
		}

		let status;
		switch (oper) {
			case 'CopyID':
				status = await utils.copyToClipboard(exp.id);
				snackbar(status ? 'Experience ID copied' : 'Opps...', 1000);
				break;
			case 'CopyVideoUrl':
				status = await utils.copyToClipboard(exp.sessions?.[0]?.video?.url);
				snackbar(status ? 'Video url copied' : 'Opps...', 1000);
				break;
			case 'CopyDeepLink':
				status = await utils.copyToClipboard(`${EXP_DEEP_LINK}/${exp.id}`);
				snackbar(status ? 'deeplink copied' : 'Opps...', 1000);
				break;
			case 'Space':
				if (exp.space) {
					utils.openWindow(`/space-posts?space=${exp.space}`);
				} else {
					snackbar('Experience has no Space');
				}

				break;
			case 'Edit':
				utils.openWindow(`/experience/${exp.id}`);
				break;
			case 'Delete':
				this.deleteExperience(exp);
				break;
			default:
				logger.error('Got invalid exp tool operation:', oper);
		}
	}

	// #endregion Event Handlers

	// #region Methods

	editExperience(exp) {
		var url = `/experience/${exp.id}`;
		utils.openWindow(url);
	}

	deleteExperience(exp) {
		messageBox('Are you sure?', '', [messageBox.Buttons.Cancel, 'DELETE FOREVER']).promise.then(result => {
			if (result === 'Cancel') {
				logger.trace('User bailed on delete exp:', exp.id);
				return;
			}

			logger.trace('Got confirmation to delete exp:', exp.id);
			api.experience.deleteExperience({ id: exp.id }).send((err, res) => {
				if (err) {
					logger.error('Got error on delete exp `%s`, error:', exp.id, err);
					messageBox('Error', err.message, messageBox.Buttons.OK);
					return;
				}

				logger.trace('Got delete response for exp `%s`, res:', exp.id, res);
				var idx = this.state.experiences.findIndex(e => e.id === exp.id);
				if (idx >= 0) {
					this.setState({experiences: spliceImmutable(this.state.experiences, idx, 1)});
				}
			});
		});
	}

	// #endregion Methods

	componentDidMount() {
		this.loadMore();
	}

	searchChanged(search) {
		this.setState({ search, hasMore: true, experiences: [] }, () => {
			this.page = 0;
			this.loadMore();
		});
	}

	async loadMore() {
		var count = FETCH_IMG_COUNT;
		var skip = this.page * FETCH_IMG_COUNT;
		logger.trace('Fetching experiences, count: %s, skip: %s', count, skip);
		var data;
		try {
			data = await api.experience.getExperiences({ count, skip, q: this.state.search }).send();
		} catch (ex) {
			messageBox('Error', ex.message);
			return false;
		}

		// Must be called before setState so it will be applied when we re-render
		let total = data.total >= 0 ? data.total : this.state.total;
		var hasMore = (data.experiences.length === FETCH_IMG_COUNT);
		++this.page;
		this.setState({ total, hasMore, experiences: [...this.state.experiences, ...data.experiences]});
	}

	render() {
		return (
			<BasePage
				className='exp-gallery'
				title="Experiences"
				subTitle={`(${this.state.total} exps)`}
				toolbarOpen={true}
				showSearch={true}
				search={this.state.search}
				searchFields={SEARCH_SCHEMA}
				onSearch={ this.searchChanged }
			>
				<InfiniteScroll scrollOn=".exp-gallery" hasMore={this.state.hasMore} loadMore={this.loadMore}>
					<ExperienceGrid
						experiences={this.state.experiences}
						onAction={this.onExpAction}
						onClick={this.onExpClick}
					/>
					<AddButtonFAB href="/experience/new" />
				</InfiniteScroll>
			</BasePage>
		);
	}
}

ExperienceGallery.propTypes = {
	searchParams: PropTypes.object
};

export default function WrappedExperienceGallery(props) {
	let [searchParams] = useSearchParams();
	return <ExperienceGallery searchParams={searchParams} {...props} />;
};
