.editor-title > .details {
	margin-left: 20px;
	font-size: 0.7em;
}

.image-editor {
	padding: 0 !important;
}

.image-editor > .panels {
	width: 100%;
	height: 100%;
}

.image-editor .image-container {
	display: flex;
	flex-direction: column;
}

.image-editor .svg-image-wrap {
	flex: 1;
	overflow: auto;
	padding: 5px;
}

.image-editor .svg-image-wrap .dropzone {
	position: relative;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
}
