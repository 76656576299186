import React from 'react';
import { Button } from '@mui/material';
import { messageBox } from 'ui-core';
import { BasePage, MongoDBDashboard } from 'app-center-common';
import { config, utils } from 'client-services';

let cfgHomeDashboardUrl = config.get('data:mongoChartsDashboard:home:url');
let cfgHomeDashboardId = config.get('data:mongoChartsDashboard:home:id');

const PAGE_STYLE = {
	padding: 0,
};

class HomepageDashboard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			pendingDownload: false
		};

		this.dashboard = null;

		this.onChartClick = this.onChartClick.bind(this);
		this.onDashboardReady = this.onDashboardReady.bind(this);
	}

	async onDashboardReady(dashboard) {
		this.dashboard = dashboard;
		let charts = await dashboard.getAllCharts();
		charts.forEach(c => {
			c.addEventListener('click', this.onChartClick);
		});
	}

	async onChartClick(ev) {
		if (!this.dashboard) {
			alert('Dashboard not initialized');
			return;
		}

		if (!this.state.pendingDownload) { return; }

		let chart = await this.dashboard.getChart(ev.chartId);
		if (!chart) {
			alert('Hmm could not get chart from dashboard');
			return;
		}

		let mb = messageBox('Downloading...');
		try {
			let data = await chart.getData();
			let { fields, documents } = data;
			let headers = Object.values(fields).join(',');
			let rows = [];
			documents.forEach(doc => {
				let row = Object.keys(fields).map(f => this.chartFieldToString(doc[f])).join(',');
				rows.push(row);
			});
			let csv = `${headers}\n${rows.join('\n')}`;
			var blob = new Blob([csv]);
			utils.downloadBlob(blob, 'cc_chart_data.csv');
			mb.close();
		} catch (ex) {
			mb.setTitle('Error');
			mb.setBody(ex.message);
		}

		this.setState({ pendingDownload: false });
	}

	chartFieldToString(data) {
		if (typeof data === 'string') {
			return `"${data}"`;
		}

		if (typeof data === 'object' && data instanceof Date) {
			return utils.toDateString(data, true);
		}

		return data;
	}

	renderToolbar() {
		let { pendingDownload } = this.state;
		return (
			<div>
				<Button
					variant="outlined"
					onClick={() => this.setState({ pendingDownload: !pendingDownload })}
				>
					{pendingDownload ? 'Tap on chart to download' : 'Download Chart Data'}
				</Button>

			</div>
		);
	}

	render() {
		return (
			<BasePage
				title="Cupixel Studio"
				contentStyle={PAGE_STYLE}
				appSidebarOpen={false}
				toolbar={this.renderToolbar()}
				toolbarOpen={true}
			>
				<MongoDBDashboard
					dashboardUrl={cfgHomeDashboardUrl}
					dashboardId={cfgHomeDashboardId}
					onReady={this.onDashboardReady}
				/>
			</BasePage>
		);
	}
}

export default HomepageDashboard;
