import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'svg.js';
import 'svg.panzoom.js';

import './svg-image.css';

class SVGImage extends React.Component {
	constructor(props) {
		super(props);
		this.svgWrap = null;
		this.svg = null;
		this.initialViewbox = [];
		this.gridGroup = null;
		this.cubesGroup = null;
		this.landscape = false;
		this.rows = 0;
		this.cols = 0;
		this.naturalWidth = 0;
		this.naturalHeight = 0;
		this.mouseDownPos = {x: 0, y: 0};
	}

	loadSvg(svg, viewbox = null) {
		this.svgWrap.innerHTML = svg;
		var svgElem = this.svgWrap.querySelector('svg');
		this.svg = new SVG(svgElem);
		window.svg = this.svg;

		if (!this.svg.viewbox().width || !this.svg.viewbox().height) {
			this.svg.viewbox(0, 0, this.svg.bbox().width, this.svg.bbox().height);
		}

		var {x, y, width, height} = this.svg.viewbox();
		this.initialViewbox = [x, y, width, height];

		this.landscape = width > height;
		this.naturalWidth = width;
		this.naturalHeight = height;

		var minScale;
		var maxScale;
		if (this.landscape) {
			minScale = this.props.minScale || ((this.svg.node.getBoundingClientRect().width / this.naturalWidth) * 0.2); // 1/5 of screen width
			maxScale = this.props.maxScale || ((this.svg.node.getBoundingClientRect().width / this.naturalWidth) * 10);
		} else {
			minScale = this.props.minScale || ((this.svg.node.getBoundingClientRect().height / this.naturalHeight) * 0.2);
			maxScale = this.props.maxScale || ((this.svg.node.getBoundingClientRect().height / this.naturalHeight) * 10);
		}

		this.addOrigColorProp(this.svg); // Important to run this before adding the grid lines

		if (this.props.grid.length === 2) {
			this.rows = this.landscape ? Math.min(...this.props.grid) : Math.max(...this.props.grid);
			this.cols = this.landscape ? Math.max(...this.props.grid) : Math.min(...this.props.grid);

			this.drawGrid();
			this.drawCubes();
		}

		if (this.props.bgImage) {
			this.svg.image(this.props.bgImage).id('bg-image').back();
		}

		this.updateSelectedElement();

		// only now it is safe to apply the requested viewbox (the grid lines has to be drawn on the original viewbox)
		if (viewbox) {
			this.svg.viewbox(viewbox.x, viewbox.y, viewbox.width, viewbox.height);
		}

		var zoomFactor = window.navigator.userAgent.toLowerCase().includes('macintosh') ? 0.005 : 0.05;
		this.svg.panZoom({zoomMin: minScale, zoomMax: maxScale, zoomFactor});

		this.registerToEvents();
		if (this.props.onLoad) {this.props.onLoad();}
	}

	registerToEvents() {
		if (this.props.elemClick) {
			this.svg.dblclick(this.onDblClick.bind(this));
		}

		if (this.props.mouseMove) {
			this.svg.mousemove(e => this.raiseMouseEvent(e, this.props.mouseMove));
		}

		if (this.props.click) {
			// For mouse click we will use mouseup but we have to make sure that the mousedown/mouseup combo was not due to
			// pan action (se we save the mouse loc)
			this.svg.mousedown(e => {
				this.mouseDownPos.x = e.clientX;
				this.mouseDownPos.y = e.clientY;
			});

			this.svg.mouseup(e => {
				if (e.clientX === this.mouseDownPos.x && e.clientY === this.mouseDownPos.y) {
					this.raiseMouseEvent(e, this.props.click);
				}
			});
		}
	}

	center() {
		this.svg.viewbox(...this.initialViewbox);
	}

	highlightCubes(idxs, color, opacity = 0.7) {
		if (!this.cubesGroup) {return;}
		idxs = Array.isArray(idxs) ? idxs : [idxs];
		idxs.forEach(idx => {
			var cube = this.cubesGroup.children()[idx];
			if (!cube) {return;}
			cube.attr({fill: color, 'fill-opacity': opacity});
		});
	}

	clearCubesHighlight() {
		if (!this.cubesGroup) {return;}
		this.cubesGroup.children().forEach(c => c.fill('none'));
	}

	flickerCubesHighlight(idxs, color = 'yellow', times = 6) {
		if (!this.cubesGroup) {return;}
		idxs = Array.isArray(idxs) ? idxs : [idxs];
		var cubes = this.cubesGroup.children().filter((x, i) => idxs.includes(i));
		var origColors = cubes.map(c => c.attr('fill'));

		function toggle(i) {
			cubes.forEach((c, x) => {
				var toColor = (i % 2) > 0 && (i !== times) ? color : origColors[x];
				c.fill(toColor);
				if (i < times) {
					setTimeout(toggle, 50, ++i);
				}
			});
		}

		toggle(1);
	}

	selectCubes(idxs, color = 'yellow', width = 2) {
		if (!this.cubesGroup) {return;}
		idxs = Array.isArray(idxs) ? idxs : [idxs];
		idxs.forEach(idx => {
			var cube = this.cubesGroup.children()[idx];
			if (!cube) {return;}
			cube.stroke({color, width});
		});
	}

	deselectCubes(idxs) {
		if (!this.cubesGroup) {return;}
		idxs = Array.isArray(idxs) ? idxs : [idxs];
		idxs.forEach(idx => {
			var cube = this.cubesGroup.children()[idx];
			if (!cube) {return;}
			cube.stroke('none');
		});
	}

	clearCubesSelection() {
		if (!this.cubesGroup) {return;}
		this.cubesGroup.children().forEach(c => c.stroke('none'));
	}

	addOrigColorProp(svg) {
		var elems = svg.node.querySelectorAll('*');
		elems.forEach(e => {
			if (!(e instanceof SVGGeometryElement)) { return;}
			var c = window.getComputedStyle(e).fill.replace(/\s/g, '');
			if (!c) {return;}
			var match = c.match(/rgb\(([0-9]+),([0-9]+),([0-9]+)\)/);
			if (!match || match.length !== 4) {return;}
			var r = parseInt(match[1]);
			var g = parseInt(match[2]);
			var b = parseInt(match[3]);
			var rgb = `#${r < 16 ? '0' : ''}${r.toString(16)}${g < 16 ? '0' : ''}${g.toString(16)}${b < 16 ? '0' : ''}${b.toString(16)}`.toUpperCase();
			e.setAttribute('data-orig-color', rgb.substring(1));
		});
	}

	drawGrid() {
		var w = this.naturalWidth;
		var h = this.naturalHeight;
		var stroke = this.props.gridColor;
		var strokeW = this.props.gridWidth;
		this.gridGroup = this.svg.group().id('gridGrp');
		var draw = this.gridGroup;

		// Verticals
		var xSteps = Math.floor(w / this.cols);
		for (var i = 1; i < this.cols; ++i) {
			var x = i * xSteps;
			draw.line(x, 0, x, h).stroke({ width: strokeW, color: stroke }).attr({'vector-effect': 'non-scaling-stroke', 'shape-rendering': 'crispEdges'}).id(null);
		}

		// Horizontals
		var ySteps = Math.floor(h / this.rows);
		for (i = 1; i < this.rows; ++i) {
			var y = i * ySteps;
			draw.line(0, y, w, y).stroke({ width: strokeW, color: stroke }).attr({'vector-effect': 'non-scaling-stroke', 'shape-rendering': 'crispEdges'}).id(null);
		}
	}

	drawCubes() {
		var w = this.naturalWidth;
		var h = this.naturalHeight;
		var xSteps = Math.floor(w / this.cols);
		var ySteps = Math.floor(h / this.rows);
		this.cubesGroup = this.svg.group().id('cubesGrp');
		var draw = this.cubesGroup;

		// Draw the cubes according to the project MC index (0 is lower left)
		for (var row = 0; row < this.rows; row++) {
			var y = (this.rows - row - 1) * ySteps;
			for (var col = 0; col < this.cols; col++) {
				var x = col * xSteps;
				draw.rect(xSteps, ySteps).x(x).y(y).attr({
					'vector-effect': 'non-scaling-stroke',
					'shape-rendering': 'crispEdges',
					'pointer-events': 'none',
					fill: 'none'
				}).id(null);
			}
		}

		window.cubes = this.cubesGroup;
	}

	updateGridStyle() {
		if (!this.gridGroup) {return;}
		this.gridGroup.select('line').stroke({color: this.props.gridColor, width: this.props.gridWidth});
	}

	updateSelectedElement() {
		var shouldHighlight = this.props.selectedElements.length > 0 && this.props.isolateSelection;
		var noFill = this.props.selectionBGColor;

		// Reset style for all elements
		this.svg.select('[data-orig-color]').each(function _() {
			// If we should highlight we first remove the fill from all elements
			var fill = shouldHighlight ? noFill : '#' + this.node.getAttribute('data-orig-color'); // can't use svg.js attr() getter as it parse numbers so a color of "029999" will be returned badly as 299999
			var stroke = shouldHighlight ? 'black' : '';
			var strokeOpacity = shouldHighlight ? 0.5 : null;
			this.style({fill, stroke, 'stroke-opacity': strokeOpacity});

			// Deselect the currently selected
			if (this.hasClass('selected')) {
				this.attr('vector-effect', null);
				this.removeClass('selected');
				this.removeClass('flashing');
			}
		});

		// Now select and highlight the selected elements
		if (this.props.selectedElements.length > 0) {
			var toSelect = this.svg.select(this.props.selectedElements.map(s => `#${s}`).join(','));
			if (toSelect) {
				toSelect.attr('vector-effect', 'non-scaling-stroke');
				toSelect.addClass('selected');
				if (!this.props.hideSelection) {
					toSelect.addClass('flashing');
				}

				if (this.props.isolateSelection) {
					toSelect.each(function _() {
						var fill = '#' + this.node.getAttribute('data-orig-color');
						this.style({fill});
					});
				}
			}
		}
	}

	onDblClick(e) {
		if (!e || !e.target) {return;}
		var target = e.target === this.svg.node ? null : e.target; // Clicking on root element is like clicking on nothing
		this.props.elemClick(target, {alt: e.altKey, shift: e.shiftKey});
	}

	raiseMouseEvent(e, fn) {
		// svg.js already convert the client x/y to svg coordinate system x/y
		var p = this.svg.point(e.clientX, e.clientY);

		var cube = -1;
		if (this.rows && this.cols && p.x >= 0 && p.y >= 0 && p.x <= this.naturalWidth && p.y <= this.naturalHeight) {
			var col = Math.min(Math.floor(p.x / this.naturalWidth * this.cols), this.cols - 1);
			var row = (this.rows - 1) - (Math.min(Math.floor(p.y / this.naturalHeight * this.rows), this.rows - 1)); // Row 0 is the most bottom
			cube = row * this.cols + col;
		}

		fn(e, cube);
	}

	shouldComponentUpdate(np) {
		if (np.svg !== this.props.svg || np.bgImage !== this.props.bgImage) {
			this.props = np;
			this.loadSvg(np.svg, this.svg ? this.svg.viewbox() : null);
			return false;
		}

		var updSelected = (np.selectionBGColor !== this.props.selectionBGColor) || (np.isolateSelection !== this.props.isolateSelection) || (np.hideSelection !== this.props.hideSelection) || (np.selectedElements !== this.props.selectedElements);
		var updGrid = np.gridColor !== this.props.gridColor || np.gridWidth !== this.props.gridWidth;
		var updCubes = np.selectedCubes !== this.props.selectedCubes;
		this.props = np;

		if (updSelected) {
			this.updateSelectedElement();
		}

		if (updGrid) {
			this.updateGridStyle();
		}

		if (updCubes) {
			this.clearCubesSelection();
			this.selectCubes(this.props.selectedCubes);
		}

		return false;
	}

	componentDidMount() {
		if (this.props.svg) {
			this.loadSvg(this.props.svg);
		}
	}

	render() {
		return (
			<div className={`svg-image ${this.props.className}`} style={this.props.style} >
				<div className="svg-wrap" ref={elem => {this.svgWrap = elem;}} />
			</div>
		);
	}
}

SVGImage.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
	svg: PropTypes.string.isRequired,
	grid: PropTypes.arrayOf(PropTypes.number),
	gridColor: PropTypes.string,
	gridWidth: PropTypes.number,
	bgImage: PropTypes.string,
	selectionBGColor: PropTypes.string,
	selectedElements: PropTypes.arrayOf(PropTypes.string),
	selectedCubes: PropTypes.arrayOf(PropTypes.number),
	isolateSelection: PropTypes.bool,
	hideSelection: PropTypes.bool,
	minScale: PropTypes.number,
	maxScale: PropTypes.number,
	onLoad: PropTypes.func,
	click: PropTypes.func,
	elemClick: PropTypes.func,
	mouseMove: PropTypes.func
};

SVGImage.defaultProps = {
	className: '',
	style: {},
	grid: [],
	gridColor: 'black',
	gridWidth: 1,
	bgImage: '',
	selectionBGColor: 'white',
	selectedElements: [],
	selectedCubes: [],
	isolateSelection: true,
	hideSelection: false,
	onLoad: null,
	click: null,
	elemClick: null,
	mouseMove: null
};

export default SVGImage;
