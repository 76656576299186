import React from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from '../../../lib/constants.js';
import { Link } from 'react-router-dom';
import { Button, Collapse } from '@mui/material';
import { ToolbarIcon, MaterialIcon } from 'ui-core';
import CommentsList from '../comments-list.jsx';
import { utils, config } from 'client-services';
import clsx from 'clsx';

import './space-post.css';

const USER_SPOTLIGHT_LINK = config.get('services:user:userSpotlightPage');

class SpacePost extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showComments: false,
			fullScreenImg: false
		};
	}

	renderSpacePostImage(mediaItems, isComment, parentMediaItems) {
		let mediaItem;
		if (!isComment) {
			mediaItem = mediaItems[0];
		} else {
			if (this.props.showImg && parentMediaItems?.length > 0) {
				mediaItem = parentMediaItems[0];
			}
		}

		return (
			<div className={clsx('spacePost-mediaItem', { full: this.state.fullScreenImg })} onClick={() => this.setState({ fullScreenImg: false})}>
				<MediaItem mediaItem={mediaItem} onClick={(e) => {
					this.setState({ fullScreenImg: !this.state.fullScreenImg });
					e.stopPropagation();
				}} />
			</div>
		);
	}

	renderCommentsLink(spacePost) {
		return (
			<Button
				color="inherit"
				variant="text"
				size="small"
				onClick={() => {
					let showComments = spacePost.commentsCount > 0 ? !this.state.showComments : false;
					this.setState({ showComments });
				}}
			>
				{spacePost.commentsCount} comments
				{spacePost.commentsCount > 0 && <MaterialIcon name={this.state.showComments ? 'expand_less' : 'expand_more'} />}
			</Button>
		);
	}

	render() {
		let {className, spacePost, parentLink, commentsLink, onAuthorClick, onSpaceClick} = this.props;

		let diff = utils.timeDiffToString(spacePost.createdAt, new Date());
		let postTime = utils.toDateString(spacePost.createdAt);
		let isComment = !!spacePost.parent;

		return (
			<div className={clsx(className, 'space-post')}>
				<div className="title">
					<ToolbarIcon name="open_in_new" href={`/space/${spacePost.space}/post/${spacePost.parent || spacePost.id}/`} target="_blank" />
					<span className="space-name" onClick={() => onSpaceClick?.(spacePost.space)}>{spacePost.spaceName}</span>
					{/* <Link target="_blank" to={`/space/${spacePost.space}/post/${spacePost.parent || spacePost.id}/`}>{spacePost.spaceName}</Link> */}
				</div>
				<div className="content">
					<div className="post-details">
						<div className="avatar">
							<img src={spacePost.avatar} onClick={() => onAuthorClick?.(spacePost.author, spacePost.username)} />
						</div>
						<div className="details">
							<div className="subtitle">
								<span className="username" onClick={() => onAuthorClick?.(spacePost.author, spacePost.username)}>{spacePost.username}</span>
								<span className="divider">•</span>
								<ToolbarIcon name="highlight" href={utils.format(USER_SPOTLIGHT_LINK, spacePost.author)} target="_blank" />
								<span className="divider">•</span>
								<span>{spacePost.member ? 'Member' : 'User'}</span>
								<span className="divider">•</span>
								{spacePost.coppaProtected && <>
									<span>COPPA</span>
									<span className="divider">•</span>
								</>}
								<span title={postTime}>{diff}</span>
							</div>
							<div className="message">
								{ spacePost.message }
							</div>
							{isComment && parentLink &&
								<div className="parentIdLink">
									<Link target="_blank" to={`/space/${spacePost.space}/post/${spacePost.parent}/`}>Parent Space Post</Link>
								</div>
							}
							<div className="toolbar">
								{!isComment && <Button className="reply" color="inherit" variant="text" size="small" onClick={() => this.props.onReply(spacePost)}>Reply</Button>}
								{!isComment && commentsLink && this.renderCommentsLink(spacePost) }
								{!isComment && <ToolbarIcon className={clsx({featured: !!spacePost.featured})} name="star" size="medium" outlined={!spacePost.featured} title="Featured" onClick={() => this.props.onFeaturedChanged(spacePost)} /> }
								{spacePost.email && <a className="email" href={`mailto:${spacePost.email}`}><ToolbarIcon className="mail" name="mail" title="E-Mail" color="primary" /></a> }
								<ToolbarIcon className="delete" name="delete" title="Delete" color="secondary" onClick={() => this.props.onDelete(spacePost)} />
							</div>
						</div>
					</div>
					<div className="ui-element">
						{this.renderSpacePostImage(spacePost.mediaItems, isComment, spacePost.parentMediaItems)}
					</div>
				</div>
				<Collapse className="comments" in={this.state.showComments} unmountOnExit>
					<CommentsList
						postId={spacePost.id}
						onDelete={this.props.onDelete}
					/>
				</Collapse>
			</div>
		);
	}
}

SpacePost.propTypes = {
	className: PropTypes.string,
	spacePost: PropTypes.object,
	showImg: PropTypes.bool,
	parentLink: PropTypes.bool,
	commentsLink: PropTypes.bool,
	onReply: PropTypes.func,
	onFeaturedChanged: PropTypes.func,
	onAuthorClick: PropTypes.func,
	onSpaceClick: PropTypes.func,
	onDelete: PropTypes.func
};

function MediaItem(props) {
	let { mediaItem, onClick } = props;
	if (!mediaItem?.url) {
		return <div />;
	}

	if (mediaItem.type === CONSTANTS.SpacePostMediaType.Image) {
		return <img src={mediaItem.url} onClick={onClick} />;
	}

	if (mediaItem.type === CONSTANTS.SpacePostMediaType.Video) {
		return (
			<video
				controls
				preload='none'
				poster={mediaItem.poster}
			>
				<source src={mediaItem.url} type="video/mp4" />
			</video>
		);
	}
}

MediaItem.propTypes = {
	mediaItem: PropTypes.object,
	onClick: PropTypes.func
};

export default SpacePost;
