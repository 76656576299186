.exp-search-page {
	display: flex;
	flex-direction: column;

	.scroller {
		height: 100%;
		overflow: auto;
		margin-top: 10px;
	}

	.alt-notice {
		margin-top: 5px;
		color:red;
	}

	.results {
		height: 100%;
		flex: 1;
		overflow: auto;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.tools {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.exp {
		width: 200px;
		margin-right: 20px;
		margin-bottom: 20px;
		font-size: 0.8em;

		.thumb {
			max-width: 100%;
			cursor: pointer;
		}

		.bottom-line {
			display: flex;
			flex-direction: row;

			.name {
				flex: 1;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				margin-right: 5px;
			}

			.similar-link {
				color: blue;
				cursor: pointer;
			}
		}
	}

	.similar {
		border-top: 2px solid;
		margin-top: 10px;

		.title {
			font-size: 1.4em;
			margin: 10px 0;

			.close {
				margin-left: 10px;
				font-size: 0.6em;
				color: rgb(68, 68, 255);
				cursor: pointer;
			}
		}

		.list {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;

			.exp {
				width: 150px;
			}
		}
	}

	.types-radio-group {
		display: flex;
		flex-direction: row;
		align-items: center;

		span.title {
			margin-right: 5px;
		}
	}
}