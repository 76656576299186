import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { QueryBuilder, formatQuery } from 'react-querybuilder';
import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import { parseMongoDB } from 'react-querybuilder/parseMongoDB';
import { messageBox, Modal, Checkbox, ToolbarIcon } from 'ui-core';
import { validateQuery } from './validators.js';
import { expQueryFields, queryOperators, defaultQuery } from './exp-query-fields.js';
import CustomValueEditor from './custom-value-editor.jsx';
import useQBClasses from './classes.js';
import clsx from 'clsx';

import 'react-querybuilder/dist/query-builder.css';

function toMongoQuery(query) {
	return JSON.parse(formatQuery(query, { format: 'mongodb' }));
}

function showAIPrompt(query) {
	let q = JSON.stringify(toMongoQuery(query));
	let body = (
		<div>
			<div>Explain the following MongoDB query:</div>
			<pre style={{whiteSpace: 'pre-wrap'}}>{q}</pre>
		</div>
	);

	messageBox('Ask Gemini', body);
}

function ExpFeedQueryBuilder(props) {
	let { open, initialQuery, onClose, onDeleteQuery } = props;

	let [query, setQuery] = useState(parseMongoDB(initialQuery || defaultQuery(), {listsAsArrays: true}));
	let [debug, setDebug] = useState(false);
	let { classes } = useQBClasses();

	useEffect(() => {
		console.log('Updating exp query filter state');
		setQuery(parseMongoDB(initialQuery || defaultQuery(), { listsAsArrays: true }));
	}, [initialQuery]);

	function onSubmit() {
		// @ts-ignore
		let res = validateQuery(query, expQueryFields);
		let errors = Object.values(res).filter(r => !r.valid);
		if (errors.length > 0) {
			let body = (
				<ul>
					{errors.map((e, i) => (
						<li key={i}>
							{ e.field && `Error in field \`${e.field}\`,` } error: { e.reasons[0] || 'unknown' }
						</li>
					))}
				</ul>
			);

			messageBox('Error', body);
			return;
		}

		let q = toMongoQuery(query);
		onClose?.(q);
	}

	return (
		<Modal
			open={open}
			className={clsx(classes.root, {'debug-mode': debug})}
			fullscreen
			showCloseButton={false}
		>
			<div className="content">
				<div className="qb">
					<QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
						<QueryBuilder
							fields={expQueryFields}
							query={query}
							autoSelectField={false}
							listsAsArrays
							operators={queryOperators}
							onQueryChange={q => setQuery(q)}
							controlElements={{valueEditor: CustomValueEditor}}
							controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
							// @ts-ignore
							validator={q => validateQuery(q, expQueryFields)}
						/>
					</QueryBuilderDnD>
				</div>
				<div className="debug-pane">
					<pre>{JSON.stringify(toMongoQuery(query), null, '  ')}</pre>
				</div>
			</div>
			<div className="buttons">
				<Checkbox className="chk-dbg" label="Show Query" checked={debug} onCheck={(ev, checked) => setDebug(checked)} />
				<ToolbarIcon className="ai" name="pages" outlined onClick={() => showAIPrompt(query)} />
				<Button color="primary" variant="contained" onClick={onSubmit}>Save</Button>
				<Button color="warning" variant="contained" onClick={() => onDeleteQuery?.()}>Delete</Button>
				<Button color="secondary" variant="contained" onClick={() => onClose?.()}>Close</Button>
			</div>
		</Modal>
	);
}

ExpFeedQueryBuilder.propTypes = {
	open: PropTypes.bool,
	initialQuery: PropTypes.object,
	onClose: PropTypes.func,
	onDeleteQuery: PropTypes.func
};

export default ExpFeedQueryBuilder;
