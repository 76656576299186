import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'ui-core';
import { EventSchema, EventSchemaUI } from './event.schema.js';
import Form from '../form/form.jsx';
import {utils} from 'client-services';
import './event-edit-model.css';

class EventEditModal extends React.Component {
	constructor(props) {
		super(props);

		var event = utils.extend(true, {}, this.props.event);
		if (!event.startDate) { event.startDate = undefined;}
		if (!event.endDate) { event.endDate = undefined;}

		this.state = {
			event
		};
	}

	renderForm() {
		return (
			<Form
				formData={this.state.event}
				schema={EventSchema}
				uiSchema={EventSchemaUI}
				submit={this.props.onClose}
				showErrorMessageBox={true}
			/>
		);
	}

	render() {
		return (
			<Modal className='event-edit-modal' open={this.props.open} onClose={this.props.onClose}>
				{this.props.open ? this.renderForm() : ''}
			</Modal>
		);
	}
}

EventEditModal.propTypes = {
	event: PropTypes.object,
	open: PropTypes.bool,
	disabled: PropTypes.bool,
	onClose: PropTypes.func
};

function nop() {}
EventEditModal.defaultProps = {
	open: false,
	disabled: false,
	onClose: nop,
	event: {
		id: '',
		name: '',
		enabled: true,
		loginRequired: true,
		feed: '',
		startDate: '',
		endDate: '',
		unlockCode: '',
		description: ''
	}
};

export default EventEditModal;
