import { compileSchema } from 'ui-core';
import { utils } from 'client-services';

/** @type {import("@rjsf/utils").RJSFSchema} */
var schema = {
	type: 'object',
	required: ['name', 'feed'],
	properties: {
		name: { type: 'string' },
		description: { type: 'string' },
		enabled: { type: 'boolean' },
		loginRequired: { type: 'boolean' },
		startDate: { type: ['string', 'null'], format: 'date-time', title: `Start (${utils.clientTimezoneString()})` },
		endDate: { type: ['string', 'null'], format: 'date-time', title: `End (${utils.clientTimezoneString()})` },
		feed: { $ref: '#/definitions/MongoObjectID', description: 'Experience feed of the event' },
		id: { type: 'string', readOnly: true }
	}
};

var schemaUI = {
	description: {
		'ui:widget': 'textarea',
		'ui:options': {
			rows: 2
		}
	},
	feed: {
		'ui:field': 'singleEntityPicker',
		'ui:options': {
			picker: 'ExperienceFeedPicker'
		},
		'ui:title': 'Experience Feed'
	},
};

schema = compileSchema(schema);
export {
	schema as EventSchema,
	schemaUI as EventSchemaUI
};
