import React from 'react';
import { messageBox, Toolbar, ToolbarIcon } from 'ui-core';
import { BasePage } from 'app-center-common';
import { Chip, TextField } from '@mui/material';
import SortableList, { SortableItem } from 'react-easy-sort';
import { arrayMoveImmutable } from '../../lib/immutable-utils.js';
import { api, logger } from 'client-services';

import './experience-filter-config.css';

const TOOLBAR_TABS = {
	TAGS: 'TAGS',
	MEDIUM: 'MEDIUM',
	INSTRUCTOR: 'INSTRUCTOR',
	ARTISTS: 'ARTISTS',
	POPULAR: 'POPULAR',
};

const IGNORE_SELECT_TABS = ['ARTISTS'];

class ExperiencesFilterConfig extends React.Component {
	constructor(props) {
		super(props);
		this.onSelect = this.onSelect.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onSortEnd = this.onSortEnd.bind(this);
		this.onToolClick = this.onToolClick.bind(this);

		this.state = {
			savedFilterConfig: {},
			editableFilterPropertiesData: {},
			instructors: [],
			searchValue: '',
			selectedTab: TOOLBAR_TABS.TAGS
		};
	}

	// #region events
	componentDidMount() {
		this.load();
	}

	async onSelect(selectedChip) {
		let {editableFilterPropertiesData, selectedTab} = this.state;

		if (IGNORE_SELECT_TABS.includes(selectedTab)) {return;}

		let key = selectedTab.toLowerCase();
		let {srcList, trgList} = editableFilterPropertiesData[key];
		let index = srcList.findIndex(chip => chip.values[0] === selectedChip.values[0]);

		// onSelect "remove" (hide) at source and add to trg
		srcList[index].show = false;
		trgList.push(srcList[index]);

		this.setState({ editableFilterPropertiesData });
	}

	async onDelete(chipToDelete) {
		let {editableFilterPropertiesData, selectedTab} = this.state;
		let key = selectedTab.toLowerCase();
		let {srcList, trgList} = editableFilterPropertiesData[key];

		// remove from trg list
		editableFilterPropertiesData[key].trgList = trgList.filter((chip) => chip.values[0] !== chipToDelete.values[0]);

		// show at src
		let index = srcList.findIndex(chip => chip.values[0] === chipToDelete.values[0]);
		if (index > -1) {
			srcList[index].show = true;
		}

		this.setState({ editableFilterPropertiesData });
	}

	onSortEnd(oldIdx, newIdx) {
		let {editableFilterPropertiesData, selectedTab} = this.state;
		let key = selectedTab.toLowerCase();
		let {trgList} = editableFilterPropertiesData[key];

		editableFilterPropertiesData[key].trgList = arrayMoveImmutable(trgList, oldIdx, newIdx);
		this.setState({ editableFilterPropertiesData });
	}

	async onSave() {
		let {id, editableFilterPropertiesData} = this.state;

		if (!id) { return; }

		let params = {
			id
		};

		for (let key in editableFilterPropertiesData) {
			let trgList = editableFilterPropertiesData[key].trgList;
			params[key.toLowerCase()] = trgList.map(item => {return {...item};});
			params[key.toLowerCase()].forEach(item => {
				delete item.count;
			});

		}

		var mb = messageBox('Updating experiences filter config...', null, null, true);

		try {
			await api.experience.updateExperiencesFilterConfig(params).send();
			mb.close();
			this.load();
		} catch (ex) {
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}
	}

	async onTabClick(tab) {
		let newState = await this.loadSelectedSectionConfig(tab);
		Object.assign(newState, { selectedTab: tab, searchValue: '' });

		this.setState(newState);
	}

	async onToolClick(toolbar, oper) {
		switch (oper) {
			case 'Save':
				this.onSave();
				break;
		}
	}

	// #endregion events

	// #region private methods

	loadData(selected = [], allItems) {
		let itemsMap = {};
		let srcList = [];

		// get all selected items labels in one array
		let flatSelectedList = selected.flatMap(item => item.values);

		// create src list from all data and also map of selected items
		// for better performance at creating trg list
		allItems.forEach(item => {
			let obj = item;
			if (!obj.values) {
				obj.values = [obj.name];
			}

			let show = flatSelectedList.includes(item.values[0]);
			obj.show = !show;

			srcList.push(obj);

			if (show) {
				itemsMap[item.values[0]] = item;
			}
		});

		// create trg list at right order
		let trgList = selected;
		trgList.forEach(item => item.count = itemsMap[item.values[0]]?.count ?? 0);

		return {srcList, trgList};
	}

	async reformatInstructorData(allItems) {
		let {instructors} = this.state;
		let state = {};
		if (instructors.length === 0) {
			let res = await api.experience.getInstructors().send();
			instructors = res.instructors;
			state.instructors = instructors;
		}

		// we should merge instructor data with metadata like count
		// (count api return instructor id as name)
		for (let idx = 0; idx < allItems.length; idx++) {
			let instructor = instructors.find(item => item.id === allItems[idx].name);
			if (!instructor) {
				continue;
			}

			let item = {...instructor};
			item.count = allItems[idx].count;
			item.values = [instructor.id];
			delete item.email;
			delete item.id;
			allItems[idx] = item;
		}

		return {allItems, state};
	}

	async loadSelectedSectionConfig(tab, refresh = false, overwriteFilterConfig = {}) {
		let {savedFilterConfig, editableFilterPropertiesData} = this.state;
		let key = tab.toLowerCase();
		let state = {};

		// on refresh there is no savedFilterConfig in state yet so should get it as parameter
		// from the fetch api
		if (refresh) {
			editableFilterPropertiesData = {};
			savedFilterConfig = overwriteFilterConfig;
		}

		// if property tab data haven't load yet we should load it
		if (!editableFilterPropertiesData[key]) {
			let allItems = [];
			if (tab !== TOOLBAR_TABS.POPULAR) {
				// get used count for each item (not for POPULAR tab items)
				allItems = await api.experience.getExperiencesFilterConfigMetaData({configName: key}).send();
			}

			switch (tab) {
				case TOOLBAR_TABS.INSTRUCTOR:
					let res = await this.reformatInstructorData(allItems);
					allItems = res.allItems;
					Object.assign(state, res.state);
					break;
				default:
					break;
			}

			editableFilterPropertiesData[key] = this.loadData(savedFilterConfig[key], allItems);
			state.editableFilterPropertiesData = editableFilterPropertiesData;
		}

		return state;
	}

	async load() {
		try {
			let {selectedTab} = this.state;
			let savedFilterConfig = await api.experience.getExperiencesFilterConfig().send();
			if (!savedFilterConfig?.experienceFilterConfig?.id) {
				logger.error('Invalid experiences filter config.');
				return;
			}

			savedFilterConfig = savedFilterConfig.experienceFilterConfig;
			let id = savedFilterConfig.id;
			let newState = await this.loadSelectedSectionConfig(selectedTab, true, savedFilterConfig);
			Object.assign(newState, {savedFilterConfig, id});

			this.setState(newState);
		} catch (ex) {
			logger.error('Error loading experiences filter config. exception:', ex);
		}
	}

	renderToolbar() {
		let {selectedTab} = this.state;

		return (
			<Toolbar onAction={this.onToolClick}>
				{Object.keys(TOOLBAR_TABS).map(tab =>
					<Chip key={tab} label={tab} color="primary" variant={tab === selectedTab ? 'filled' : 'outlined'} onClick={() => this.onTabClick(tab)} />)}
				<ToolbarIcon oper="Save" right size="medium" name="save">Save</ToolbarIcon>
			</Toolbar>
		);
	}

	renderSearchField(value) {
		return (
			<TextField
				id="search"
				label="Search field"
				type="search"
				variant="standard"
				value={value}
				size="small"
				onChange={event => this.setState({searchValue: event.target.value})}
			/>
		);
	}

	renderPopularSearchField(value) {
		return (
			<TextField
				id="addPopularSearch"
				label="Popular search text"
				type="search"
				variant="standard"
				value={value}
				size="small"
				onKeyDown={this.onKeyDown.bind(this)}
				onChange={event => this.setState({searchValue: event.target.value})}
			/>
		);
	}

	renderSrcListElm(tab, srcList, searchValue) {
		if (tab === TOOLBAR_TABS.POPULAR) { return '';}

		return (
			<div className='srcList'>
				{srcList
					?.filter(chip => chip.show && chip.name.toLowerCase().startsWith(searchValue.toLowerCase()))
					?.map(chip => (
						<div key={chip.name} className="srcItem">
							<Chip clickable= {true} onClick={ () => this.onSelect({name: chip.name, count: chip.count, values: chip.values}) } variant="outlined" label= {`${chip.name} (${chip.count})`}/>
						</div>
					))}
			</div>
		);
	}

	onKeyDown(event) {
		let {searchValue} = this.state;

		// if ENTER and not empty string then should check if need to add
		// the value to the trgList
		if (event.keyCode === 13 && searchValue.trim().length > 0) {
			let {editableFilterPropertiesData, selectedTab} = this.state;

			let key = selectedTab.toLowerCase();
			let {trgList} = editableFilterPropertiesData[key];

			// if value is already exists then just return
			if (!(trgList.find(item => item.name === searchValue))) {
				trgList.push({
					name: searchValue,
					values: [searchValue],
					count: 0
				});
			}


			this.setState({ editableFilterPropertiesData, searchValue: '' });
		}
	}

	// #endregion private methods

	render() {
		let {editableFilterPropertiesData, searchValue, selectedTab} = this.state;
		let key = selectedTab.toLowerCase();
		let srcList = editableFilterPropertiesData[key]?.srcList;
		let trgList = editableFilterPropertiesData[key]?.trgList;

		let isPopularTab = selectedTab === TOOLBAR_TABS.POPULAR;
		let srcListElm = this.renderSrcListElm(selectedTab, srcList, searchValue);
		let textField = isPopularTab ? this.renderPopularSearchField(searchValue) : this.renderSearchField(searchValue);

		return (
			<BasePage title="Experience Filter Config Editor" toolbar={this.renderToolbar()} toolbarOpen={true}>
				<div className="exp-filter-config">
					<div className="exp-tags-src">
						<div className='title'>
							<span>{selectedTab}</span>
						</div>
						{textField}
						{srcListElm}
					</div>

					<div className="exp-tags-trg">
						<div className='title'>
							<span>SELECTED</span>
						</div>
						<SortableList
							onSortEnd={this.onSortEnd}
							className="trgList"
							allowDrag={true}
						>
							{trgList?.map(chip => (
								<SortableItem key={chip.name}>
									<div className="trgItem">
										<Chip label= {isPopularTab ? `${chip.name}` : `${chip.name} (${chip.count})`} onDelete={() => this.onDelete({name: chip.name, count: chip.count, values: chip.values})}/>
									</div>
								</SortableItem>
							))}
						</SortableList>
					</div>
				</div>
			</BasePage>
		);
	}
}

export default ExperiencesFilterConfig;