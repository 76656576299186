import {GridFormatters} from 'ui-core';

var GridHelper = {
	rowHeight: 70
};

GridHelper.getColumns = function getColumns() {
	return [
		{
			key: 'thumbnail',
			width: 100,
			formatter: GridFormatters.image({maxHeight: GridHelper.rowHeight})
		},
		{
			key: 'id@250',
			formatter: GridFormatters.copyToClipboard(),
		},
		{
			key: 'name',
		},
	];
};

export default GridHelper;
