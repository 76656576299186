.image-page {
	display: flex;
	box-sizing: border-box;
	flex-direction: column;
}

.image-page .details-section {
	width: 100%;
	display: flex;
}

.image-page .details-form {
	flex: 1;
}

.image-page .artwork-file {
	flex: 2;
	overflow: auto;
	height: 470px;
	margin-left: 20px;
	margin-top: 15px;
	padding-bottom: 15px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
}
