.media-asset-modal {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.media-asset-modal .content {
	flex: 1;
	overflow: auto;
}
