// Credit: https://github.com/sindresorhus/array-move

export function arrayMoveMutable(array, fromIndex, toIndex) {
	const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

	if (startIndex >= 0 && startIndex < array.length) {
		const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

		const [item] = array.splice(fromIndex, 1);
		array.splice(endIndex, 0, item);
	}
}

export function arrayMoveImmutable(array, fromIndex, toIndex) {
	array = [...array];
	arrayMoveMutable(array, fromIndex, toIndex);
	return array;
}

// https://github.com/jacobbuck/immutable-splice/blob/master/src/index.js
export function spliceImmutable(input, start, deleteCount = input.length - start, ...items) {
	return input.slice(0, start).concat(...items, input.slice(start + deleteCount));
}
