import React from 'react';
import PropTypes from 'prop-types';
import {Toolbar, ToolbarIcon, ColorPicker, List, ListItem} from 'ui-core';
import { Button, Switch, Popover, Divider, ListSubheader } from '@mui/material';

import './toolbar.css';

var Actions = {
	GridColor: 'GridColor',
	GridWidth: 'GridWidth',
	SelectionBGColor: 'SelectionBGColor',
	Center: 'Center',
	ShowPaletteAlt: 'ShowPaletteAlt',
	ToggleBGFill: 'ToggleBGFill',
	Undo: 'Undo',
	Redo: 'Redo',
	BlendingPage: 'BlendingPage',
	DownloadSVG: 'DownloadSVG',
	DownloadPNG: 'DownloadPNG',
	Audit: 'Audit',
	Open: 'Open',
	ReloadPalette: 'ReloadPalette'
};

var ListItemStyle = {
	cursor: 'default'
};

class ImageEditorToolbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showGridOpts: false,
			showColorPicker: false,

			// options
			showGrid: false,
			gridWidth: 1,
			gridColor: '#000000',
			selectionBGColor: '#FFFFFF'
		};

		this.onToolbarClick = this.onToolbarClick.bind(this);
		this.onShowGrid = this.onShowGrid.bind(this);
		this.onGridWidth = this.onGridWidth.bind(this);
		this.onGridColor = this.onGridColor.bind(this);
		this.onColorPickerClose = this.onColorPickerClose.bind(this);
		this.onBGColor = this.onBGColor.bind(this);
	}

	toggleSettings(open) {
		this.setState({showGridOpts: open});
	}

	onColorPickerClose() {
		this.setState({showGridOpts: false});
	}

	onToolbarClick(toolbar, oper) {
		switch (oper) {
			case 'Settings':
				this.toggleSettings(true);
				break;
			default:
				if (Actions[oper]) {
					this.setState({showGridOpts: false}, () => this.props.onAction(Actions[oper]));
				}
		}
	}

	onShowGrid() {
		var showGrid = !this.state.showGrid;
		this.setState({showGrid}, () => { this.props.onAction(Actions.GridColor, this.state.showGrid ? this.state.gridColor : 'transparent'); });
	}

	onGridWidth(ev) {
		this.setState({gridWidth: parseInt(ev.target.value)}, () => { this.props.onAction(Actions.GridWidth, this.state.gridWidth); });
	}

	onGridColor(hex) {
		this.setState({gridColor: hex}, () => { this.props.onAction(Actions.GridColor, this.state.gridColor); });
	}

	onBGColor(hex) {
		this.setState({selectionBGColor: hex}, () => { this.props.onAction(Actions.SelectionBGColor, this.state.selectionBGColor); });
	}

	render() {
		var gridIconElem = document.querySelector('.image-editor-toolbar i[data-oper=Settings]');
		var {showGridOpts, showGrid, gridWidth, gridColor, selectionBGColor} = this.state;
		var {hasSelection, isolateSelection, hasHistory, hasRedo } = this.props;
		return (
			<div>
				<Toolbar className="image-editor-toolbar" primary shadow onAction={this.onToolbarClick}>
					<ToolbarIcon name="note_add" title="Open..." oper={Actions.Open}/>
					<ToolbarIcon name="my_location" title="Center" oper={Actions.Center}/>
					<ToolbarIcon name="palette" title="Adjust colors to palette" oper={Actions.ShowPaletteAlt}/>
					{hasSelection ? <ToolbarIcon name={isolateSelection ? 'brightness_high' : 'brightness_low'} title="Toggle background color" oper={Actions.ToggleBGFill}/> : null}
					{hasHistory ? <ToolbarIcon name="undo" title="Undo" oper={Actions.Undo}/> : null}
					{hasRedo ? <ToolbarIcon name="redo" title="Redo" oper={Actions.Redo}/> : null}
					<ToolbarIcon name="assignment" title="Open Blending Page" oper={Actions.BlendingPage}/>
					<ToolbarIcon name="file_download" className="tool-text svg" title="Download SVG" oper={Actions.DownloadSVG}/>
					<ToolbarIcon name="file_download" className="tool-text png" title="Download PNG" oper={Actions.DownloadPNG}/>
					{/* Right aligned items */}
					{/* <ToolbarIcon right name="spellcheck" title="Audit" oper={Actions.Audit}/> */}
					<ToolbarIcon right name="settings" title="Settings" oper="Settings"/>
				</Toolbar>

				<Popover className="opts-wrap" open={showGridOpts} anchorEl={gridIconElem} transformOrigin={{ horizontal: 'right', vertical: 'top' }} onClose={() => this.toggleSettings(false)}>
					<List>
						<ListSubheader>Grid Options</ListSubheader>
						<ListItem style={ListItemStyle} primaryText="Show Grid" secondaryAction={<Switch checked={showGrid} onChange={this.onShowGrid} />} />
						<ListItem style={ListItemStyle} primaryText="Width" secondaryAction={<input type="number" className="text-num" min="1" max="5" value={gridWidth} onChange={this.onGridWidth} />} />
						<ListItem style={ListItemStyle} primaryText="Color" secondaryAction={<span><ColorPicker openToLeft color={gridColor} onChange={this.onGridColor} /></span>} />
					</List>
					<Divider />
					<List>
						<ListSubheader>Canvas Options</ListSubheader>
						<ListItem style={ListItemStyle} primaryText="BG Color" secondaryAction={<span><ColorPicker openToLeft color={selectionBGColor} onChange={this.onBGColor} /></span>} />
					</List>
					<Divider />
					<List>
						<ListSubheader>Misc</ListSubheader>
						<ListItem style={ListItemStyle} primaryText={<Button variant="contained" onClick={() => this.onToolbarClick(null, Actions.ReloadPalette)}>Reload Palette</Button>} />
					</List>
				</Popover>
			</div>
		);
	}
}

ImageEditorToolbar.Actions = Actions;

ImageEditorToolbar.propTypes = {
	hasSelection: PropTypes.bool,
	isolateSelection: PropTypes.bool,
	hasHistory: PropTypes.bool,
	hasRedo: PropTypes.bool,
	onAction: PropTypes.func
};

ImageEditorToolbar.defaultProps = {
	hasSelection: false,
	isolateSelection: true,
	hasHistory: false,
	hasRedo: false,
	onAction: function nop() {}
};

export default ImageEditorToolbar;
