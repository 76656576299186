import React from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { messageBox, InfiniteScroll, AddButtonFAB } from 'ui-core';
import { BasePage } from 'app-center-common';
import Gallery from './gallery-grid.jsx';
import {utils, api, logger, config} from 'client-services';
import { spliceImmutable } from '../../../lib/immutable-utils.js';

import './gallery-grid.css';

var FETCH_IMG_COUNT = 50;
var IMG_FIELDS = ['name', 'lod', 'artwork', 'photo', 'thumbnail', 'layers', 'groups', 'tags', 'artist', 'width', 'height'];
let SEARCH_SCHEMA = {
	name: {},
	artist: {},
	tags: {},
	groups: {},
	id: { $ref: '#/definitions/MongoObjectID' },
};

class GalleryGridController extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			images: [],
			search: this.props.searchParams.get('q') || '',
			hasMore: true,
			showPoster: true,
			showEditModal: false,
			selectedImage: null,
			total: 0
		};

		this.page = 0;

		this.openEditModal = this.openEditModal.bind(this);
		this.loadMore = this.loadMore.bind(this);
		this.onImageAction = this.onImageAction.bind(this);
		this.onImageClick = this.onImageClick.bind(this);
		this.onImageUpdate = this.onImageUpdate.bind(this);
		this.searchChanged = this.searchChanged.bind(this);
		this.deleteDerivedImages = this.deleteDerivedImages.bind(this);
	}

	componentDidMount() {
		this.loadMore();
	}

	searchChanged(search) {
		this.setState({ search, hasMore: true, images: [] }, () => {
			this.page = 0;
			this.loadMore();
		});
	}

	openEditModal() {
		if (!this.state.showEditModal) {
			this.setState({showEditModal: true});
		}
	}

	async deleteDerivedImages() {
		var num1 = utils.randomInt(100);
		var num2 = utils.randomInt(100);
		var answer = num1 + num2;
		var res = await messageBox.prompt(<div>Are you sure you want to delete all derived resources?<br />Answer: {num1} + {num2} = ?</div>, 'Answer', [messageBox.Buttons.Cancel, messageBox.Buttons.Yes]);
		if (res.result === messageBox.Buttons.Cancel) {return;}
		if (parseFloat(res.values['Answer']) !== answer) {
			messageBox('You look tired', 'Maybe it\'s not a good idea you delete all derived resources now...');
			return;
		}

		var mb = messageBox('Deleting derived images...', '', null, true);
		try {
			res = await api.gallery.deleteDerivedImages().send();
			mb.setProgress(false);
			mb.setTitle('Success');
			mb.setBody(`Deleted ${res.total} resources. It will take up to an hour to invalidate the CDN.`);
		} catch (e) {
			mb.setProgress(false);
			mb.setTitle('Error');
			mb.setBody(e.message);
		}
	}

	async loadMore() {
		var req = api.gallery.getImages({count: FETCH_IMG_COUNT, skip: this.page * FETCH_IMG_COUNT, q: this.state.search, fields: IMG_FIELDS});

		try {
			var data = await req.send();
			let total = data.total >= 0 ? data.total : this.state.total;
			++this.page;
			var hasMore = (data.images.length === FETCH_IMG_COUNT);
			this.setState({hasMore, total, images: [...this.state.images, ...data.images]});
		} catch (ex) {
			logger.error('Error loading images', ex);
		}
	}

	onImageClick(idx) {
		if (this.state.images[idx]?.id) {
			window.open(`/gallery/image/${this.state.images[idx].id}`);
		}
	}

	onImageAction(oper, id) {
		var image = this.state.images.find(i => i.id === id);
		if (!image) {
			logger.error('Got %s operation on image `%s` which was not found in the images list', oper, id);
			return;
		}

		switch (oper) {
			case 'Download':
				this.downloadImage(image, false);
				break;
			case 'DownloadAll':
				this.downloadImage(image, true);
				break;
			case 'Edit':
				this.editImage(image);
				break;
			case 'Delete':
				this.deleteImage(image);
				break;
			default:
				logger.error('Got invalid image tool operation:', oper);
		}
	};

	// TODO - should download all image files
	downloadImage(image, allFiles) {
		var name = image.name || image.id;
		if (image.artwork) {
			this.downloadUrl(image.artwork, `artwork_${name}`);
		}

		if (!allFiles) {return;}
		if (image.photo) {
			this.downloadUrl(image.photo, `photo_${name}`);
		}

		if (image.layers) {
			image.layers.forEach((layer,i) => {
				let layerName = layer.name !== '' ? `layer${i}_ ${layer.name}` : `layer${i}`;
				this.downloadUrl(layer.url, layerName);
			});
		}
	}

	downloadUrl(url, name) {
		var request = new XMLHttpRequest();
		request.open('GET', url, true);
		request.responseType = 'blob';
		request.onload = function _() {
			utils.downloadBlob(request.response, name);
		};

		request.send();
	}

	editImage(image) {
		var url = `/gallery/image/${image.id}`;
		utils.openWindow(url);
	}

	deleteImage(image) {
		messageBox('Are you sure?', '', [messageBox.Buttons.Cancel, 'Delete']).promise.then(result => {
			if (result === 'Cancel') {
				logger.trace('User bailed on delete image:', image.id);
				return;
			}

			logger.trace('Got confirmation to delete image:', image.id);
			api.gallery.deleteImage({id: image.id}).send((err, res) => {
				if (err) {
					logger.error('Got error on delete image `%s`, error:', image.id, err);
					messageBox('Error', err.message, messageBox.Buttons.OK);
					return;
				}

				logger.trace('Got delete response for image `%s`, res:', image.id, res);
				var idx = this.state.images.findIndex(img => img.id === image.id);
				if (idx >= 0) {
					this.setState({images: spliceImmutable(this.state.images, idx, 1)});
				}
			});
		});
	}

	onImageUpdate(newImage) {
		var cmd = { showEditModal: false, selectedImage: null };
		if (newImage) {
			logger.trace('Image updated, newImage:', newImage);

			var idx = this.state.images.findIndex(img => img.id === newImage.id);
			if (idx >= 0) {
				cmd.images = spliceImmutable(this.state.images, idx, 1, newImage);
			} else {
				cmd.images = [newImage, ...this.state.images];
			}
		}

		// Close the modal
		this.setState(cmd);
	}

	render() {
		return (
			<BasePage
				className='gallery-grid'
				title="Gallery"
				subTitle={`(${this.state.total} images)`}
				showSearch={true}
				search={this.state.search}
				searchFields={SEARCH_SCHEMA}
				onSearch={ this.searchChanged }
			>
				<InfiniteScroll scrollOn=".gallery-grid" hasMore={this.state.hasMore} loadMore={this.loadMore}>
					<Gallery
						images={this.state.images}
						showPoster={this.state.showPoster}
						onAction={this.onImageAction}
						onClick={this.onImageClick}
					/>
					<AddButtonFAB href="/gallery/image/new" />
				</InfiniteScroll>
			</BasePage>
		);
	}
}

GalleryGridController.propTypes = {
	searchParams: PropTypes.object
};

export default function WrappedGalleryGridController(props) {
	let [searchParams] = useSearchParams();
	return <GalleryGridController searchParams={searchParams} {...props} />;
};
