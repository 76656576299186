import CONSTANTS from '../../lib/constants.js';
import { compileSchema, toEnumSchema } from 'ui-core';

let toEnum = toEnumSchema;

/** @type {import("@rjsf/utils").RJSFSchema} */
var schema = {
	title: 'Feed Item',
	type: 'object',
	required: ['type', 'name'],
	properties: {
		id: {
			type: 'string'
		},
		name: {
			type: 'string'
		},
		type: toEnum(CONSTANTS.FeedItemType, CONSTANTS.FeedItemType.ExperienceFeed),
		status: {
			type: 'number',
			oneOf: [
				{ enum: [0], title: 'Draft' },
				{ enum: [1], title: 'Public' }
			],
			default: 0
		},
		allowDismiss: {type: 'boolean', default: false},
		uiElement: {
			$ref: '#/definitions/uiElement'
		},
	},
	dependencies: {
		type: {
			oneOf: [
				{
					required: ['feed'],
					properties: {
						type: {
							enum: [0] // Feed
						},
						feed: {
							$ref: '#/definitions/MongoObjectID'
						}
					}
				},
				{
					required: ['experienceFeed'],
					properties: {
						type: {
							enum: [1] // ExperienceFeed
						},
						experienceFeed: {
							$ref: '#/definitions/MongoObjectID'
						}
					}
				},
				{
					required: ['experience'],
					properties: {
						type: {
							enum: [2] // Experience
						},
						experience: {
							$ref: '#/definitions/MongoObjectID'
						}
					}
				},
				{
					properties: {
						type: {
							enum: [3] // Banner
						},
						bannerUrl: { $ref: '#/definitions/uri' }
					}
				},
				{
					required: ['videoUrl'],
					properties: {
						type: {
							enum: [4] // Video
						},
						videoUrl: { $ref: '#/definitions/uri' }
					}
				},
				{
					required: ['screenName'],
					properties: {
						type: {
							enum: [6] // Screen
						},
						screenName: toEnum(CONSTANTS.FeedItemScreenName, CONSTANTS.FeedItemScreenName.Discount),
					}
				}
			]
		}
	},
	definitions: {
		uiElement: {
			type: 'object',
			required: ['type'],
			properties: {
				type: {
					type: 'string',
					enum: ['None', 'FullCardImage', 'FullCardVideo', 'Strip', 'Slideshow', 'FeaturedCard'],
					default: 'FullCardImage'
				},
				aspectRatio: {
					type: 'number',
					default: 2.14,
					exclusiveMinimum: 0,
					description: 'The aspect ratio of the ui elements'
				}
			},
			dependencies: {
				type: {
					oneOf: [
						{
							properties: {
								type: {
									enum: ['None']
								}
							}
						},
						{
							required: ['params'],
							properties: {
								type: {
									enum: ['FullCardImage']
								},
								params: {
									title: '',
									type: 'object',
									required: ['url'],
									properties: {
										url: {
											$ref: '#/definitions/uri'
										}
									}
								}
							}
						},
						{
							required: ['params'],
							properties: {
								type: {
									enum: ['FullCardVideo']
								},
								params: {
									title: '',
									type: 'object',
									required: ['url'],
									properties: {
										url: {
											$ref: '#/definitions/videoUrl'
										},
										poster: {
											$ref: '#/definitions/uri'
										}
									}
								}
							}
						},
						{
							properties: {
								type: {
									enum: ['Strip']
								}
							}
						},
						{
							required: ['params'],
							properties: {
								type: {
									enum: ['Slideshow']
								},
								params: {
									title: '',
									type: 'object',
									properties: {
										autoSlide: {type: 'boolean', default: true, title: 'Auto Slide'}
									}
								}
							}
						},
						{
							required: ['params'],
							properties: {
								type: {
									enum: ['FeaturedCard']
								},
								params: {
									title: '',
									type: 'object',
									properties: {
										seed: {
											type: 'integer',
											minimum: 0,
											default: 0
										}
									}
								}
							}
						},
					]
				}
			}
		}
	}
};

schema = compileSchema(schema);

export default schema;