import React from 'react';
import PropTypes from 'prop-types';
import { messageBox, Modal, Toolbar, InfiniteScroll, SearchBox } from 'ui-core';
import { SearchFormPop } from 'app-center-common';
import EntityGallery from './entity-gallery.jsx';
import EntityGrid from './entity-grid.jsx';
import { Button, FormControlLabel, Checkbox, TextField } from '@mui/material';
import { arrayMoveImmutable } from '../../lib/immutable-utils.js';
import clsx from 'clsx';

import './entity-picker.css';

var FETCH_IMG_COUNT = 50;

class EntityPicker extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			initialized: false,
			search: '',
			filter: '',
			openPickerOnSelected: props.openPickerOnSelected,
			images: null,
			selected: [],
			selectedOrig: [], // to rollback on cancel
			hasMore: true
		};

		this.page = 0;

		this.loadMore = this.loadMore.bind(this);
		this.searchChanged = this.searchChanged.bind(this);
		this.filterChanged = this.filterChanged.bind(this);
		this.onKeyUp = this.onKeyUp.bind(this);
		this.showSelectedToggle = this.showSelectedToggle.bind(this);
		this.onImageSelected = this.onImageSelected.bind(this);
		this.onSortEnd = this.onSortEnd.bind(this);
	}

	// #region Methods

	async init() {
		this.setState({ initialized: true }, async() => {
			await this.loadPreSelected();
			// todo: When showPreselectedPreview is true this might be redundant as we don't
			// need yet the entire entity gallery, see if we can defer it to only when showing
			// the modal
			this.loadMore(false);
		});
	}

	async loadPreSelected() {
		// props.preSelected should be ids
		var preselectIds = this.props.preSelected.filter(p => typeof p === 'string' && p);
		var selected = [];

		// Fetch all ids in batches
		var page = 0;
		var count = FETCH_IMG_COUNT;
		var idsToLoad = preselectIds.slice(page, count * (page + 1));
		while (idsToLoad.length > 0) {
			try {
				var images = await this.props.loadEntitiesByIds(idsToLoad);
				images.forEach(i => { i.isSelected = true; });
				selected = selected.concat(images);
			} catch (ex) {
				messageBox('Error pre-loading selected entities', ex.message);
				return;
			}

			++page;
			idsToLoad = preselectIds.slice(count * page, count * (page + 1));
		}

		// Finally we have to make sure that order of "selected" is like preSelected
		selected = selected.sort((i1, i2) => {
			var idx1 = preselectIds.indexOf(i1.id);
			var idx2 = preselectIds.indexOf(i2.id);
			return idx1 - idx2;
		});

		this.setState({ selected, selectedOrig: [...selected] });
	}

	async doLoadMore(search) {
		var count = FETCH_IMG_COUNT;
		var skip = this.page * FETCH_IMG_COUNT;
		try {
			var images = await this.props.loadEntities(count, skip, search);
			images.map(i => {
				i.isSelected = this.state.selected.some(s => s.id === i.id);
			});
		} catch (ex) {
			messageBox('Error loading entities', ex.message);
			return;
		}

		var hasMore = (images.length === FETCH_IMG_COUNT);
		++this.page;
		this.setState({ hasMore, images: [...(this.state.images || []), ...images] });
	}

	// #endregion Methods

	// #region EventHandlers

	loadMore(searchChanged) {
		var { search } = this.state;

		// If search changed we have to first reset images and re-render picker with no images to reset scroller
		if (searchChanged) {
			this.page = 0;
			this.setState({ images: [], hasMore: true }, () => this.doLoadMore(search));
		} else {
			this.doLoadMore(search);
		}
	}

	searchChanged(search) {
		this.setState({ search }, () => {
			this.loadMore(true);
		});
	}

	filterChanged(e) {
		e.stopPropagation();
		e.preventDefault();
		let filter = e.target.value;
		this.setState({ filter });
	}

	onKeyUp(e) {
		if (!this.props.showFilter) { return; }
		let key = e.key.toLowerCase();
		let filter = this.state.filter;
		if (key === 'backspace') {
			filter = filter.substring(0, filter.length - 1);
		} else if (/[a-z0-9A-Z ]/.test(key)) {
			filter += key;
		}

		this.setState({ filter });
	}

	showSelectedToggle() {
		this.setState({ openPickerOnSelected: !this.state.openPickerOnSelected });
	}

	onImageSelected(id, isSelected) {
		if (!id) { return; }

		// Since "images" and "selected" are 2 different lists the toggled image can be
		// either in the "images" or "selected" (or both) lists (depends on what's currently displayed).
		var images = this.state.images;
		var image = images.find(img => img.id === id);
		if (!image) {
			image = this.state.selected.find(img => img.id === id);
			if (!image) { return; }
		}

		image.isSelected = isSelected;
		if (image.isSelected) {
			selected = this.props.multiSelect ? [image, ...this.state.selected] : [image];
		} else {
			var selected = this.props.multiSelect ? this.state.selected.filter(i => i.id !== image.id) : [];
		}
		this.state.images.forEach(i => { i.isSelected = selected.some(s => s.id === i.id); });
		this.setState({ selected });
	}

	onSortEnd(oldIdx, newIdx) {
		if (oldIdx === newIdx) { return; }
		var selected = arrayMoveImmutable(this.state.selected, oldIdx, newIdx);
		this.setState({ selected }, () => {
			if (this.props.showPreSelectedPreview && !this.props.showPicker) {
				this.commit(true); // let the user know about this change
			}
		});
	}

	commit(commit) {
		this.props.onCommit(commit ? this.state.selected : null);

		// In picker mode we should clear all the "selected" flags
		// (if it's not picker only we do selection and ordering, so we keep the state as-is since we don't
		// expect anyone to change anything from outside of this component. In practice we would reset the
		// state every time but we want to avoid re-loading the pre-selected if the user open/close/open this picker).
		var selected;
		if (this.props.pickerOnly) {
			selected = [];
		} else if (commit) {
			selected = this.state.selected;
		} else {
			selected = this.state.selectedOrig; // rollback
		}

		this.state.images.forEach(i => { i.isSelected = selected.some(s => s.id === i.id); });
		this.setState({ selected, selectedOrig: selected });
	}

	// #endregion EventHandlers

	// #region Component Lifecycle

	componentDidMount() {
		if (this.props.showPicker || this.props.showPreSelectedPreview) {
			this.init();
		}
	}

	componentDidUpdate() {
		if ((this.props.showPicker || this.props.showPreSelectedPreview) && !this.state.initialized) {
			this.init();
		}
	}

	// #endregion Component Lifecycle

	// #region Render

	renderToolbar() {
		let { pickerOnly, searchSchema } = this.props;
		let { openPickerOnSelected, selected, search } = this.state;
		return (
			<Toolbar primary shadow className="toolbar">
				{!pickerOnly && <FormControlLabel
					control={<Checkbox edge="start" size="small" checked={openPickerOnSelected} onChange={this.showSelectedToggle} />}
					label={`Show Selected (${selected.length})`}
					style={{ marginLeft: 0 }}
				/>}
				<div className={clsx('search-tools', { hidden: openPickerOnSelected })}>
					{searchSchema && <SearchFormPop search={search} searchChanged={this.searchChanged} fields={searchSchema} /> }
					{!searchSchema && <SearchBox search={search} searchChanged={this.searchChanged} />}

					{/* <SearchBox search={search} searchChanged={this.searchChanged} /> */}
				</div>
			</Toolbar>
		);
	}

	renderModalGallery() {
		let Comp = this.props.mode === 'Gallery' ? EntityGallery : EntityGrid;
		let { openPickerOnSelected, filter, selected, images } = this.state;
		filter = filter.toLowerCase();
		images = openPickerOnSelected ?
			selected : images.filter(i => i.title.toLowerCase().includes(filter));
		return <Comp
			images={images}
			allowDrag={this.state.openPickerOnSelected}
			onSelectedToggle={this.onImageSelected}
			onSortEnd={this.onSortEnd}
		/>;
	}

	render() {
		if (!this.props.showPicker && !this.props.showPreSelectedPreview) {
			return null;
		}

		if (!this.props.showPicker && this.props.showPreSelectedPreview) {
			if (this.props.preSelected.length < 1) { return 'None Selected'; }
			if (this.state.selected.length < 1) { return 'Loading...';}
			let Comp = this.props.mode === 'Gallery' ? EntityGallery : EntityGrid;
			return (
				<Comp
					images={this.state.selected}
					allowDrag={true}
					allowSelect={false}
					compact={this.props.preselectedPreviewCompactView}
					onSortEnd={this.onSortEnd}
				/>
			);
		}

		let keyUpProps = this.props.showFilter ? {onKeyUp: this.onKeyUp, tabIndex: -1} : {};
		return (
			<Modal open fullscreen className="entity-picker" showCloseButton={false}>
				{this.renderToolbar()}
				<div className="content" {...keyUpProps}>
					{ this.props.showFilter && <TextField className="filter" autoFocus value={this.state.filter} placeholder="Filter" variant="standard" onChange={this.filterChanged} onKeyUp={e => e.stopPropagation()} />}
					<InfiniteScroll className="scroller" scrollOn=".entity-picker .scroller" hasMore={this.state.hasMore} loadMore={this.loadMore}>
						{this.state.images && this.renderModalGallery()}
						{!this.state.images && <div>Loading...</div>}
					</InfiniteScroll>
				</div>
				<div className="footer">
					<Button variant="contained" color="secondary" onClick={() => this.commit()}>Cancel</Button>
					<Button variant="contained" color="primary" onClick={() => this.commit(true)}>OK</Button>
				</div>
			</Modal>
		);
	}

	// #endregion Render
}

// const IMAGE_PROPS = PropTypes.arrayOf(PropTypes.shape({
// 	id: PropTypes.string,
// 	src: PropTypes.string,
// 	isSelected: PropTypes.bool,
// 	title: PropTypes.string,
// }));

EntityPicker.propTypes = {
	mode: PropTypes.oneOf(['Gallery', 'Grid']),
	showPicker: PropTypes.bool,
	openPickerOnSelected: PropTypes.bool,
	pickerOnly: PropTypes.bool,
	multiSelect: PropTypes.bool,
	showPreSelectedPreview: PropTypes.bool,
	preselectedPreviewCompactView: PropTypes.bool,
	preSelected: PropTypes.arrayOf(PropTypes.string),
	showFilter: PropTypes.bool,
	searchSchema: PropTypes.object,
	loadEntitiesByIds: PropTypes.func.isRequired,
	loadEntities: PropTypes.func.isRequired,
	onCommit: PropTypes.func,
};

function nop() { }
EntityPicker.defaultProps = {
	mode: 'Gallery',
	openPickerOnSelected: true,
	pickerOnly: false,
	multiSelect: true,
	showPreSelectedPreview: false,
	preselectedPreviewCompactView: false,
	preSelected: [],
	showFilter: false,
	onCommit: nop,
};

export default EntityPicker;
