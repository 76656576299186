import React from 'react';
import { BasePage } from 'app-center-common';
import { messageBox, compileSchema, AddButtonFAB, Grid } from 'ui-core';
import Form from '../form/form.jsx';
import ExperienceFeedGridHelper from './experiences-feed-grid-helpers.jsx';
import { api, logger } from 'client-services';

let SEARCH_SCHEMA = {
	name: {},
	tags: {},
	forEvent: {enum: ['true', 'false']},
	id: {$ref: '#/definitions/MongoObjectID', description: 'exp feed id'},
};

class ExperienceFeedGrid extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedItem: null,
			search: ''
		};

		this.grid = null;
		this.columns = ExperienceFeedGridHelper.getColumns();

		this.loadMore = this.loadMore.bind(this);
		this.onNewExpFeed = this.onNewExpFeed.bind(this);
		this.onSelectionChange = this.onSelectionChange.bind(this);
		this.searchChanged = this.searchChanged.bind(this);
	}

	// #region Methods

	async loadMore(count, skip, sort) {
		logger.trace('Fetching experiences feeds, count: %s, skip: %s', count, skip);
		let q = this.state.search;
		var res = await api.experience.getExperienceFeeds({q, count, skip}).send();
		if (!res || !Array.isArray(res.experienceFeeds)) {
			messageBox('Error experiences feeds', 'Try to refresh the page');
			return [];
		}

		logger.trace('Got experiences feeds:', res);
		return res.experienceFeeds;
	}

	async upsertExperienceFeed(formData = {}) {
		var insert = !formData.id;
		var mb;
		async function onFormClosed(data) {
			mb.close(50);
			if (!data) { return; }
			var mbb = messageBox('Creating experiences feed...', null, null, true);
			try {
				if (insert) { delete data.id; }
				var req = insert ? api.experience.addExperienceFeed(data) : api.experience.updateExperienceFeed(data);
				let res = await req.send();
				if (!res.experienceFeed || !res.experienceFeed.id) {
					logger.error('Error creating new experiences feed, invalid response:', res);
					throw new Error('Got invalid response nor error, check console');
				}

				this.setState({ selectedItem: null }, () => this.grid.reset());
				mbb.close();
			} catch (ex) {
				mbb.setTitle('Error', false);
				mbb.setBody(ex.message);
			}
		}

		mb = this.showExpFeedModal(formData, insert, onFormClosed.bind(this));
	}

	showExpFeedModal(formData, insert, onSubmit) {
		var schema = compileSchema({
			type: 'object',
			required: ['name'],
			properties: {
				id: { type: 'string' },
				name: { type: 'string', minLength: 1 },
				tags: { type: 'array', items: { type: 'string' } },
				forEvent: { type: 'boolean', default: false },
			}
		});

		const uiSchema = {
			id: { 'ui:widget': 'hidden' },
			tags: {
				'ui:field': 'autocomplete',
				'ui:options': {
					autocompleteProps: {
						multiple: true,
						allowDrag: true,
						selectOnSpace: true,
						onSearch: this.searchTag.bind(this)
					}
				}
			},
		};

		var body =
			<Form
				formData={formData}
				schema={schema}
				uiSchema={uiSchema}
				submitText={insert ? 'Add' : 'Edit'}
				submit={onSubmit}
			/>;

		return messageBox('Experience Feed', body, []);
	}

	async searchTag(search) {
		if (search.length < 2) { return []; }
		logger.trace('Searching for exp feed tags, search: `%s`', search);
		try {
			var res = await api.experience.searchTags({ category: 'exp-feed', name: search }).send();
			logger.trace('Got search result for exp feed tags, search `%s`, result:', search, res.tags);
			return res.tags.map(t => t.name);
		} catch (err) {
			logger.error('Error searching exp feed tags, search `%s`, error:', search, err);
			return [];
		}
	}

	async deleteExperienceFeed(expFeed) {
		let res = await messageBox('Are you sure?', `Delete experience feed: ${expFeed.name}`, [messageBox.Buttons.Cancel, 'Delete']).promise;
		if (res !== 'Delete') { return; }
		let mb = messageBox('Deleting...', null, null, true);
		try {
			await api.experience.deleteExperienceFeed({ id: expFeed.id }).send();
			this.setState({ selectedItem: null }, () => this.grid.reset());
			mb.close();
		} catch (ex) {
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}
	}

	// #endregion Methods

	// #region Event Handlers

	searchChanged(search) {
		this.setState({ search, selectedItem: null }, () => this.grid.reset());
	}

	onSelectionChange(selection) {
		var selectedItem = null;
		if (selection.length === 1 && selection[0]?.id) {
			selectedItem = Object.assign({}, selection[0]);
		}

		this.setState({ selectedItem });
	}

	onNewExpFeed() {
		this.upsertExperienceFeed();
	}

	onUpdateExperienceFeed(expsFeedId) {
		if (!expsFeedId) { return; }
		var expsFeed = this.grid.rows.find(e => e.id === expsFeedId);
		if (!expsFeed) { return; }
		this.upsertExperienceFeed(expsFeed);
	}

	// #endregion Event Handlers

	// #region Render

	renderGridToolbar() {
		var items = [];
		if (this.state.selectedItem) {
			items = [
				{
					key: 'edit',
					title: 'Edit',
					onClick: () => this.onUpdateExperienceFeed(this.state.selectedItem.id),
					icon: 'edit'
				},
				{
					key: 'delete',
					title: 'Delete',
					onClick: () => this.deleteExperienceFeed(this.state.selectedItem),
					icon: 'delete',
					color: 'secondary'
				}
			];
		}

		return items;
	}

	render() {
		return (
			<BasePage
				toolbarOpen={true}
				showSearch={true}
				search={this.state.search}
				searchFields={SEARCH_SCHEMA}
				onSearch={ this.searchChanged }
			>
				<Grid
					ref={g => { this.grid = g; }}
					columns={this.columns}
					keyCol="id"
					pageSize={50}
					onLoadMore={this.loadMore}
					selectOnClick={true}
					toolbarItems={this.renderGridToolbar()}
					onSelectionChange={this.onSelectionChange}
				/>
				<AddButtonFAB onClick={this.onNewExpFeed} />
			</BasePage>
		);
	}

	// #endregion Render
}

ExperienceFeedGrid.propTypes = {};
ExperienceFeedGrid.defaultProps = {};

export default ExperienceFeedGrid;
