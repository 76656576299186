.image-colors {
	padding: 10px;
	display: flex;
	flex-direction: row;
	height: 100%;
	box-sizing: border-box;
}

.image-colors .colors {
	flex: 1;
	overflow: auto;
}

.image-colors .colors .blending {
	vertical-align: middle;
	font-size: 18px;
	cursor: pointer;
}

.image-colors .color-box {
	display: inline-block;
	position: relative;
	border: 1px solid;
	box-sizing: border-box;
	width: 22px;
	height: 22px;
	vertical-align: middle;
	cursor: pointer;
}

.image-colors .color-box.selected:after {
	content: " ";
	background-color: lightblue;
	position: absolute;
	left: -5px;
	right: -5px;
	top: -5px;
	bottom: -5px;
	z-index: -1;
	border-radius: 50%;
}

.image-colors .color-box .missing {
	font-size: 16px;
	position: absolute;
	right: -8px;
	top: -8px;
	color: red;
	background-color: white;
	border-radius: 50%;
}

.image-colors .color-details-wrap {
	min-width: 33%;
	overflow: auto;
	padding: 0 5px;
	box-sizing: border-box;
}

.image-colors .color-details-wrap .title {
	font-size: 1.2em;
	color: #525252;
}

.image-colors .color-details-wrap .color-details {
	border-bottom: 1px solid #dedede;
	padding: 5px 0;
}

.image-colors .color-details-wrap .color-details .color-box {
	margin-left: 10px;
	width: 18px;
	height: 18px;
}

.image-colors .color-details-wrap .color-details:last-child {
	border-bottom: none;
}

.image-colors .color-boxes {
	display: flex;
	flex-wrap: wrap;
}

.image-colors .color-boxes .color-box {
	margin: 10px 10px 0 0;
}

.image-colors .recipe {
	margin-top: 10px;
}

.image-colors .recipe > .title {
	font-weight: bold;
	margin-bottom: 5px;
}

.image-colors .recipe .part {
	margin: 10px 0;
}

.image-colors .recipe .part:first-child {
	margin-top: 5px;
}
