import {default as ERRORS, Severity} from '../audit-errors.js';
// import Paper from 'paper';
// import TopologyWorker from 'worker-loader!./topology-worker.js';
import defer from 'defer-promise';

async function run(data, config) {
	// Dont run if no need
	if (config.severities[ERRORS.sameColorAdjacent.name] === Severity.Ignore) {
		return Promise.resolve({name: run.Rule, errors: []});
	}

	validateInput(data);
	// var json = await svgToJson(data.svg);

	var deferred = defer();

	// var topologyWorker = new TopologyWorker();
	// topologyWorker.postMessage({json, kit: data.kit, config});
	// topologyWorker.onmessage = function(event) {
	// 	deferred.resolve({name: run.Rule, errors: event.data});
	// };

	return deferred.promise;
}

run.Rule = 'Topology';

// function svgToJson(svg) {
// 	return new Promise(res => {
// 		var paper = new Paper.PaperScope();
// 		paper.setup();
// 		paper.project.importSVG(svg, function svgLoaded() {
// 			var json = paper.project.exportJSON({asString: true});
// 			res(json);
// 		});
// 	});
// }

function validateInput(data) {
	if (typeof data.svg === 'undefined') {
		throw new Error('No `svg` provided');
	}

	if (!data.kit || !Array.isArray(data.kit.cubes) || data.kit.cubes.length !== 2) {
		throw new Error('No `kit.cubes` array provided');
	}
}

export default run;
