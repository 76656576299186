import CONSTANTS from '../../lib/constants.js';
import { compileSchema, toEnumSchema } from 'ui-core';
import { utils } from 'client-services';

let toEnum = toEnumSchema;

/** @type {import("@rjsf/utils").RJSFSchema} */
var schema = {
	title: 'Experience',
	type: 'object',
	required: ['name', 'slug', 'accessLevel', 'status', 'instructor', 'thumbnail', 'thumbnailLarge', 'preview'],
	properties: {
		id: { type: 'string', readOnly: true },
		name: { type: 'string' },
		slug: { type: 'string', pattern: '^\\w+(?:-\\w+){0,}$', minLength: 3},
		status: toEnum(CONSTANTS.ExperienceStatus, CONSTANTS.ExperienceStatus.Draft),
		kind: toEnum(CONSTANTS.ExperienceKind, CONSTANTS.ExperienceKind.Creative),
		isLive: { type: 'boolean', default: false },
		publishDate: { type: ['string', 'null'], format: 'date-time', title: `Publish Date (${utils.clientTimezoneString()})` },
		accessLevel: toEnum(CONSTANTS.ExperienceAccessLevel, CONSTANTS.ExperienceAccessLevel.Member),
		spacePostAccessLevel: toEnum(CONSTANTS.SpacePostAccessLevel, CONSTANTS.SpacePostAccessLevel.User),
		personalPhotoAccessLevel: toEnum(CONSTANTS.PersonalPhotoAccessLevel, CONSTANTS.PersonalPhotoAccessLevel.Member),
		preview: {
			$ref: '#/definitions/preview',
			default: undefined // to prevent a default of empty object
		},
		description: { type: 'string' },
		instructor: { $ref: '#/definitions/NullableMongoObjectID' },
		lod: toEnum(CONSTANTS.ExperienceLOD, CONSTANTS.ExperienceLOD.CreatorsOnly),
		thumbnail: { type: 'string' },
		thumbnailLarge: { type: 'string' },
		enableTrace: { type: 'boolean', title: 'With Tracing' },
		creationTabs: {
			type: 'array',
			items: toEnum(CONSTANTS.CreationTabs),
			uniqueItems: true,
			default: [...Object.values(CONSTANTS.CreationTabs)]
		},
		suggestedAudience: toEnum(CONSTANTS.Audience),
		artworkType: {
			type: 'array',
			items: toEnum(CONSTANTS.ArtworkType),
			uniqueItems: true
		},
		forEvent: { type: 'boolean', default: false },
		tools: {
			type: 'array',
			items: {
				title: '',
				type: 'object',
				properties: {
					optional: { type: 'boolean', default: false },
					tool: {
						type: 'object',
						properties: {
							name: {type: 'string'}
						}
					},
				}
			}
		},
		paperBackgrounds: {
			type: 'array',
			items: { $ref: '#/definitions/MongoObjectID' }
		},
		artStyle: { type: 'array', items: { type: 'string' } },
		artists: { type: 'array', items: { type: 'string' } },
		tags: { type: 'array', items: { type: 'string' } },
		atmosphere: { type: 'array', items: { type: 'string' } },
		colors: { type: 'array', items: { type: 'string' } },
		medium: { type: 'array', items: { type: 'string' } },
		images: {
			title: 'Gallery Images',
			type: 'array',
			items: { $ref: '#/definitions/MongoObjectID' }
		},
		sessions: {
			title: 'Creation Sessions',
			type: 'array',
			items: {
				title: '',
				type: 'object',
				properties: {
					id: {type: 'string'},
					name: { type: 'string' },
					thumbnail: { $ref: '#/definitions/imageUrl' },
					video: {
						title: 'video',
						type: 'object',
						properties: {
							id: { $ref: '#/definitions/MongoObjectID' },
							url: { $ref: '#/definitions/videoUrl' },
							duration: { type: 'integer', exclusiveMinimum: 0 },
						}
					},
					introDuration: {type: 'integer', minimum: 0, description: 'Intro duration in seconds'},
					chapters: {
						type: 'array',
						items: {
							title: '',
							type: 'object',
							required: ['name', 'start'],
							properties: {
								name: { type: 'string' },
								start: { type: 'integer', minimum: 0 },
								allowSkip: { type: 'boolean', default: false }
							}
						}
					}
				}
			}
		},
		// used for when the exp is displayed on FeaturedCard, one day it won't be here...
		featuredBanner: { type: 'string' },
		featuredPosterField: {
			type: 'string',
			enum: ['Thumbnail', 'Preview'],
			default: 'Thumbnail'
		},
		shareWithPartners: {
			type: 'array',
			items: toEnum(CONSTANTS.SharedPartners),
			uniqueItems: true
		},
	},
	definitions: {
		preview: {
			title: 'Preview',
			type: 'object',
			required: ['type', 'aspectRatio'],
			properties: {
				type: {
					type: 'string',
					enum: ['Image', 'Video']
				},
				aspectRatio: {
					type: 'number',
					exclusiveMinimum: 0
				}
			},
			dependencies: {
				type: {
					oneOf: [
						{
							required: ['params'],
							properties: {
								type: {
									enum: ['Image']
								},
								params: {
									title: '',
									type: 'object',
									required: ['url'],
									properties: {
										url: {
											$ref: '#/definitions/uri'
										}
									}
								}
							}
						},
						{
							required: ['params'],
							properties: {
								type: {
									enum: ['Video']
								},
								params: {
									title: '',
									type: 'object',
									required: ['url'],
									properties: {
										url: {
											$ref: '#/definitions/videoUrl'
										},
										poster: {
											$ref: '#/definitions/uri'
										}
									}
								}
							}
						},
					]
				}
			}
		}
	}
};

schema = compileSchema(schema);

export default schema;
