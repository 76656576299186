.space-posts-page-toolbar {
	display: flex;
	flex-direction: row;
	overflow: auto;
	align-items: center;
}

.space-posts-page-toolbar .btn-filter {
	margin: 5px;
}

.space-posts-page-toolbar .filter-time {
	margin-left: 20px;
}

.space-posts-page-toolbar .filter-time select {
	padding: 2px;
	margin-left: 5px;
}
