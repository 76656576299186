.space-post {
	background-color: #fdf5e9;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 8px;
}

.space-post .title {
	font-size: 0.9em;
	font-weight: 500;
}

.space-post .title .space-name {
	cursor: pointer;
}

/* content - card without title */
.space-post .content {
    display: flex;
    flex-direction: row;
	max-height: 210px;
	margin-top: 10px;
}

.space-post .comments {
	max-height: 300px;
	overflow: auto;
}

/* details */
.space-post .content .post-details {
	flex: 0.7;
	overflow: auto;
	display: flex;
	flex-direction: row;
}

.space-post .content .post-details .avatar {
	margin-right: 10px;
}

.space-post .content .post-details .details {
	display: flex;
	flex-direction: column;
}

.space-post .content .post-details .avatar img {
	cursor: pointer;
	width: 40px;
	height: 40px;
	max-width: 40px;
	max-height: 40px;
}

.space-post .content .post-details .details .toolbar {
	margin-top: auto;
	color: #606060;
}

.space-post .content .post-details .details .toolbar > * {
	margin-left: 10px;
}

.space-post .content .post-details .details .toolbar > *:first-child {
	margin-left: -8px;
}

.space-post .content .post-details .details .toolbar .reply {
	min-width: 0;
}

.space-post .content .post-details .details .toolbar .featured {
	color: gold;
}

.space-post .content .post-details .details .toolbar .email {
	text-decoration: none;
}

.space-post .content .post-details .details .subtitle {
	font-size: 0.9em;
	color: #606060;
}

.space-post .content .post-details .details .subtitle .username {
	cursor: pointer;
}

.space-post .content .post-details .details .subtitle .divider {
	margin: 0 7px;
}

.space-post .content .post-details .details .message {
	margin-top: 10px;
}

.space-post .content .post-details .details .parentIdLink {
	margin-top: 10px;
}

/* image */
.space-post .content .ui-element {
	overflow: auto;
	height: 100%;
	min-height: 150px;
    margin-left: 30px;
	flex: 0.3;
}

.space-post .content .ui-element .spacePost-mediaItem img,
.space-post .content .ui-element .spacePost-mediaItem video {
	display: block;
	max-width: 150px;
	cursor: pointer;
}

.space-post .content .ui-element .spacePost-mediaItem.full
{
	position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: #000000c0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.space-post .content .ui-element .spacePost-mediaItem.full img,
.space-post .content .ui-element .spacePost-mediaItem.full video {
	max-height: 100%;
	max-width: 100%;
}
