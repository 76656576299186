.exps-list {
	padding: 0 !important;

	.grid-wrap {
		width: 100%;
		height: 100%;
		display: flex;
		overflow: auto;
		justify-content: center;
		padding: 8px;
		box-sizing: border-box;
	}

	&.row .grid-wrap {
		display: block;
	}

	.grid {
		align-self: baseline;
		user-select: none;
		display: grid;
		grid-template-columns: 150px 150px;
		grid-gap: 24px;
	}

	&.row .grid {
		grid-template-columns: repeat(auto-fill, 150px);
	}

	.exp {
		position: relative;

		.exp-tools {
			display: flex;
			align-items: center;

			span {
				flex: 1;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		img {
			max-width: 100%;
			max-height: 100%;
		}

		&.draft {
			filter: grayscale();
			position: relative;
		}

		.exp-title {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			text-align: center;
			color: white;
			background-color: #00000090;
			text-transform: uppercase;
		}
	}
}

/* When dragging the element is rendered at root, not under exps-list */
.exp.exp-drag {
	pointer-events: none;
	box-shadow: -7px 7px 6px 0px rgb(0 0 0 / 20%);

	img {
		max-height: 100%;
		max-width: 100%;
	}
}