import React from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from '../../lib/constants.js';
import { Button } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort';
import { Video } from 'ui-core';
import { BasePage } from 'app-center-common';
import { messageBox, snackbar, compileSchema, EditableDropdown, Card, Checkbox, AddButtonFAB, Toolbar, ToolbarIcon, MaterialIcon } from 'ui-core';
import Form from '../form/form.jsx';
import { api, logger, utils } from 'client-services';
import clsx from 'clsx';
import './feed-page.css';

var FI_TYPE_MAP = {};
Object.keys(CONSTANTS.FeedItemType).forEach(k => {
	FI_TYPE_MAP[CONSTANTS.FeedItemType[k]] = k;
});

class FeedPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			feed: null,
			feeds: [],
			feedItems: [],
			selectMode: false
		};

		this.reordering = false;
		this.collapsed = new Set();
		this.selected = new Set();
		this.onFeedItemReorder = this.onFeedItemReorder.bind(this);
		this.onFeedItemStatusChange = this.onFeedItemStatusChange.bind(this);
		this.onDeleteFeed = this.onDeleteFeed.bind(this);
		this.onSelectClick = this.onSelectClick.bind(this);
		this.onDeleteFeedItems = this.onDeleteFeedItems.bind(this);
		this.onCopyFeedItems = this.onCopyFeedItems.bind(this);
	}

	// #region Methods

	async load() {
		try {
			var fid = this.props.params.fid || CONSTANTS.HOME_FEED_ID;

			// We fetch all the feeds as we need them for the "copy feed item" feature
			var feeds = await this.fetchFeeds();
			var feed = feeds.find(f => f.id === fid);
			if (!feed) {
				throw new Error('Feed not found');
			}

			var feedItems = await this.fetchFeedItems(feed.id);
			this.setState({ feed, feeds, feedItems, loading: false });
		} catch (ex) {
			messageBox('Error', ex.message);
			return;
		}
	}

	async fetchFeeds() {
		var res = await api.feed.getFeeds().send();
		if (!res || !Array.isArray(res.feeds)) {
			logger.error('Error when calling getFeeds, invalid response:', res);
			throw new Error('Error fetching feeds');
		}

		return res.feeds;
	}

	async fetchFeedItems(fid) {
		logger.trace('Fetching feed items for feed:', fid);
		var res = await api.feed.getFeedItems({id: fid}).send();
		var feedItems = res.feedItems;
		if (!Array.isArray(feedItems)) {
			throw new Error('Invalid feed items response, no `feedItems` array');
		}

		return feedItems;
	}

	async reloadFeedItems() {
		if (!this.state.feed || !this.state.feed.id) { return;}
		var feedItems = await this.fetchFeedItems(this.state.feed.id);
		this.setState({ feedItems });
	}

	async copyFeedItems(srcFeedItems, dstFeedId) {
		var errors = [];
		var mb = messageBox(`Copying ${srcFeedItems.length} feed items`, null, null, true);
		for (let i = 0; i < srcFeedItems.length; i++) {
			let feedItem = srcFeedItems[i];

			// Create the params for the add feed api
			var params = utils.extend(true, {}, feedItem);
			delete params.id;
			delete params.order;
			params.parent = dstFeedId;

			try {
				await api.feed.addFeedItem(params).send();
			} catch (ex) {
				logger.error('Error copying feed item %s, error:', feedItem.id, ex);
				errors.push(feedItem);
			}
		}

		if (errors.length > 0) {
			mb.setTitle('Error copying these feed items', false);
			mb.setBody(errors.map(fi => fi.name).join(', '));
		} else {
			mb.close(10);
			snackbar('Feed items copied');
		}

		// If we copied to our own feed reload it
		if (dstFeedId === this.state.feed.id) {
			await this.reloadFeedItems();
		}

		this.setState({ selectMode: false });
	}

	async deleteFeedItems(srcFeedItems) {
		var res = await messageBox('Are you sure?', `Please confirm deleting ${srcFeedItems.length} items`, [messageBox.Buttons.Cancel, 'Delete']).promise;
		if (res === messageBox.Buttons.Cancel) {return;}

		var mb = messageBox('Deleting feed items...');
		var errors = [];
		for (let i = 0; i < srcFeedItems.length; i++) {
			let feedItem = srcFeedItems[i];
			try {
				await api.feed.deleteFeedItem({ id: feedItem.id }).send();
			} catch (ex) {
				logger.error('Error deleting feed item %s, error:', feedItem.id, ex);
				errors.push(feedItem);
			}
		}

		if (errors.length > 0) {
			mb.setTitle('Error deleting these feed items', false);
			mb.setBody(errors.map(fi => fi.name).join(', '));
		} else {
			mb.close(10);
			snackbar('Feed items deleted');
			this.reloadFeedItems();
			this.setState({ selectMode: false });
		}
	}

	// #endregion Methods

	// #region EventHandlers

	onToggleExpand(id, expanded) {
		if (this.reordering) { return;}
		if (expanded) {
			this.collapsed.delete(id);
		} else {
			this.collapsed.add(id);
		}

		this.setState({});
	}

	onToggleExpandAll(expanded) {
		if (expanded) {
			this.collapsed = new Set();
		} else {
			this.state.feedItems.forEach(fi => this.collapsed.add(fi.id));
		}

		this.setState({});
	}

	onSelectClick() {
		this.setState({ selectMode: !this.state.selectMode });
		this.selected.clear();
	}

	onFeedItemChecked(fid, ev, checked) {
		if (checked) {
			this.selected.add(fid);
		} else {
			this.selected.delete(fid);
		}
	}

	async onDeleteFeed() {
		var res = await messageBox('Are you sure ?', 'This will delete the feed AND ALL ITS ITEMS !!', [messageBox.Buttons.Cancel, 'Delete']).promise;
		if (res === messageBox.Buttons.Cancel) {return;}

		var mb = messageBox('Deleting feed...');
		var id = this.state.feed.id;
		try {
			await api.feed.deleteFeed({ id }).send();
			mb.setTitle('Feed deleted', false);
			mb.setBody('Please close the tab');
		} catch (ex) {
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}
	}

	async onFeedItemReorder(oldIdx, newIdx) {
		let fi = this.state.feedItems[oldIdx];
		if (!fi?.id) {
			messageBox('Oops, cant find feed item id, this is weird...');
			return;
		}

		this.reordering = true;
		var mb = messageBox('Moving feed item...');
		var feedId = this.state.feed.id;
		try {
			await api.feed.reorderFeedItem({ id: fi.id, newOrder: newIdx, feed: feedId }).send();
			this.reloadFeedItems();
			mb.close(10);
		} catch (ex) {
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}

		this.reordering = false;
	}

	async onFeedItemStatusChange(id, val) {
		var fi = this.state.feedItems.find(fi => fi.id === id);
		if (!fi) {
			console.error('Feed item with id %s not found in state feedItems list:', id, this.state.feedItems);
			messageBox('Unexpected error, check console', '', [messageBox.Buttons.OK]);
			return true;
		}

		var mb = messageBox('Updating...');
		try {
			var res = await api.feed.updateFeedItem({ id, status: val }).send();
			if (!res || !res.feedItem) {
				throw new Error('Invalid response from server, missing `feedItem`');
			}

			mb.close();
			fi.status = res.feedItem.status;
			this.setState({feedItems: this.state.feedItems});
		} catch (ex) {
			mb.setTitle('Error');
			mb.setBody(ex.message);
		}
	}

	onCopyFeedItems() {
		var mb;
		function onFormClosed(data) {
			mb.close(50);
			if (!data) { return; }

			var feedItems = this.state.feedItems.filter(fi => this.selected.has(fi.id));
			if (feedItems.length !== this.selected.size) {
				messageBox('Unexpected error', 'Some selected feed items not found, aborting');
				return;
			}

			if (!data.dstFeedId) {
				messageBox('Destination feed id not found');
				return;
			}

			this.copyFeedItems(feedItems, data.dstFeedId);
		}

		if (this.selected.size < 1) {
			messageBox('Please select some items first');
			return;
		}

		if (this.state.feeds.length < 1) {
			messageBox('There are other feeds to copy to');
			return;
		}

		var formData = {};
		var schema = compileSchema({
			type: 'object',
			properties: {
				dstFeedId: {
					type: 'string',
					title: 'Feed',
					enum: this.state.feeds.map(f => f.id),
					enumNames: this.state.feeds.map(f => f.name)
				}
			}
		});

		var body =
			<Form
				formData={formData}
				schema={schema}
				submitText="Copy"
				submit={onFormClosed.bind(this)}
			/>;

		mb = messageBox('Copy to feed', body, []);
	}

	onDeleteFeedItems() {
		if (this.selected.size < 1) {
			messageBox('Please select some items first');
			return;
		}

		var feedItems = this.state.feedItems.filter(fi => this.selected.has(fi.id));
		if (feedItems.length !== this.selected.size) {
			messageBox('Unexpected error', 'Some selected feed items not found, aborting');
			return;
		}

		this.deleteFeedItems(feedItems);
	}

	// #endregion EventHandlers

	// #region Lifecycle

	componentDidMount() {
		this.load();
	}

	componentDidUpdate(prevProps) {
		var fid = this.props.params.fid || CONSTANTS.HOME_FEED_ID;
		var pfid = prevProps.params.fid || CONSTANTS.HOME_FEED_ID;
		if (fid === pfid) {
			return;
		}

		this.load();
	}

	// #endregion Lifecycle

	// #region Render

	renderParams(fi) {
		var content = null;
		switch (fi.type) {
			case CONSTANTS.FeedItemType.ExperienceFeed:
				content = <div>Experience Feed: <Link to={`/experienceFeed/${fi.experienceFeed || ''}`}>{fi.experienceFeed || ''}</Link></div>;
				break;
			case CONSTANTS.FeedItemType.Experience:
				content = <div>Experience: <Link to={`/experience/${fi.experience || ''}`}>{fi.experience || ''}</Link></div>;
				break;
			case CONSTANTS.FeedItemType.Feed:
				content = <div>Feed: <Link to={`/feed/${fi.feed || ''}`}>{fi.feed || ''}</Link></div>;
				break;
			case CONSTANTS.FeedItemType.Banner:
				content = <div>Banner: {fi.bannerUrl ? <Link to={fi.bannerUrl}>Link...</Link> : ''}</div>;
				break;
			case CONSTANTS.FeedItemType.Video:
				content = <div>Video: {fi.videoUrl ? <Link to={fi.videoUrl}>Link...</Link> : ''}</div>;
				break;
			case CONSTANTS.FeedItemType.Screen:
				content = <div>Screen Name: {fi.screenName}</div>;
				break;
		}

		return content;
	}

	renderFeedItem(fi) {
		var expanded = !this.collapsed.has(fi.id);
		var { feed, selectMode } = this.state;
		return (
			<Card
				key={fi.id}
				className={clsx('feed-item', {'title-item': fi.type === CONSTANTS.FeedItemType.Title})}
				data-id={fi.id}
				expanded={expanded}
				title={fi.name}
				leftIcon={selectMode ? <Checkbox onCheck={this.onFeedItemChecked.bind(this, fi.id)} /> : <SortableKnob><div><MaterialIcon name="drag_handle" /></div></SortableKnob>}
				actions={expanded &&
					<Link to={`/feed/${feed.id}/item/${fi.id}`} target="_blank" style={{textDecoration: 'none'}}>
						<MaterialIcon name="edit" title="Edit" style={{ color: 'black' }} />
					</Link>
				}
				onExpandChange={this.onToggleExpand.bind(this, fi.id)}
			>
				<div className="content">
					<div className="details">
						<div>Type: {FI_TYPE_MAP[fi.type] }</div>
						<div>{ this.renderParams(fi) }</div>
						<div className="fi-status">Status: <EditableDropdown name={fi.id} className="dropdown" onChange={this.onFeedItemStatusChange} value={fi.status} values={Object.values(CONSTANTS.ExperienceStatus)} texts={Object.keys(CONSTANTS.ExperienceStatus)} /></div>
						<div>UI Element: {fi.uiElement.type}</div>
						<div>Card Ratio: {fi.uiElement.aspectRatio}</div>
						<div>Order: {fi.order}</div>
						<div>Last Update: {utils.toDateString(fi.lastUpdate, true)}</div>
						<div className="faded">ID: {fi.id}</div>
					</div>
					<div className="ui-element">{this.renderUIElement(fi.uiElement)}</div>
				</div>
			</Card>
		);
	}

	renderUIElement(uiElement) {
		switch (uiElement.type) {
			case CONSTANTS.FeedItemUIElementType.FullCardImage:
				return this.renderFullCardImage(uiElement);
			case CONSTANTS.FeedItemUIElementType.FullCardVideo:
				return this.renderFullCardVideo(uiElement);
			case CONSTANTS.FeedItemUIElementType.FeaturedCard:
				return this.renderFeaturedCard();
		}
	}

	renderFullCardImage(uiElement) {
		return (
			<div className="fc-image">
				<img src={uiElement.params.url} />
			</div>
		);
	}

	renderFullCardVideo(uiElement) {
		return (
			<Video
				className="fc-video"
				controls
				preload='metadata'
				autoStartLoad={false}
				poster={uiElement.params.poster}
				url={uiElement.params.url}
			/>
		);
	}

	renderFeaturedCard() {
		return (
			<div className="featured">
				<div>Featured Card</div>
				<div className="featured-buttons">
					<span>Start Now</span>
					<span>Later</span>
				</div>
			</div>
		);
	}

	renderToolbar() {
		var { selectMode, feed } = this.state;
		let isHome = feed.id === CONSTANTS.HOME_FEED_ID;

		return (
			<Toolbar>
				<ToolbarIcon name="expand_less" className="icon lg" title="Collapse All" onClick={this.onToggleExpandAll.bind(this, false)} />
				<ToolbarIcon name="expand_more" className="icon lg" title="Expand All" onClick={this.onToggleExpandAll.bind(this, true)} />
				<Button onClick={this.onSelectClick}>{selectMode ? 'Cancel' : 'Select'}</Button>
				{selectMode && <ToolbarIcon name="file_copy" className="icon md" title="Copy" onClick={this.onCopyFeedItems} />}
				{selectMode && <ToolbarIcon name="delete" className="icon md delete" title="Delete" onClick={this.onDeleteFeedItems} />}
				{!isHome && <ToolbarIcon name="delete" right color="secondary" variant="outlined" onClick={this.onDeleteFeed}>Delete Feed</ToolbarIcon>}
			</Toolbar>
		);
	}

	render() {
		var { loading, feedItems, feed, selectMode } = this.state;
		if (loading) { return 'Loading...'; }

		return (
			<BasePage
				className="exp-feed"
				title={`Feed - ${feed.name || 'N/A'}`}
				subTitle={`(${this.state.feedItems.length} items)`}
				toolbar={this.renderToolbar()}
				toolbarOpen={true}
			>
				<SortableList
					onSortEnd={this.onFeedItemReorder}
					allowDrag={!selectMode}
					lockAxis="y"
				>
					{feedItems.map(fi => (
						<SortableItem key={fi.id}>
							<div>
								{this.renderFeedItem(fi)}
							</div>
						</SortableItem>
					))}
				</SortableList>
				<AddButtonFAB href={`/feed/${feed.id}/item/new`} />
			</BasePage>
		);
	}

	// #endregion Render
}

FeedPage.propTypes = {
	params: PropTypes.object
};

export default function WrappedFeedPage(props) {
	let params = useParams();
	return <FeedPage params={params} {...props} />;
}
