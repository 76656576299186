import CONSTANTS from '../../../lib/constants.js';
import { humanize } from 'ui-core';
import { queryOperators, basicFieldValidation } from './validators.js';

function defaultFieldValidation({ field, operator, value }) {
	let fieldDef = expQueryFields.find(f => f.name === field);
	if (!fieldDef) {
		return {valid: false, reasons: [`invalid field: ${field}`]};
	}

	return basicFieldValidation(fieldDef, operator, value);
}

function toList(obj) {
	return Object.keys(obj).map(k => ({ name: obj[k], label: k }));
}

function boolList() {
	return toList({ true: true, false: false });
}

function getOps(ops) {
	ops = Array.isArray(ops) ? ops : [ops];
	return queryOperators.filter(op => ops.includes(op.name));
}

let expQueryFields = [
	{
		name: 'status',
		valueEditorType: 'multiselect',
		datatype: 'number',
		values: toList(CONSTANTS.ExperienceStatus),
		ops: ['in', 'notIn']
	},
	{
		name: 'kind',
		valueEditorType: 'select',
		datatype: 'number',
		values: toList(CONSTANTS.ExperienceKind),
		ops: ['=', '!=']
	},
	{
		name: 'accessLevel',
		valueEditorType: 'multiselect',
		datatype: 'number',
		values: toList(CONSTANTS.ExperienceAccessLevel),
		ops: ['in', 'notIn']
	},
	{
		name: 'suggestedAudience',
		valueEditorType: 'multiselect',
		values: toList(CONSTANTS.Audience),
		ops: ['in', 'notIn']
	},
	{
		name: 'artworkType',
		valueEditorType: 'multiselect',
		values: toList(CONSTANTS.ArtworkType),
		ops: ['in', 'notIn']
	},
	{
		name: 'personalPhotoAccessLevel',
		valueEditorType: 'select',
		datatype: 'number',
		values: toList(CONSTANTS.PersonalPhotoAccessLevel),
		ops: ['=', '!=']
	},
	{
		name: 'lod',
		label: 'LOD',
		valueEditorType: 'select',
		datatype: 'number',
		values: toList(CONSTANTS.ExperienceLOD),
		ops: ['=', '!=', '<', '>', '<=', '>=']
	},
	{
		name: 'instructor',
		ops: ['=', '!=', 'in', 'notIn'],
		datatype: 'objectId'
	},
	{
		name: 'forEvent',
		valueEditorType: 'select',
		datatype: 'boolean',
		defaultValue: false,
		values: boolList(),
		ops: '='
	},
	{
		name: 'isLive',
		valueEditorType: 'select',
		datatype: 'boolean',
		defaultValue: false,
		values: boolList(),
		ops: '='
	},
	{
		name: 'enableTrace',
		label: 'With Tracing',
		valueEditorType: 'select',
		datatype: 'boolean',
		defaultValue: false,
		values: boolList(),
		ops: '='
	},
	{
		name: 'shareWithPartners',
		valueEditorType: 'multiselect',
		values: toList(CONSTANTS.SharedPartners),
		ops: ['in', 'notIn']
	},
	{
		name: 'tags',
		valueEditorType: 'tagsAutocomplete',
		defaultValue: [],
		ops: ['in', 'notIn']
	},
	{
		name: 'medium',
		valueEditorType: 'tagsAutocomplete',
		defaultValue: [],
		ops: ['in', 'notIn']
	},
	{
		name: 'colors',
		valueEditorType: 'tagsAutocomplete',
		defaultValue: [],
		ops: ['in', 'notIn']
	},
	{
		name: 'artStyle',
		valueEditorType: 'tagsAutocomplete',
		defaultValue: [],
		ops: ['in', 'notIn']
	},
	{
		name: 'atmosphere',
		valueEditorType: 'tagsAutocomplete',
		defaultValue: [],
		ops: ['in', 'notIn']
	},
	{
		name: 'artists',
		valueEditorType: 'tagsAutocomplete',
		defaultValue: [],
		ops: ['in', 'notIn']
	},
];

expQueryFields = expQueryFields.map(r => {
	r.id = r.name;
	r.label = r.label || humanize(r.name);
	if (r.ops) {
		r.operators = getOps(r.ops);
	}

	r.validator = defaultFieldValidation;

	return r;
}).sort((r1, r2) => r1.label > r2.label ? 1 : -1);

function defaultQuery() {
	return {
		forEvent: false
	};
}

export { queryOperators, expQueryFields, defaultQuery };
