import React from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { Grid, messageBox, Modal, ToolbarIcon } from 'ui-core';
import { BasePage } from 'app-center-common';
import Form from '../form/form.jsx';
import { logger } from 'client-services';

import './entity-crud-page.css';

class EntityCRUDPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedItem: null,
			showEditModal: false,
			search: this.props.searchParams.get('q') || '',
			total: 0
		};

		this.name = props.name;
		this.grid = null;

		this.onEditModalClose = this.onEditModalClose.bind(this);
		this.searchChanged = this.searchChanged.bind(this);
	}

	// #region Methods

	reset() {
		this.setState({ selectedItem: null }, () => this.grid.reset());
	}

	// #endregion Methods

	// #region Events Handlers

	async onLoadMore(count, skip, sort) {
		logger.trace(`Fetching ${this.name}, count: %s, skip: %s:`, count, skip);
		this.fetching = true;
		let q = this.state.search;

		try {
			let res = await this.props.apiGetMulti({ count, skip, sort, q });
			logger.trace('Got entities `%s`:', this.name, res.entities);
			if (res.total >= 0) {
				this.setState({ total: res.total });
			}
			return res.entities;
		} catch (e) {
			logger.error('Error fetching `%s`:', this.name, e);
			messageBox(`Error loading ${this.name}`, e.message);
			return [];
		}
	}

	onSelectionChange(selection) {
		let selectedItem = null;
		if (selection.length === 1 && selection[0]) {
			selectedItem = selection[0];
		}

		this.setState({ selectedItem });
	}

	searchChanged(search) {
		this.setState({ search }, () => this.reset());
	}

	async onDelete(id) {
		if (!id || !this.props.apiDelete) { return; }

		let res = await messageBox('Are you sure?', '', [messageBox.Buttons.Cancel, messageBox.Buttons.Yes]).promise;
		if (res !== messageBox.Buttons.Yes) { return; }

		logger.trace('Deleting `%s` with id:', this.name, id);
		var mb = messageBox('Deleting...', '', null, true);
		try {
			await this.props.apiDelete({ id }).send();
			mb.setTitle('Success');
			mb.close(500);
			this.reset();
		} catch (ex) {
			logger.error('Error deleting `%s`:', this.name, ex);
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}
	}

	async onEdit() {
		// weird but check anyway
		if (!this.state.selectedItem) { return; }
		this.setState({ showEditModal: true });
	}

	onAdd() {
		this.grid.clearSelection();
		this.setState({ showEditModal: true, selectedItem: null });
	}

	async onEditModalClose(data) {
		if (!data) {
			this.setState({ showEditModal: false });
			return;
		}

		var apiFunc = this.state.selectedItem === null ? this.props.apiAdd : this.props.apiUpdate;
		logger.trace('Upserting `%s` with params:', this.name, data);
		let mb = messageBox('Updating...', '', null, true);

		try {
			let res = await apiFunc(data).send();
			logger.trace('Got upsert `%s` result:', this.name, res);
			this.reset();
			mb.close();
			this.setState({ showEditModal: false });
		} catch (ex) {
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
			logger.error('Error upserting `%s`, error:', this.name, ex);
		}
	}

	// #endregion

	// #region Render

	renderGridToolbar() {
		if (!this.state.selectedItem) {return [];}

		let items = [
			{
				key: 'edit',
				title: 'Edit',
				onClick: () => this.onEdit(),
				icon: 'edit'
			}
		];

		if (this.props.apiDelete) {
			items.push({
				key: 'delete',
				title: 'Delete',
				onClick: () => this.onDelete(this.state.selectedItem.id),
				icon: 'delete'
			});
		}

		return items;
	}

	renderToolbar() {
		return (
			<ToolbarIcon name="playlist_add" title="Add" onClick={this.onAdd.bind(this)} />
		);
	}

	render() {
		return (
			<BasePage
				title={this.props.pageTitle}
				subTitle={`(${this.state.total})`}
				toolbar={this.renderToolbar()}
				toolbarOpen={true}
				showSearch={true}
				search={this.state.search}
				onSearch={ this.searchChanged }
			>
				<Grid
					ref={grid => { this.grid = grid; }}
					columns={this.props.columns}
					keyCol="id"
					rowHeight={this.props.rowHeight}
					selectOnClick={true}
					toolbarItems={this.renderGridToolbar()}
					onLoadMore={this.onLoadMore.bind(this)}
					onSelectionChange={this.onSelectionChange.bind(this)}
				/>
				<Modal className="edit-entity-modal" open={this.state.showEditModal} showCloseButton={false}>
					<Form
						schema={this.props.schema}
						uiSchema={this.props.uiSchema}
						formData={this.state.selectedItem || {}}
						buttonsAlign='right'
						submit={this.onEditModalClose}
					>
					</Form>
				</Modal>
			</BasePage>
		);
	}

	// #endregion
}

EntityCRUDPage.propTypes = {
	searchParams: PropTypes.object,
	name: PropTypes.string.isRequired,
	pageTitle: PropTypes.string.isRequired,
	columns: PropTypes.array.isRequired,
	schema: PropTypes.object.isRequired,
	uiSchema: PropTypes.object,
	apiGetMulti: PropTypes.func.isRequired,
	apiUpdate: PropTypes.func.isRequired,
	apiAdd: PropTypes.func,
	apiDelete: PropTypes.func,
	rowHeight: PropTypes.number,
};

EntityCRUDPage.defaultProps = {
	rowHeight: 35
};

export default function WrappedEntityCRUDPage(props) {
	let [searchParams] = useSearchParams();
	return <EntityCRUDPage searchParams={searchParams} {...props} />;
};

