import React from 'react';
import PropTypes from 'prop-types';
import { Modal, } from 'ui-core';
import CONSTANTS from '../../../lib/constants.js';
import MediaAssetController from '../media-asset-controller.jsx';
import './media-asset-modal.css';

class MediaAssetModal extends React.Component {
	constructor(props) {
		super(props);
	};

	onPickAsset(data) {
		this.props.onClose(data);
	}

	render() {
		if (!this.props.open) {
			return null;
		}

		return (
			<Modal open fullscreen className="media-asset-modal" showCloseButton={true} onClose={() => this.props.onClose() }>
				<div className="content">
					<MediaAssetController
						mode={this.props.mode}
						allowSelect={true}
						allowDelete={false}
						isExperience={this.props.isExperience}
						assetTypes={this.props.assetTypes}
						maxFileSize={this.props.maxFileSize}
						onPickAsset= {this.onPickAsset.bind(this)}
					/>
				</div>
			</Modal>
		);
	}
}

MediaAssetModal.propTypes = {
	open: PropTypes.bool,
	isExperience: PropTypes.bool,
	mode: PropTypes.string,
	assetTypes: PropTypes.arrayOf(PropTypes.string),
	maxFileSize: PropTypes.number.isRequired,
	onClose: PropTypes.func,
};

function nop() { }
MediaAssetModal.defaultProps = {
	open: true,
	assetTypes: Object.values(CONSTANTS.AssetTypes),
	onClose: nop,
};

export default MediaAssetModal;
