.event-details-row {
	padding: 10px;
	display: flex;
	flex-direction: row;
}

.event-details-row .ui-toolbar {
	padding: 10px 0;
}

.event-details-row .info-pane {
	flex: 0.7;
}

.event-details-row .title {
	font-weight: bold;
}

.event-details-row .details {
	display: table;
	font-size: 0.9em;
	margin-top: 5px;
}

.event-details-row .details > div {
	display: table-row;
}

.event-details-row .details > div > div {
	display: table-cell;
	padding: 1px 0 1px 10px;
}

/* The label cell should no be broken */
.event-details-row .details > div > div:first-child {
	white-space: nowrap;
	padding-left: 2px;
}

.event-details-row .details .gray {
	color: #aaaaaa;
}

.event-details-row .toggle {
	text-align: right;
	padding-right: 10px;
	font-size: 0.8em;
	user-select: none;
}

.event-details-row .toggle > span {
	cursor: pointer;
}

