import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import { ValueEditor } from 'react-querybuilder';
import TagsValueEditor from './tags-value-editor.jsx';

let cast = {
	number: v => Number(v),
	boolean: v => {
		return v === 'true' ? true : false;
	}
};

function CustomValueEditor(props) {
	let { name, valueEditorType, datatype } = props.fieldData;
	let { handleOnChange, ...rest } = props;

	let onChange = useCallback(val => {
		if (datatype && typeof cast[datatype] === 'function') {
			if (Array.isArray(val)) {
				val = val.map(v => cast[datatype](v));
			} else {
				val = cast[datatype](val);
			}
		}

		handleOnChange(val);
	}, [datatype, handleOnChange]);

	if (valueEditorType === 'tagsAutocomplete') {
		let { value } = props;
		return (
			<TagsValueEditor
				field={name}
				value={value}
				onChange={handleOnChange}
			/>
		);
	}

	return <ValueEditor {...rest} handleOnChange={onChange} />;
};

CustomValueEditor.propTypes = {
	fieldData: PropTypes.any,
	value: PropTypes.any,
	handleOnChange: PropTypes.func
};

export default CustomValueEditor;
