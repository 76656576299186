import React from 'react';
import EntityCRUDPage from '../entity-crud-page/entity-crud-page.jsx';
import InstructorGridHelper from './instructors-grid-helpers.jsx';
import {api} from 'client-services';

import InstructorSchema from './instructor.schema.js';
import InstructorSchemaUI from './instructor.ui.schema.js';

class InstructorsPage extends React.Component {
	constructor(props) {
		super(props);
	}

	async getInstructors(params) {
		var res = await api.experience.getInstructors(params).send();
		res.entities = res.instructors;
		return res;
	}

	render() {
		return (
			<EntityCRUDPage
				name="instructor"
				pageTitle="Instructors"
				columns={InstructorGridHelper.getColumns()}
				schema={InstructorSchema}
				uiSchema={InstructorSchemaUI}
				apiGetMulti={this.getInstructors.bind(this)}
				apiUpdate={api.experience.updateInstructor}
				apiAdd={api.experience.addInstructor}
				apiDelete={api.experience.deleteInstructor}
				rowHeight={InstructorGridHelper.rowHeight}
				{...this.props}
			/>
		);
	}
}

export default InstructorsPage;
