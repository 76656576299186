import React from 'react';
import PropTypes from 'prop-types';
import { MaterialIcon } from 'ui-core';
import {SVGTools, utils} from 'client-services';

import clsx from 'clsx';

import './image-colors.css';

class ImageColors extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			colors: [],
			working: true
		};

		this.svgTools = new SVGTools(document);

		this.onBoxClick = this.onBoxClick.bind(this);

		setTimeout(this.processColors.bind(this), 100, this.props.svg);
	}

	// <editor-fold desc="// Methods {...}">

	processColors(svg) {
		if (!svg) {
			return [];
		}

		this.svgTools.svg = svg;
		this.svgTools.getColors(true).then(colors => {
			this.setState({working: false, colors});
		});
	}

	// </editor-fold> // Methods

	// <editor-fold desc="// EventHandlers {...}">

	onBoxClick(ev) {
		if (!ev || !ev.currentTarget) {return;}
		var c = ev.currentTarget.getAttribute('data-color');
		if (!c) {return;}
		this.props.onClick(c, {alt: ev.altKey, shift: ev.shiftKey});
	}

	getRecipes() {
		var recipes = [];
		for (var i = 0; i < this.state.colors.length; i++) {
			var c = this.state.colors[i];
			var r = this.props.paletteSvc.getRecipe(c.hex, c.pct);
			if (!r && this.props.validate) {
				recipes.push(null);
				continue;
			}

			if (!r) {
				r = {color: c.hex};
			}

			r.name = c.name;
			r.code = c.code;
			recipes.push(r);
		}

		return recipes;
	}

	// </editor-fold> // EventHandlers

	// <editor-fold desc="// Lifecycle {...}">

	shouldComponentUpdate(np, ns) {
		if (np.svg !== this.props.svg) {
			this.props = np;
			setTimeout(this.processColors.bind(this), 5, np.svg);
			this.setState({working: true});
			return false;
		}

		return (ns.working !== this.state.working) || (np.validate !== this.props.validate) || (np.selectedColors.length !== this.props.selectedColors.length) ||
				np.selectedColors.some(c => this.props.selectedColors.indexOf(c) < 0);
	}

	// </editor-fold> // Lifecycle

	// <editor-fold desc="// Render {...}">

	renderColorBoxes(colors) {
		return (
			colors.map(c => {
				var selected = this.props.selectedColors.includes(c.hex);
				var inPalette = !this.props.validate || this.props.paletteSvc.contains(c.hex);
				return (
					<React.Fragment key={c.hex}>
						<div
							className={clsx('color-box', {selected})}
							data-color={c.hex}
							style={{backgroundColor: c.hex}}
							onClick={this.onBoxClick}
							title={c.name}
						>
							{inPalette ? null : <MaterialIcon name="error" className="missing" title="Color not in Cupixel palette"/> }
						</div>
					</React.Fragment>
				);
			})
		);
	}

	renderColorDetails(selectedColors) {
		var colors = this.state.colors.filter(c => selectedColors.includes(c.hex));
		if (colors.length < 1) {
			return (
				<div className="color-details-wrap">
					<div className="title">Color Details</div>
					<div>Pick a color for details</div>
				</div>
			);
		}

		var recipes = {};
		colors.forEach(c => {
			recipes[c.hex] = this.props.paletteSvc.getRecipe(c.hex, c.pct);
		});

		return (
			<div className="color-details-wrap">
				{colors.map(c =>
					<div key={c.hex} className="color-details">
						<div className="title">
							<span>{c.name}</span>
							<span className="color-box" style={{backgroundColor: c.hex}} />
						</div>
						<div>hex: {c.hex}</div>
						<div>Percentage: {c.pct}%</div>
						{this.renderRecipe(recipes[c.hex])}
					</div>
				)}
			</div>
		);
	}

	renderRecipe(recipe) {
		if (!recipe) {return null;}
		var required = utils.precision(recipe.requiredPaint, 2);
		var actual = utils.precision(recipe.actualPaint, 2);
		return (
			<div className="recipe">
				<div className="title">Blending:</div>
				<div>Required: {required <= 0 ? '< 1' : required}{recipe.units}</div>
				<div>Actual: {actual}{recipe.units}</div>
				<div>Containers: {recipe.containers}</div>
				{recipe.mixture.map((m, i) =>
					<div key={i} className="part">
						<div>
							<span>Name: {m.tube ? m.tube.name : 'N/A'}</span>
							<span className="color-box" title={m.tube ? m.tube.color : ''} style={{backgroundColor: m.tube ? m.tube.color : 'transparent'}} />
						</div>
						<div>Spoons: {m.spoons.map(s => s.size + recipe.units).join(', ')}</div>
						<div>Part: {m.part}</div>
					</div>
				)}
			</div>
		);
	}

	render() {
		if (this.state.working) {
			return <div>Loading colors...</div>;
		}

		var colors = this.state.colors;
		return (
			<div className="image-colors">
				<div className="colors">
					<div>{colors.length} Colors</div>
					<div className="color-boxes">
						{this.renderColorBoxes(colors)}
					</div>
				</div>
				{this.renderColorDetails(this.props.selectedColors)}
			</div>
		);
	}

	// </editor-fold> // Render
}

ImageColors.propTypes = {
	paletteSvc: PropTypes.object.isRequired,
	svg: PropTypes.string.isRequired,
	validate: PropTypes.bool,
	onClick: PropTypes.func,
	selectedColors: PropTypes.arrayOf(PropTypes.string)
};

ImageColors.defaultProps = {
	selectedColors: [],
	onClick: function nop() {}
};

export default ImageColors;
