import React from 'react';
import { MongoDBDashboard } from 'app-center-common';
import { config } from 'client-services';

let googleKey = config.get('app:keys:google');
let cfgHomeDashboardUrl = config.get('data:mongoChartsDashboard:home:url');
let cfgHomeDashboardId = config.get('data:mongoChartsDashboard:home:id');

const STYLES = {
	main: {
		width: '100%',
		height: '100%'
	}
};

function TestPage(props) {
	return (
		<div style={STYLES.main}>
			<MongoDBDashboard googleKey={googleKey} dashboardUrl={cfgHomeDashboardUrl} dashboardId={cfgHomeDashboardId} />
		</div>
	);
}

export default TestPage;
