/** @type {import('@rjsf/utils').UiSchema} */
var uiSchema = {
	artist: {
		'ui:field': 'autocomplete'
	},
	groups: {
		'ui:help': 'Related images groups',
		'ui:field': 'autocomplete'
	},
	tags: {
		'ui:field': 'autocomplete'
	},
};

export default uiSchema;