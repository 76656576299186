import React from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from '../../lib/constants.js';
import { messageBox, snackbar } from 'ui-core';
import AssetPickerView from './asset-picker-view/asset-picker-view.jsx';
import { api, http, logger } from 'client-services';

// by default we return only public media assets
// if you explicitly want to see draft then type: +status:draft in search
let PUBLIC_STATUS_FILTER = ` +status:${CONSTANTS.MediaAssetStatus.Public}`;

class MediaAssetController extends React.Component {
	constructor(props) {
		super(props);
	};

	// #region UPLOAD funcs

	uploadImage(data) {
		logger.trace(`Start image submit process, data: ${data}`);

		let formData = new FormData();
		formData.append('isExperience', this.props.isExperience);
		if (data.name) { formData.append('name', data.name); };
		formData.append('size', data.file?.size || 0);
		formData.append('file', data.file);

		return api.mediaAsset.uploadImageAsset(formData).send();
	}

	async uploadAsset(data, mb) {
		let mimeType = data.file.type;
		if (mimeType.indexOf('pdf') < 0) {
			throw new Error('Invalid file type, only `pdf` is supported');
		}

		let params = {
			type: CONSTANTS.AssetTypes.PDF,
			name: data.name,
			filename: data.file.name,
		};

		logger.trace(`Uploading ${mimeType}... data: ${params}`);
		let uploadRes = await this.uploadAssetToS3(params, data.file, mb);

		let id = uploadRes.mediaAsset.id;
		return api.mediaAsset.endAssetUploadProcess({ id, isExperience: this.props.isExperience }).send();
	}

	async uploadVideo(data, mb) {
		let type = CONSTANTS.AssetTypes.Video;
		let videoObj = {
			type,
			name: data.name,
			filename: data.file.name,
			aspectRatio: data.file.aspectRatio,
			duration: data.file.duration,
		};

		logger.trace(`Uploading ${type}... data: ${videoObj}`);
		let uploadRes = await this.uploadAssetToS3(videoObj, data.file, mb);

		let id = uploadRes.mediaAsset.id;
		return api.mediaAsset.endAssetUploadProcess({ id, isExperience: this.props.isExperience }).send();
	}

	async uploadAssetToS3(assetParams, file, mb) {
		assetParams.isExperience = this.props.isExperience;
		assetParams.contentType = file.type;
		assetParams.size = file.size || 0;
		let uploadRes = await api.mediaAsset.startAssetUploadProcess(assetParams).send();

		let awsMetadata = uploadRes?.aws;
		if (!uploadRes?.mediaAsset?.id || !awsMetadata.url) {
			logger.error('Got invalid response from api.mediaAsset.startAssetUploadProcess, got:', uploadRes);
			throw new Error('Got invalid response from server');
		}

		logger.trace(`Uploading file with url ${awsMetadata.url}`);
		var req = http.upload('PUT', awsMetadata.url, file);
		req.progress(pct => {
			var uploadMsg = `Uploading ${pct.toFixed(2)}%`;
			mb.setBody(uploadMsg);
		});

		await req.done();

		return {mediaAsset: uploadRes.mediaAsset};
	};

	// #endregion

	// #region MEDIA ASSET API

	/**
	 *
	 * @param {String} id - Asset id to delete / restore
	 * @param {Boolean} shouldDelete - Whether to delete or restore the item
	 */
	async onDelete(id, shouldDelete) {
		if (!id) {
			messageBox('Got invalid `id`');
			return;
		}

		let res = await messageBox('Are you sure?', '', [messageBox.Buttons.Cancel, messageBox.Buttons.Yes]).promise;
		if (res !== messageBox.Buttons.Yes) {
			return;
		}

		var data = {
			id,
			isExperience: this.props.isExperience,
			status: shouldDelete ? CONSTANTS.MediaAssetStatus.Deleted : CONSTANTS.MediaAssetStatus.Public
		};

		let mb = messageBox('Updating item status...', null, [messageBox.Buttons.OK], true);
		try {
			logger.trace('Marking media asset as deleted, params:', data);
			let res = await api.mediaAsset.updateMediaAsset(data).send();
			logger.trace('Updated media asset as deleted, res:', res);
			mb.close();
			return true;
		} catch (ex) {
			logger.error('Error updating media item as deleted, error:', ex);
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}
	}

	async onUpload(newAsset) {
		if (!newAsset.file) {
			throw new Error('got newAsset upload req with no file');
		}

		let res;
		let mimeType = newAsset.file.type;

		let mb = messageBox('', 'Uploading...', [messageBox.Buttons.OK], true);
		try {
			if (mimeType.indexOf('image') >= 0) {
				res = await this.uploadImage(newAsset);
			} else if (mimeType.indexOf('video') >= 0) {
				res = await this.uploadVideo(newAsset, mb);
			} else {
				res = await this.uploadAsset(newAsset, mb);
			}

			mb.close(50);
			snackbar('File uploaded successfully');
		} catch (ex) {
			mb.setTitle('Error', false);
			mb.setBody(`Error uploading file: ${ex.message}`);
		}

		return res.mediaAsset;
	}

	onTitleChanged(mediaAsset) {
		if (!mediaAsset.id) {
			throw new Error('Got media asset with no id on title change request');
		}

		var data = {
			id: mediaAsset.id,
			name: mediaAsset.title,
			isExperience: this.props.isExperience
		};

		return api.mediaAsset.updateMediaAsset(data).send();
	}

	async loadMediaAssets(count, skip, q) {
		q = `${PUBLIC_STATUS_FILTER} ${q}`;
		logger.trace(`Fetching media assets, count: ${count}, skip: ${skip}, q: ${q}`);
		let res = await api.mediaAsset.getMediaAssets({ count, skip, q, isExperience: this.props.isExperience }).send();
		return res.mediaAssets;
	}

	// #endregion

	render() {
		let { maxFileSize } = this.props;
		if (!maxFileSize) {
			logger.error('`maxFileSize` prop is mandatory');
			return <div>Invalid props</div>;
		}

		return (
			<AssetPickerView
				mode={this.props.mode}
				allowSelect={this.props.allowSelect}
				allowDelete={this.props.allowDelete}
				maxFileSize={maxFileSize}
				assetTypes={this.props.assetTypes}
				onUpload={this.onUpload.bind(this)}
				loadData={this.loadMediaAssets.bind(this)}
				onDelete={this.onDelete.bind(this)}
				onTitleChanged={this.onTitleChanged.bind(this)}
				onPickAsset={this.props.onPickAsset}
			/>
		);
	}
}

MediaAssetController.propTypes = {
	mode: PropTypes.string,
	allowSelect: PropTypes.bool,
	allowDelete: PropTypes.bool,
	isExperience: PropTypes.bool,
	maxFileSize: PropTypes.number.isRequired,
	assetTypes: PropTypes.arrayOf(PropTypes.string),
	onPickAsset: PropTypes.func,
};

function nop() { }
MediaAssetController.defaultProps = {
	allowDelete: false,
	mode: CONSTANTS.AssetViewModes.Gallery,
	isExperience: false,
	assetTypes: Object.values(CONSTANTS.AssetTypes),
	onPickAsset: nop,
};

export default MediaAssetController;
