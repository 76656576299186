import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from 'ui-core';
import { logger, api } from 'client-services';
import { makeStyles } from 'tss-react/mui';

const useClasses = makeStyles()(theme => ({
	root: {
		minWidth: 100
	}
}));

function TagsValueEditor(props) {
	let { field, value, onChange } = props;
	let { classes } = useClasses();

	if (!Array.isArray(value)) {
		value = value.split(',');
	}

	let searchTag = useCallback(async(category, search) => {
		if (search.length < 2) { return []; }
		logger.trace('Searching for tags, category: `%s`, search: `%s`', category, search);
		try {
			var res = await api.experience.searchTags({ category, name: search }).send();
			logger.trace('Got search result for tags, category: `%s`, search `%s`, result:', category, search, res.tags);
			return res.tags.map(t => t.name);
		} catch (err) {
			logger.error('Error searching tags, category: `%s`, search `%s`, error:', category, search, err);
			return [];
		}
	}, []);

	return (
		<div className="rule-value">
			<Autocomplete
				className={classes.root}
				fullWidth={false}
				value={value}
				label={''}
				multiple={true}
				selectOnSpace={field === 'artists' ? false : true}
				onSearch={s => searchTag(field, s)}
				onChange={onChange}
			/>
		</div>
	);
}

TagsValueEditor.propTypes = {
	field: PropTypes.string.isRequired,
	value: PropTypes.any,
	onChange: PropTypes.func.isRequired
};

export default TagsValueEditor;

