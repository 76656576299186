.svg-image {
	width: 100%;
	height: 100%;
}

.svg-image .svg-wrap {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.svg-image .svg-wrap > svg {
	width: 100%;
	height: 100%;
}

@keyframes selected-dash {
	to {
		stroke-dashoffset: 1000;
	}
}

.svg-image .selected.flashing {
	animation: selected-dash 90s linear infinite;
	stroke-dasharray: 3, 3;
	stroke-width: 2px;
	stroke: black;
}
