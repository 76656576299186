import React from 'react';
import PropTypes from 'prop-types';
import { MaterialIcon } from 'ui-core';
import { colors } from 'client-services';
import clsx from 'clsx';

import './palette-picker.css';

class PalettePicker extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			palette: this.prepareColorsArray(this.props.palette),
			color: props.color,
			hue: props.hue,
			hover: '#000000',
			dropperActive: false
		};

		this.root = null;
		this.sliderElem = null;
		this.hueElem = null;
		this.colorsCanvasElem = null;

		this.onStartHueSlide = this.onStartHueSlide.bind(this);
		this.onHueMove = this.onHueMove.bind(this);
		this.onHueRelease = this.onHueRelease.bind(this);
		this.onCanvasClick = this.onCanvasClick.bind(this);
		this.onCanvasHover = this.onCanvasHover.bind(this);
		this.onToggleDropper = this.onToggleDropper.bind(this);
		this.onSampleColor = this.onSampleColor.bind(this);
	}

	prepareColorsArray(hexArr) {
		if (!hexArr || hexArr.length < 1) {return {};}
		var pal = hexArr.map(hex => { return {hex, hsl: colors.hex2hsl(hex)};});

		// group colors by "h"
		var merPal = {};
		pal.forEach(c => {
			c.h = parseInt(c.hsl.h);
			c.s = parseInt(c.hsl.s);
			c.l = parseInt(c.hsl.l);
			c.hsl = `hsl(${c.h}, ${c.s}%, ${c.l}%)`;
			c.lab = colors.rgb2lab(colors.hex2rgb(c.hex));
			var h = Math.floor(c.h / 10);
			merPal[h] = merPal[h] || [];
			merPal[h].push(c);
		});

		// sort each group
		for (var h in merPal) {
			pal = merPal[h].sort((c1, c2) => c1.l - c2.l);
			merPal[h] = [];
			while (pal.length > 0) {
				var c = pal.shift();
				merPal[h].push(c);
				if (pal.length < 1) {break;}
				var bidx;
				var lde = Number.MAX_VALUE;
				for (var i = 0; i < pal.length; i++) {
					var de = colors.de94(c.lab, pal[i].lab);
					if (de < lde) {
						lde = de;
						bidx = i;
					}
				}

				c = pal.splice(bidx, 1);
				pal.unshift(c[0]);
			}
		}

		return merPal;
	}

	onStartHueSlide(e) {
		this.handleHueMouseChange(e);
		document.addEventListener('mousemove', this.onHueMove);
		document.addEventListener('mouseup', this.onHueRelease);
		e.preventDefault();
		e.stopPropagation();
	}

	onHueMove(e) {
		this.handleHueMouseChange(e);
		e.preventDefault();
		e.stopPropagation();
	}

	onHueRelease() {
		document.removeEventListener('mousemove', this.onHueMove);
		document.removeEventListener('mouseup', this.onHueRelease);
	}

	onCanvasHover(e) {
		var canvas = this.colorsCanvasElem;
		var rect = canvas.getBoundingClientRect();
		var x = e.clientX - rect.left;
		var y = e.clientY - rect.top;
		var ctx = canvas.getContext('2d');
		var data = ctx.getImageData(x, y, 1, 1).data;
		var r = data[0];
		var g = data[1];
		var b = data[2];
		var hover = `#${r < 16 ? '0' : ''}${r.toString(16)}${g < 16 ? '0' : ''}${g.toString(16)}${b < 16 ? '0' : ''}${b.toString(16)}`;
		this.setState({hover});
	}

	onCanvasClick(e) {
		var canvas = this.colorsCanvasElem;
		var rect = canvas.getBoundingClientRect();
		var x = e.clientX - rect.left;
		var y = e.clientY - rect.top;
		var ctx = canvas.getContext('2d');
		var data = ctx.getImageData(x, y, 1, 1).data;
		var r = data[0];
		var g = data[1];
		var b = data[2];
		var a = data[3];
		if (a < 255) {return;}
		var color = `#${r < 16 ? '0' : ''}${r.toString(16)}${g < 16 ? '0' : ''}${g.toString(16)}${b < 16 ? '0' : ''}${b.toString(16)}`.toUpperCase();
		this.props.onChangeComplete(color, true);
	}

	onToggleDropper() {
		this.setState({dropperActive: !this.state.dropperActive}, () => {
			if (this.state.dropperActive) {
				document.body.style.cursor = 'crosshair';
				document.addEventListener('click', this.onSampleColor, true);
			}
		});
	}

	onSampleColor(e) {
		e.stopPropagation();
		e.preventDefault();

		var color;
		var elem = document.elementFromPoint(e.clientX, e.clientY);
		if (elem) {
			var style = window.getComputedStyle(elem);
			var fill = colors.css2hex(style.fill);
			var bgcolor = colors.css2hex(style.backgroundColor);
			if (fill && fill !== '#000000') {
				color = fill;
			} else if (bgcolor && bgcolor !== '#000000') {
				color = bgcolor;
			}
		}

		document.body.style.cursor = 'default';
		document.removeEventListener('click', this.onSampleColor, true);
		this.setState({dropperActive: false}, () => {
			if (color) {
				this.props.onChangeComplete(color, false);
			}
		});
	}

	handleHueMouseChange(e) {
		// left should be between 0 and the hue width
		var left = (e.clientX - this.hueElem.getBoundingClientRect().left);
		left = Math.max(0, left);
		left = Math.min(this.hueElem.offsetWidth, left);

		// Adjust the slider position so it wont exceeds and be at the middle of the cursor
		var posLeft = Math.max(0, left - this.sliderElem.offsetWidth / 2);
		posLeft = Math.min(this.hueElem.offsetWidth - this.sliderElem.offsetWidth, posLeft);
		this.sliderElem.style.left = posLeft + 'px';

		var h = Math.floor(left / this.hueElem.offsetWidth * 359);
		this.drawHueColor(h);
		this.props.onHueChange(h);
	}

	drawHueColor(h, highlightHex) {
		var colors = this.state.palette[Math.floor(h / 10)];
		if (!colors || colors.length < 1) {return;}

		var canvas = this.colorsCanvasElem;
		var ctx = canvas.getContext('2d');
		var count = Math.ceil(Math.sqrt(colors.length));
		var cubeSize = Math.floor(canvas.offsetWidth / count);

		ctx.clearRect(0, 0, canvas.offsetWidth, canvas.offsetHeight);
		ctx.fillStyle = 'rgba(0, 0, 0, 0)';
		ctx.fillRect(0, 0, canvas.offsetWidth, canvas.offsetHeight);
		var ccx, ccy, ccw;
		for (var r = 0; r < count; ++r) {
			for (var c = 0; c < count; c++) {
				var y = r * cubeSize;
				var x = c * cubeSize;
				var idx = r * count + c;
				if (idx < colors.length) {
					var w = (c === count - 1) ? (canvas.offsetWidth - c * cubeSize) : cubeSize;
					ctx.fillStyle = colors[idx].hex;
					ctx.fillRect(x, y, w, cubeSize);
					if (this.props.color === colors[idx].hex || highlightHex === colors[idx].hex) {
						ccx = x;
						ccy = y;
						ccw = w;
					}
				}
			}
		}

		if (ccw) {
			ctx.strokeRect(ccx, ccy, ccw, cubeSize);
		}
	}

	slideToHue(hex) {
		var h;
		if (typeof hex === 'string' && hex.length === 7) {
			var hsl = colors.hex2hsl(hex);
			h = Math.floor(hsl.h);
		} else if (typeof hex === 'number' && hex >= 0 && hex <= 359) {
			h = Math.floor(hex);
		} else {
			return;
		}

		var left = (h / 359) * this.hueElem.offsetWidth;
		left = Math.min(this.hueElem.offsetWidth - this.sliderElem.offsetWidth, left);
		left = Math.max(this.sliderElem.offsetWidth / 2, left);
		this.sliderElem.style.left = left + 'px';
		this.drawHueColor(h);
	}

	componentDidMount() {
		var size = this.root.querySelector('.colors').offsetWidth;
		this.colorsCanvasElem.width = size;
		this.colorsCanvasElem.height = size;
		this.slideToHue(this.props.color || this.props.hue);
	}

	componentDidUpdate() {
		if (this.props.color !== this.state.color || this.props.hue !== this.state.hue) {
			this.setState({color: this.props.color, hue: this.props.hue}, () => this.slideToHue(this.props.color || this.props.hue));
		}
	}

	renderRGB(hex) {
		if (!this.props.showRGB) {return;}
		var rgb = colors.hex2rgb(hex);
		return (
			<div className="rgb-data">
				<div>
					<div>{hex}</div>
					<div>HEX</div>
				</div>
				<div>
					<div>{rgb.r}</div>
					<div>R</div>
				</div>
				<div>
					<div>{rgb.g}</div>
					<div>G</div>
				</div>
				<div>
					<div>{rgb.b}</div>
					<div>B</div>
				</div>
			</div>
		);
	}

	renderSwatches(colors) {
		if (colors.length < 1) {return null;}
		var elems = colors.map(c => <div key={c} className="swatch" style={{backgroundColor: c}} onClick={() => this.props.onChangeComplete(c, true)} />);
		if (this.props.showEmptyColorSwatch) {
			elems.push(<div key="empty" className="swatch empty" onClick={() => this.props.onChangeComplete('none', true)}/>);
		}

		return (
			<div className="swatches-wrap">
				{elems}
			</div>
		);
	}

	render() {
		return (
			<div className="palette-picker" ref={e => {this.root = e;}}>
				<div className="colors">
					<canvas ref={e => {this.colorsCanvasElem = e;}} onClick={this.onCanvasClick} onMouseMove={this.onCanvasHover} />
				</div>
				<div className="hue-wrap">
					<div className="hue" ref={e => {this.hueElem = e;}} onMouseDown={this.onStartHueSlide}>
						<div className="slider" ref={e => {this.sliderElem = e;}}/>
					</div>
					<div className="preview">
						<div style={{backgroundColor: this.state.hover}} title={this.state.hover} />
						<div style={{backgroundColor: this.props.color}} title={this.props.color} />
					</div>
					<div className={clsx('eyedropper', {active: this.state.dropperActive})}>
						<MaterialIcon name="colorize" onClick={this.onToggleDropper} />
					</div>
				</div>
				{this.renderRGB(this.props.color)}
				{this.renderSwatches(this.props.swatches)}
			</div>
		);
	}
}

PalettePicker.propTypes = {
	color: PropTypes.string,
	hue: PropTypes.number,
	palette: PropTypes.arrayOf(PropTypes.string),
	swatches: PropTypes.arrayOf(PropTypes.string),
	showRGB: PropTypes.bool,
	showEyedropper: PropTypes.bool,
	showEmptyColorSwatch: PropTypes.bool,
	onHueChange: PropTypes.func,
	onChangeComplete: PropTypes.func
};

function nop() {}
PalettePicker.defaultProps = {
	swatches: [],
	hue: 0,
	color: '#000000',
	showEyedropper: true,
	onHueChange: nop,
	onChangeComplete: nop
};

export default PalettePicker;
