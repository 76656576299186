import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {messageBox} from 'ui-core';
import { BasePage } from 'app-center-common';
import SpacePost from '../space-post/space-post.jsx';
import CommentsList from '../comments-list.jsx';
import {api, logger} from 'client-services';

import './single-post-page.css';

class SinglePostPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			spacePost: null
		};

		this.load = this.load.bind(this);
		this.onReply = this.onReply.bind(this);
		this.onDelete = this.onDelete.bind(this);
	}

	async onReply(spacePost) {
		if (spacePost.parent) {
			messageBox('Cannot add reply to a comment');
			return;
		}

		let msg = <div>Reply to user {spacePost.username}</div>;
		let msgBoxRes = await messageBox.prompt(msg, 'Reply', [messageBox.Buttons.Cancel, 'reply']);
		if (msgBoxRes.result !== 'reply') {return;}
		let message = msgBoxRes.values['Reply'];

		if (message.length === 0) {
			messageBox('Please add comment');
			return;
		}

		let params = {
			id: spacePost.id,
			message
		};

		logger.trace('Adding new comment to space post. params: ', params);
		let comment = await api.spaces.commentAsModerator(params).send();
		logger.trace('New saved comment: ', comment);

		this.setState({ spacePost: null }, () => {this.load();});
	}

	async onDelete(spacePost) {
		let type = spacePost.parent ? 'comment' : 'post';
		let msgBoxRes = await messageBox(`Delete ${type} ?`, spacePost.message, [messageBox.Buttons.Cancel, 'delete']).promise;
		if (msgBoxRes !== 'delete') { return; }

		logger.trace('Deleting spacePost with id:', spacePost.id);

		let params = {id: spacePost.id};
		try {
			if (spacePost.parent) {
				await api.spaces.deleteComment(params).send();
			} else {
				await api.spaces.deleteSpacePost(params).send();
			}
		} catch (ex) {
			messageBox('Error', ex.message);
			return;
		}

		// If this was the post user should close the tab.
		if (!spacePost.parent) {
			this.setState({ spacePost: null });
			messageBox('Post Deleted', 'Please close this tab');
			return;
		}

		// Find and remove the comment
		let comments = this.state.spacePost.comments;
		let idx = comments.findIndex(c => c.id === spacePost.id);
		if (idx >= 0) {
			comments.splice(idx, 1);
		}

		this.setState({ spacePost: Object.assign({}, this.state.spacePost) });
	}

	async load() {
		let id = this.props.params.id;
		if (!id) {
			return;
		}

		logger.trace('Fetching spacePost %s with all its comments.', id);
		try {
			let spacePost = (await api.spaces.getFullSpacePostsById({ id }).send());
			logger.trace('Got spacePost:', spacePost);

			this.setState({ spacePost });
		} catch (ex) {
			logger.error('Error fetching the spacePost:', ex);
			messageBox('Error fetching the spacePost', ex.message);
		}
	}

	componentDidMount() {
		this.load();
	}

	componentDidUpdate(prevProps) {
		if (this.props.params.id === prevProps.params.id) {
			return;
		}

		this.load();
	}

	render() {
		let { spacePost } = this.state;
		if (!spacePost) { return <div>Loading...</div>; }

		return (
			<BasePage title="Space Post" toolbarOpen={true}>
				<SpacePost
					spacePost={spacePost}
					commentsLink={false}
					onReply={this.onReply}
					onDelete={this.onDelete}
				/>
				<CommentsList
					comments={spacePost.comments || []}
					onDelete={this.onDelete}
				/>
			</BasePage>
		);
	}
}

SinglePostPage.propTypes = {
	params: PropTypes.object
};

export default function WrappedSinglePostPage(props) {
	let params = useParams();
	return <SinglePostPage params={params} {...props} />;
}
