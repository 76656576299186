import {default as ERRORS, Severity} from '../audit-errors.js';

// Validates the the svg file contains the Cupixel Illustrator Script stamp
async function run(data, config) {
	validateInput(data);
	var errors = [];
	var res = {name: run.Rule, errors};

	var severity = config.severities[ERRORS.illustrator.name];

	if (severity === Severity.Ignore) {
		return res;
	}

	// todo: validate against the required version
	var pass = /Cupixel Illustrator Script v[0-9]+/.test(data.svg);
	if (!pass) {
		errors.push(ERRORS.illustrator(severity));
	}

	return res;
}

run.Rule = 'Illustrator Script';

function validateInput(data) {
	if (typeof data.svg === 'undefined') {
		throw new Error('No `svg` provided');
	}
}

export default run;
