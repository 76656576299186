import CONSTANTS from '../../lib/constants.js';

/** @type {import("@rjsf/utils").UiSchema} */
var schema = {
	'ui:order': ['id', 'name', 'type', 'feed', 'experienceFeed', 'experience', 'bannerUrl', 'videoUrl', 'screenName', 'status', 'allowDismiss', 'uiElement'],
	id: {
		'ui:disabled': true
	},
	name: {
		'ui:placeholder': 'Marvel Heroes'
	},
	feed: {
		'ui:field': 'singleEntityPicker',
		'ui:options': {
			picker: 'FeedPicker'
		},
		'ui:description': 'The id of the feed to open'
	},
	experienceFeed: {
		'ui:field': 'singleEntityPicker',
		'ui:options': {
			picker: 'ExperienceFeedPicker'
		},
		'ui:description': 'The id of the experiences list to open'
	},
	experience: {
		'ui:field': 'singleEntityPicker',
		'ui:options': {
			picker: 'ExperiencePicker'
		},
		'ui:description': 'The id of the experience to open'
	},
	videoUrl: {
		'ui:field': 'singleMediaAsset',
		'ui:mediaAsset': {
			assetType: CONSTANTS.AssetTypes.Video,
			resultProp: 'url',
			maxFileSize: 1000 * 1024 * 1024 // 1GB
		},
	},
	uiElement: {
		'ui:field': 'uiElement'
	}
};

export default schema;