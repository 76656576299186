import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { Card } from 'ui-core';

function ExperienceFormRenderer(props) {
	var { title, description, properties, required, registry, uiSchema, idSchema } = props;

	var seoFields = properties.filter(p => uiSchema[p.name] && uiSchema[p.name]['ui:seo']);
	var restFields = properties.filter(p => !(uiSchema[p.name] && uiSchema[p.name]['ui:seo']));
	let TitleField = registry.templates.TitleFieldTemplate;
	let DescriptionField = registry.templates.DescriptionFieldTemplate;

	return (
		<>
			{(uiSchema['ui:title'] || title) && (
				<TitleField
					id={`${idSchema.$id}-title`}
					title={title}
					required={required}
				/>
			)}
			{description && (
				<DescriptionField
					id={`${idSchema.$id}-description`}
					description={description}
				/>
			)}
			<Grid container={true} spacing={2} style={{ marginTop: '10px' }}>
				{restFields.map((element, index) =>
					// Remove the <Grid> if the inner element is hidden as the <Grid>
					// itself would otherwise still take up space.
					element.hidden ? (
						element.content
					) : (
						<Grid
							item={true}
							xs={12}
							key={index}
							style={{ marginBottom: '10px' }}>
							{element.content}
						</Grid>
					)
				)}
				<Grid item={true} xs={12}>
					<TitleField title="SEO" />
					<Card expanded={true} unmountOnExit={false} title="Metadata for search" headerStyle={{ backgroundColor: '#efefef' }}>
						{seoFields.map((element, index) =>
							// Remove the <Grid> if the inner element is hidden as the <Grid>
							// itself would otherwise still take up space.
							element.hidden ? (
								element.content
							) : (
								<Grid
									item={true}
									xs={12}
									key={index}
									style={{ marginBottom: '20px', marginTop: index < 1 ? 20 : 0 }}>
									{element.content}
								</Grid>
							)
						)}
					</Card>
				</Grid>
			</Grid>


			{/* <TitleField title={title} /> */}
			{/* <Card expanded={false} unmountOnExit={false} title="SEO" headerStyle={{ backgroundColor: '#efefef' }}> */}
			{/* <>
					{seoFields}
				</> */}
			{/* </Card> */}
		</>
	);
}

ExperienceFormRenderer.propTypes = {
	title: PropTypes.string,
	properties: PropTypes.array,
	uiSchema: PropTypes.object,
	idSchema: PropTypes.shape({$id: PropTypes.string}),
	description: PropTypes.string,
	required: PropTypes.bool,
	registry: PropTypes.shape({
		templates: PropTypes.object.isRequired,
	}).isRequired,
};

export default ExperienceFormRenderer;
