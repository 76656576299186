.exp-gallery {
	.img-overlay {
		background-color: rgba(0,0,0,0.8);
		position: absolute;
		box-sizing: border-box;
		bottom: 0;
		width: 100%;
		color: white;
		padding: 5px;
		font-size: 0.8em;
		overflow: auto;
		pointer-events: visible;
		user-select: initial;

		.title {
			font-size: 1.5em;

			.status-icon {
				vertical-align: middle;
				margin-left: 5px;
			}

			a {
				text-decoration: none;
				color: inherit;
			}
		}

		.chip {
			display: inline-block;
			background-color: #fff;
			color: black;
			padding: 2px 2px;
			border-radius: 2px;
			margin: 0 5px 5px 0;

			&.medium {
				background-color: #ffe5a7;
			}
		}

		.tools {
			display: inline-block;
			margin-bottom: 2px;

			&:hover {
				cursor: pointer;
			}

			& > i {
				font-size: 18px;
				margin-right: 10px;
			}

			& > i:hover {
				color: yellow;
			}

			& > i[data-origin=""] {
				display: none;
			}

			& > i[data-oper="Delete"] {
				color: #ff3636;
			}
		}
	}
}
