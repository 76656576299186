.layers-container {
    width: 100%;
}

.layers-container .layers-wrap {
	display: flex;
	min-height: 200px;
}

.layers-container .list {
	user-select: none;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.layers-container .dragged {
	background-color: rgb(37, 37, 197);
}

.newLayerElement {
	min-height: 255px;
}