.gallery-grid {
	position: relative;
}

.gallery-grid .img-overlay {
	background-color: rgba(0,0,0,0.8);
	position: absolute;
	box-sizing: border-box;
	bottom: 0;
	width: 100%;
	color: white;
	padding: 5px;
	font-size: 0.8em;
	overflow: auto;
	pointer-events: visible;
	user-select: initial;
}

.gallery-grid .img-overlay .title {
	font-size: 1.5em;
}

.gallery-grid .img-overlay .title .lod {
	font-size: 0.6em;
	margin-left: 5px;
}

.gallery-grid .img-overlay .title a {
	text-decoration: none;
	color: inherit;
}

.gallery-grid .img-overlay .chip {
	display: inline-block;
	background-color: #fff;
	color: black;
	padding: 2px 2px;
	border-radius: 2px;
	margin: 0 5px 5px 0;
}

.gallery-grid .img-overlay .chip.img-group {
	background-color: #8e8e8e;
	color: white;
}

.gallery-grid .img-overlay .chip.mngt {
	background-color: #ff5722;
	color: white;
}

.gallery-grid .img-overlay .img-tools {
	display: inline-block;
	margin-top: 10px;
}

.gallery-grid .img-overlay .img-tools:hover {
	cursor: pointer;
}

.gallery-grid .img-overlay .img-tools > i {
	font-size: 18px;
	margin-right: 10px;
}

.gallery-grid .img-overlay .img-tools > i:hover {
	color: yellow;
}

.gallery-grid .img-overlay .img-tools > i[data-origin=""] {
	display: none;
}

.gallery-grid .img-overlay .img-tools > i[data-oper="Delete"] {
	color: #ff3636;
}
