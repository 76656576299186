import React from 'react';
import PropTypes from 'prop-types';
import SpacePost from '../space-post/space-post.jsx';
import clsx from 'clsx';

import './space-posts-list.css';

class SpacePostsList extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		let { spacePosts, asCommentsList, showImg, parentLink, commentsLink } = this.props;

		return (
			<div className={clsx('space-posts-list', { comment: !!asCommentsList })}>
				{spacePosts.map(spacePost => (
					<SpacePost
						key={spacePost.id}
						className={clsx('post', { comment: !!asCommentsList })}
						spacePost={spacePost}
						showImg={showImg}
						parentLink={parentLink}
						commentsLink={commentsLink}
						onReply={this.props.onReply}
						onDelete={this.props.onDelete}
						onFeaturedChanged={this.props.onFeaturedChanged}
						onAuthorClick={this.props.onAuthorClick}
						onSpaceClick={this.props.onSpaceClick}
					/>
				))}
			</div>
		);
	}
}

SpacePostsList.propTypes = {
	spacePosts: PropTypes.array,
	asCommentsList: PropTypes.bool,
	showImg: PropTypes.bool,
	parentLink: PropTypes.bool,
	commentsLink: PropTypes.bool,
	onReply: PropTypes.func,
	onFeaturedChanged: PropTypes.func,
	onAuthorClick: PropTypes.func,
	onSpaceClick: PropTypes.func,
	onDelete: PropTypes.func
};

export default SpacePostsList;
