import React from 'react';
import { Chip } from '@mui/material';
import {GridFormatters} from 'ui-core';

var ExperienceFeedGridHelper = {
};

ExperienceFeedGridHelper.getColumns = function getColumns() {
	return [
		{ key: 'id@100', name: 'Id', formatter: GridFormatters.link('/experienceFeed/%s'), },
		'name@300',
		{ key: 'experiences', formatter: experiencesRenderer },
		{ key: 'forEvent', formatter: GridFormatters.checkbox(true) },
		{ key: 'tags', formatter: tagsRenderer },
	];
};

function experiencesRenderer({ row }) {
	let title = '';
	if (row.query) {
		title = 'Dynamic Feed';
	} else if (Array.isArray(row.experiences)) {
		title = row.experiences.length.toString();
	}

	return  <span>{title}</span>;
}

function tagsRenderer({ row }) {
	let tags = Array.isArray(row?.tags) ? row.tags : [];
	return (
		<div style={{display: 'flex', flexDirection: 'row'}}>
			{tags.map(t => <Chip key={t} label={t} />)}
		</div>
	);
}

export default ExperienceFeedGridHelper;
