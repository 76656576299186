import React from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from '../../../../lib/constants.js';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import MediaUIElement from './media-ui-element.jsx';
import DefaultUIElementParams from './default-ui-element-params.jsx';

const useClasses = makeStyles()(theme => ({
	typesRadioGroup: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		'& > span': {
			marginRight: 10
		}
	}
}));

function UIElementField(props) {
	let { classes } = useClasses();

	var { formData, registry } = props;
	var TitleField = registry.templates.TitleFieldTemplate;

	formData = formData || {};
	var { type } = formData;
	if (!type) {
		throw new Error('UIElement has no `type`');
	}

	function onTypeChange(e) {
		let type = e.target.value;
		// we reset all ui-element fields when changing type
		props.onChange({ type });
	}

	let renderMediaElement = (type === CONSTANTS.FeedItemUIElementType.FullCardImage || type === CONSTANTS.FeedItemUIElementType.FullCardVideo);
	let renderDefaultParams = (type === CONSTANTS.FeedItemUIElementType.Slideshow || type === CONSTANTS.FeedItemUIElementType.FeaturedCard);
	return (
		<div>
			<TitleField title="UI Element" />
			<Typography variant="caption" color="textSecondary">Experience page top element</Typography>
			<RadioGroup className={classes.typesRadioGroup} value={type} onChange={onTypeChange}>
				<span>Element Type:</span>
				<FormControlLabel value={CONSTANTS.FeedItemUIElementType.None} control={<Radio />} label="None" />
				<FormControlLabel value={CONSTANTS.FeedItemUIElementType.FullCardImage} control={<Radio />} label="Image" />
				<FormControlLabel value={CONSTANTS.FeedItemUIElementType.FullCardVideo} control={<Radio />} label="Video" />
				<FormControlLabel value={CONSTANTS.FeedItemUIElementType.Strip} control={<Radio />} label="Strip" />
				<FormControlLabel value={CONSTANTS.FeedItemUIElementType.Slideshow} control={<Radio />} label="Slideshow" />
				<FormControlLabel value={CONSTANTS.FeedItemUIElementType.FeaturedCard} control={<Radio />} label="Featured Card" />
			</RadioGroup>
			{renderMediaElement &&
				<MediaUIElement {...props} />
			}
			{renderDefaultParams &&
				<DefaultUIElementParams {...props} />
			}
		</div>
	);
}

UIElementField.propTypes = {
	schema: PropTypes.object.isRequired,
	uiSchema: PropTypes.object.isRequired,
	registry: PropTypes.shape({
		templates: PropTypes.object.isRequired
	}).isRequired,
	formData: PropTypes.object,
	onChange: PropTypes.func
};

export default UIElementField;
