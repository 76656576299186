import {default as ERRORS, Severity} from '../audit-errors.js';
import {utils} from 'client-services';

// Validates image ratio per kit definition
async function run(data, config) {
	validateInput(data);
	var errors = [];

	var div = document.createElement('div');
	div.setAttribute('style', 'position:absolute; visibility: hidden; width: 0; height: 0');
	div.innerHTML = data.svg;

	var svgElem = div.querySelector('svg');
	if (!svgElem) {
		return {pass: false, error: 'svg text is not an svg element'};
	}

	document.body.appendChild(div);

	var viewBox = svgElem.viewBox.baseVal;

	var severity = config.severities[ERRORS.viewBoxX.name];
	if (severity !== Severity.Ignore && viewBox.x !== 0) {
		errors.push(ERRORS.viewBoxX(severity).formatMessage('0'));
	}

	severity = config.severities[ERRORS.viewBoxY.name];
	if (severity !== Severity.Ignore && viewBox.y !== 0) {
		errors.push(ERRORS.viewBoxY(severity).formatMessage('0'));
	}

	// bbox should match viewBox
	severity = config.severities[ERRORS.boundingBoxWidth.name];
	var bbox = svgElem.getBBox();
	if (severity !== Severity.Ignore && Math.abs(bbox.width - viewBox.width) > 1) {
		errors.push(ERRORS.boundingBoxWidth(severity));
	}

	severity = config.severities[ERRORS.boundingBoxHeight.name];
	if (severity !== Severity.Ignore && Math.abs(bbox.height - viewBox.height) > 1) {
		errors.push(ERRORS.boundingBoxHeight(severity));
	}

	// test ratio
	severity = config.severities[ERRORS.imageRatio.name];
	if (severity !== Severity.Ignore) {
		var longSide = Math.max(viewBox.width, viewBox.height);
		var shortSide = Math.min(viewBox.width, viewBox.height);
		var ratio = longSide / shortSide;

		var requiredRatio = Math.max(...data.kit.cubes) / Math.min(...data.kit.cubes);
		if (Math.abs(ratio - requiredRatio) > 0.01) {
			errors.push(ERRORS.imageRatio(severity).formatMessage(utils.precision(requiredRatio, 2), utils.precision(ratio, 2)));
		}
	}

	div.remove();
	return {name: run.Rule, errors};
}

run.Rule = 'Image Ratio';

function validateInput(data) {
	if (typeof data.svg === 'undefined') {
		throw new Error('No `svg` provided');
	}

	if (!data.kit || !Array.isArray(data.kit.cubes) || data.kit.cubes.length !== 2) {
		throw new Error('No `kit.cubes` array provided');
	}
}

export default run;
