import {logger, config, http, storage, colors} from 'client-services';
import defer from 'defer-promise';

var PALETTE_KEY = '__CPXBO_PALETTE__';
var SOLIDS_KEY = '__CPXBO_SOLIDS__';

const KIT = {
	units: 'ml',
	paintPerCanvas: 5,
	paintPerContainer: 5,
	spoons: {
		xs: {size: 0.625},
		s: {size: 1.25},
		m: {size: 2.5},
		l: {size: 5}
	}
};

class PaletteService {
	constructor(tubeSet) {
		this.tubeSet = tubeSet;
		this.colorSpace = config.get('services:palette:colorSpace');
		this.loaded = false;
		this.loading = false;
		this.resolveOnLoad = [];
		this.palette = null;
		this.reducedPalette = null;
		this.solids = null;
		this.allHexColors = [];
	}

	get paletteCacheKey() {
		return `${PALETTE_KEY}:${this.tubeSet}:${this.colorSpace}`;
	}

	get solidsCacheKey() {
		return `${SOLIDS_KEY}:${this.tubeSet}`;
	}

	hasLAB(color) {
		return color && color.lab && (color.lab.L !== 0 || color.lab.a !== 0 || color.lab.b !== 0);
	}

	load() {
		var deferred = defer();
		if (this.loaded) {
			deferred.resolve();
			return deferred.promise;
		}

		if (this.loading) {
			this.resolveOnLoad.push(deferred);
			return deferred.promise;
		}

		this.loading = true;
		this.resolveOnLoad.push(deferred);

		this.loadPalette().then(() => {
			this.loading = false;
			this.loaded = true;
			this.notifyLoaded();
		}).catch(e => {
			this.loading = false;
			this.notifyLoaded(e);
		});

		return deferred.promise;
	}

	loadPalette() {
		var palette = storage.local.get(this.paletteCacheKey);
		var solids = storage.local.get(this.solidsCacheKey);
		if (palette && solids) {
			try {
				this.palette = JSON.parse(palette);
				this.solids = JSON.parse(solids);
				this.allHexColors = this.palette.map(c => c.rgb);
				logger.trace('loaded palette and solids from local storage:', this.palette, this.solids);
				return Promise.resolve();
			} catch (ex) {
				logger.warn('Found palette/solids in local storage but could not parse them, will load from server');
			}
		}

		return this.loadPaletteFromServer();
	}

	clearCache() {
		storage.local.del(this.paletteCacheKey);
		storage.local.del(this.solidsCacheKey);
	}

	async loadPaletteFromServer() {

		// Fetch the palette
		var colorsServiceBaseUrl = config.get('data:colorsServiceBaseUrl');
		if (!colorsServiceBaseUrl) {
			throw new Error('colorsServiceBaseUrl is not in config file');
		}

		colorsServiceBaseUrl = '//' + colorsServiceBaseUrl + (colorsServiceBaseUrl.endsWith('/') ? '' : '/');

		var tubeSetAPI = colorsServiceBaseUrl + `tubeset/${this.tubeSet}`;
		logger.trace('fetching tubeset from:', tubeSetAPI);
		var res = await http.send('GET', tubeSetAPI);
		var tubeSetRes = res.data;
		if (!tubeSetRes || !tubeSetRes.tubeSet) {
			logger.error('Got no tube set response nor error, got:', res);
			throw new Error('Failed loading tube set');
		}

		var paletteAPI = colorsServiceBaseUrl + `tubeset/${this.tubeSet}/colors?colorSpace=${this.colorSpace}&count=100000`;
		logger.trace('Fetching palette from:', paletteAPI);
		res = await http.send('GET', paletteAPI);
		var colorsRes = res.data;
		if (!colorsRes || !Array.isArray(colorsRes.colors)) {
			logger.error('Got no colors array in response nor error, got:', res);
			throw new Error('Failed loading palette colors');
		}

		// Normalize solids
		this.solids = tubeSetRes.tubeSet.tubes;
		for (var tube in this.solids) {
			var s = this.solids[tube];
			if (!s.rgb) {continue;}
			s.color = s.rgb.toUpperCase();
			s.lab = this.hasLAB(s) ? s.lab : colors.xyz2lab(colors.rgb2xyz(colors.hex2rgb(s.color)));
			s.opaque = typeof s.opacity === 'string' && s.opacity.toUpperCase() === 'O';
		}

		// Normalize palette
		this.palette = colorsRes.colors.filter(c => !!c.rgb);
		this.palette.forEach(c => {
			c.color = c.rgb.toUpperCase();
			c.lab = this.hasLAB(c) ? c.lab : colors.xyz2lab(colors.rgb2xyz(colors.hex2rgb(c.color)));
		});

		this.palette = this.reducePalette(this.palette, 1.05);

		this.allHexColors = this.palette.map(c => c.color);

		storage.local.set(this.paletteCacheKey, JSON.stringify(this.palette));
		storage.local.set(this.solidsCacheKey, JSON.stringify(this.solids));
		logger.trace('Loaded palette from server and saved to local-storage:', this.palette, this.solids);
	}

	findBestMatch(rgb, chroma = 1, hue = 1, lightness = 1) {
		if (!this.loaded) {
			throw new Error('palette service is not loaded yet');
		}

		if (typeof rgb === 'number') {
			rgb = colors.int2rgb(rgb);
		} else if (typeof rgb === 'string') {
			rgb = colors.hex2rgb(rgb);
			if (!rgb) {
				throw new Error('Invalid rgb string');
			}
		}

		var lab = colors.rgb2lab(rgb);
		var de = Number.MAX_VALUE;
		var hex = null;
		for (var i = 0; i < this.palette.length; i++) {
			var cde = colors.de2000(lab, this.palette[i].lab, chroma, hue, lightness);
			if (cde < de) {
				de = cde;
				hex = this.palette[i].color;
				if (de === 0) {
					break;
				}
			}
		}

		return {hex, de};
	}

	getPaintableSolids() {
		return Object.keys(this.solids).filter(s => this.solids[s].opaque).map(s => this.solids[s].color);
	}

	getPaletteColors() {
		return this.allHexColors;
	}

	contains(hex) {
		if (typeof hex !== 'string') {return false;}
		return this.allHexColors.includes(hex.toUpperCase());
	}

	reducePalette(palette, jnd) {
		var result = [];
		while (palette.length > 0) {
			var removedColor = palette.shift();
			var removed = [removedColor];
			for (var i = palette.length - 1; i >= 0; i--) {
				var c = palette[i];
				var de = colors.de94(removedColor.lab, c.lab);
				if (de <= jnd) {
					removed.push(c);
					palette.splice(i, 1);
				}
			}

			removed = removed.sort((c1, c2) => c1.ratios.length - c2.ratios.length);
			var chosen = removed[0];

			result.push(chosen);
		}

		return result;
	}

	getRecipe(hex, pct) {
		if (!hex) {return null;}
		hex = hex.toUpperCase();
		var color = this.palette.find(c => c.color === hex);
		if (!color || !Array.isArray(color.ratios) || color.ratios.length < 1) {return null;}

		var recipe = {color: hex, units: KIT.units, mixture: [], containers: 1, requiredPaint: 0, actualPaint: 0};

		// Get the mixture sorted by part
		// IMPORTANT: We normalize the parts so the minimum part will be 1
		var sumParts = 0;
		var minPart = Math.min(...color.ratios.map(r => r.part));
		recipe.mixture = color.ratios.map(r => {
			var p = r.part / minPart;
			sumParts += p;
			return {part: p, tube: this.solids[r.tube], spoons: []};
		}).sort((m1, m2) => m1.part - m2.part);

		// IMPORTANT! Convert spoons to ordered array by spoon size
		var spoons = Object.entries(KIT.spoons).map(s => {s[1].id = s[0]; return s[1];}).sort((s1, s2) => s1.size - s2.size);
		if (typeof pct === 'undefined' || spoons.length < 1) {
			return recipe;
		}

		// Calculate the total paint needed and split it into containers - each container has the same amount with
		// the same mixing instructions
		var totalPaintAmount = Math.max(spoons[0].size, KIT.paintPerCanvas * pct / 100);
		recipe.requiredPaint = totalPaintAmount;
		recipe.containers = KIT.paintPerContainer ? Math.ceil(totalPaintAmount / KIT.paintPerContainer) : 1;
		totalPaintAmount /= recipe.containers;

		// calc the first color as this will be the base (remember part is normalized so the first color part is 1)
		var pSize = (recipe.mixture[0].part / sumParts) * totalPaintAmount;
		recipe.mixture[0].spoons = this.fillPaintInSpoons(pSize, spoons);
		var baseAmount = recipe.mixture[0].spoons.reduce((acc, s) => acc + s.size, 0); // sum the actual color amount

		// Now do the rest
		recipe.mixture.forEach(mix => {
			pSize = mix.part * baseAmount;
			mix.spoons = this.fillPaintInSpoons(pSize, spoons);
		});

		recipe.actualPaint = recipe.containers * (recipe.mixture.map(m => m.spoons.reduce((acc, s) => acc + s.size, 0)).reduce((acc, p) => acc + p));
		return recipe;
	}

	fillPaintInSpoons(amount, spoons) {
		// Find the spoons that fills the required amount using minimum amount of spoons
		// but also minimum paint waste
		var res = [];
		while (amount > 0) {
			// Use the largest spoon size that does NOT exceeds amount (remember spoons is ordered)
			var idx = spoons.findIndex(sp => sp.size > amount);
			idx = (idx < 0) ? spoons.length - 1 : Math.max(idx - 1, 0);
			res.push({id: spoons[idx].id, size: spoons[idx].size});
			amount -= spoons[idx].size;
		}

		return res;
	}

	notifyLoaded(err) {
		this.resolveOnLoad.forEach(d => err ? d.reject(err) : d.resolve());
	}
}

var instances = {};

export default function getInstance(colorSet) {
	if (typeof colorSet !== 'string' || !colorSet) {throw new Error('tubeSet must be a non empty string');}

	if (instances[colorSet]) {
		return instances[colorSet];
	}

	instances[colorSet] = new PaletteService(colorSet);
	return instances[colorSet];
};
