.palette-picker {
	width: 100%;
	box-sizing: border-box;
	padding: 10px;
	border: 1px solid #dedede;
	border-radius: 4px;
}

.palette-picker .hue {
	width: 100%;
	height: 24px;
	position: relative;
	box-sizing: border-box;
	background: linear-gradient(to right, rgb(255, 0, 0) 0%, rgb(255, 255, 0) 17%, rgb(0, 255, 0) 33%, rgb(0, 255, 255) 50%, rgb(0, 0, 255) 67%, rgb(255, 0, 255) 83%, rgb(255, 0, 0) 100%);
}

.palette-picker .hue .slider {
	position: absolute;
	width: 6px;
	border-radius: 1px;
	height: 22px;
	box-shadow: rgba(0, 0, 0, 0.6) 0 0 2px;
	border: 1px solid;
}

.palette-picker .colors {
	/* Checkboard  */
	background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==");
}

.palette-picker .hue-wrap {
	display: flex;
	align-items: center;
	margin-top: 10px;
}

.palette-picker .preview {
	margin-left: 10px;
	border: 1px solid #dedede;
	border-radius: 4px;
}

.palette-picker .preview > div {
	width: 24px;
	height: 24px;
}

.palette-picker .rgb-data {
	display: flex;
	margin-top: 5px;
	font-size: 0.9em;
}

.palette-picker .rgb-data > div {
	flex: 0.25;
	text-align: center;
	text-transform: uppercase;
}

.palette-picker .swatches-wrap {
	margin-top: 5px;
	border-top: 1px solid #dedede;
	display: flex;
	flex-wrap: wrap;
}

.palette-picker .swatches-wrap > .swatch {
	position: relative;
	width: 20px;
	height: 20px;
	border: 1px solid #dedede;
	border-radius: 4px;
	margin-top: 5px;
	margin-right: 5px;
	cursor: pointer;
}

.palette-picker .swatches-wrap > .swatch.empty:after {
	content: '';
	border: 1px solid red;
	position: absolute;
	top: -5px;
	bottom: -5px;
	left: 50%;
	transform: rotate(45deg);
}


.palette-picker .eyedropper {
	margin-left: 10px;
}

.palette-picker .eyedropper > i {
	font-size: 20px;
	vertical-align: middle;
	cursor: pointer;
}

.palette-picker .eyedropper.active > i {
	color: #4681EA
}
