import { defaultCombinators, groupInvalidReasons, isRuleGroup, isRuleGroupType } from 'react-querybuilder';

const OBJECT_ID_REGEX = /^[0-9a-f]{24}$/;

let queryOperators = [
	{ name: '=', label: '=' },
	{ name: '!=', label: '!=' },
	{ name: '<', label: '<' },
	{ name: '>', label: '>' },
	{ name: '<=', label: '<=' },
	{ name: '>=', label: '>=' },
	{ name: 'in', label: 'in' },
	{ name: 'notIn', label: 'not in' },
];

function basicFieldValidation(fieldDef, operator, value) {
	let allowedOps = fieldDef.operators || queryOperators;
	if (!allowedOps.some(op => op.name === operator)) {
		return { valid: false, reasons: [`invalid operator \`${operator}\``] };
	}

	// For multiple values fields where value editor is not "multiselect" the array values are comma separated
	// convert it to array
	if ((operator === 'in' || operator === 'notIn') && !Array.isArray(value)) {
		value = value.split(',');
	}

	// sometimes value is array and sometimes single value, for testing always use array so code is consistent
	let testVals = Array.isArray(value) ? value : [value];
	if (testVals.length < 1) {
		return { valid: false, reasons: ['empty value'] };
	}

	let datatype = fieldDef.datatype || 'string';
	let valid = true;
	let reasons = [];
	switch (datatype) {
		case 'number':
			valid = !(testVals.some(v => typeof v !== 'number'));
			reasons.push(`Value \`${value}\` should be number`);
			break;
		case 'boolean':
			valid = !(testVals.some(v => typeof v !== 'boolean'));
			reasons.push(`Value \`${value}\` should be boolean`);
			break;
		case 'objectId':
			valid = !(testVals.some(v => (typeof v !== 'string' || !(OBJECT_ID_REGEX.test(v)))));
			reasons.push(`Value \`${value}\` should be valid object id`);
			break;
		case 'string':
			valid = !(testVals.some(v => (typeof v !== 'string' || v.trim() === '')));
			reasons.push(`Value \`${value}\` should be non-empty string`);
			break;
		default:
			break;
	}

	return { valid, reasons: valid ? [] : reasons };
}

/**
 * Validates a query rule
 * @param {RuleType} rule - The rule to validate
 * @param {Array.<QueryField>} expQueryFields
 * @param {ValidationMap} result
 */
function validateRule(rule, expQueryFields, result) {
	let fieldDef = expQueryFields.find(f => f.name === rule.field);
	if (!fieldDef) {
		result[rule.id] = { valid: false, reasons: ['Invalid/no field selected'] };
	} else {
		// @ts-ignore
		let {valid, reasons} = fieldDef.validator(rule);
		result[rule.id] = { field: rule.field, valid, reasons };
	}
}

/**
 * Validates a query rule group
 * A default validator function from
 * source: https://github.com/react-querybuilder/react-querybuilder/blob/v7.6.0/packages/react-querybuilder/src/utils/defaultValidator.ts
 * @param {RuleGroup} rg - The rule group to validate
 * @param {Array.<QueryField>} expQueryFields
 * @param {ValidationMap} result
 */
function validateGroup(rg, expQueryFields, result) {
	const reasons = [];

	if (rg.rules.length === 0) {
		reasons.push('Rule group has no rules');
	} else if (!isRuleGroupType(rg)) {
		// Odd indexes should be valid combinators and even indexes should be rules or groups
		let invalidICs = false;
		for (let i = 0; i < rg.rules.length && !invalidICs; i++) {
			if (
				(i % 2 === 0 && typeof rg.rules[i] === 'string') ||
				(i % 2 === 1 && typeof rg.rules[i] !== 'string') ||
				(i % 2 === 1 &&
					typeof rg.rules[i] === 'string' &&
					!defaultCombinators.map(c => c.name.toString()).includes(rg.rules[i].toString()))
			) {
				invalidICs = true;
			}
		}
		if (invalidICs) {
			reasons.push(groupInvalidReasons.invalidIndependentCombinators);
		}
	}

	// Non-independent combinators should be valid, but only checked if there are multiple rules
	// since combinators don't really apply to groups with only one rule/group
	if (
		isRuleGroupType(rg) &&
		!defaultCombinators.map(c => c.name.toString()).includes(rg.combinator) &&
		rg.rules.length > 1
	) {
		reasons.push(groupInvalidReasons.invalidCombinator);
	}

	if (rg.id) {
		result[rg.id] = { valid: reasons.length < 1, reasons };
	}

	rg.rules.forEach(r => {
		if (typeof r === 'string') {
			// Validation for this case was done earlier
		} else if (isRuleGroup(r)) {
			validateGroup(r, expQueryFields, result);
		} else {
			validateRule(r, expQueryFields, result);
		}
	});
}

/**
 * Query validator
 * @param {RuleGroup} query
 * @param {Array.<QueryField>} expQueryFields
 * @returns {ValidationMap}
 */
function validateQuery(query, expQueryFields) {
	/** @type {ValidationMap} */
	const result = {};

	validateGroup(query, expQueryFields, result);

	return result;
}

export { queryOperators, basicFieldValidation, validateQuery };

/**
 * @typedef {import('react-querybuilder').Field} QueryField
 */

/**
 * @typedef {import('react-querybuilder').RuleType} RuleType
 */

/**
 * @typedef {import('react-querybuilder').RuleGroupTypeAny} RuleGroup
 */

/**
 * @typedef {Object} ValidationResult
 * @property {string} [field]
 * @property {boolean} valid
 * @property {Array.<String>} reasons
 */

/**
 * @typedef {{[key: string]: ValidationResult}} ValidationMap
 */