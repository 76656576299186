.exp-feed .feed-item {
	margin-top: 10px;
}

.exp-feed .feed-item.title-item {
	background-color: #c1c1c1;
}

.exp-feed .feed-item .faded {
	opacity: 0.4;
}

.exp-feed .feed-item .content {
    display: flex;
    flex-direction: row;
    height: 250px;
    box-sizing: border-box;
}

.exp-feed .feed-item .content .fi-status {
    display: flex;
    flex-direction: row;
	align-items: center;
}

.exp-feed .feed-item .content .fi-status .dropdown {
	margin-left: 3px;
}

.exp-feed .feed-item .content .details {
	overflow: auto;
	white-space: nowrap;
}

.exp-feed .feed-item .content .details a {
	color: inherit;
}

.exp-feed .feed-item .content .ui-element {
	overflow: auto;
	height: 100%;
    margin-left: 30px;
}

.exp-feed .feed-item .content .ui-element .fc-image {
	height: 100%;
	width: 100%;
}

.exp-feed .feed-item .content .ui-element .fc-image img {
	display: block;
	max-height: 100%;
	max-width: 100%;
}

.exp-feed .feed-item .content .ui-element .fc-video {
	height: 100%;
	width: 100%;
}

.exp-feed .feed-item .content .ui-element .fc-video video {
	display: block;
}

.exp-feed .feed-item .content .ui-element .featured {
    display: flex;
	flex-direction: column;
	border: 1px solid;
    height: 100%;
    width: 200px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    background-color: #e7e7e7;
}

.exp-feed .feed-item .content .ui-element .featured .featured-buttons {
	margin-top: 50%;
}

.exp-feed .feed-item .content .ui-element .featured .featured-buttons span {
	margin-right: 10px;
    padding: 5px;
	border: 1px solid;
    border-radius: 50px;
}
