import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from '../../../lib/constants.js';
import { EditableLabel, Video } from 'ui-core';
import { makeStyles } from 'tss-react/mui';
import {utils} from 'client-services';

// @ts-ignore
import videoPoster from '../../../../assets/video-poster.png';
// @ts-ignore
import pdfLogo from '../../../../assets/pdf-logo.svg';

const useClasses = makeStyles()(theme => ({
	assetGalleryTile: {
		userSelect: 'none'
	},
	mediaAssetWrap: {
		height: 150
	},
	mediaAsset: {
		height: '100%',
		maxWidth: '100%',
		maxHeight: '100%',
		'&.video': {
			height: '100%',
			width: '100%'
		}
	},
}));

function ImageAsset(props) {
	let { classes } = useClasses();

	return <img className={classes.mediaAsset} src={props.asset.thumbnail ? props.asset.thumbnail : props.asset.src} />;
}

ImageAsset.propTypes = {
	asset: PropTypes.object.isRequired,
};

function VideoAsset(props) {
	let { classes } = useClasses();
	// for some reason "hls.js" is not working with local blobs
	if (props.asset.src.startsWith('blob:')) {
		return (
			<video className={classes.mediaAsset} controls src={props.asset.src} />
		);
	}

	return (
		<Video
			className={classes.mediaAsset}
			controls
			preload='none'
			autoStartLoad={false}
			poster={videoPoster}
			url={props.asset.src}
		/>
	);
}

VideoAsset.propTypes = {
	asset: PropTypes.object.isRequired,
};

function PdfAsset(props) {
	let { classes } = useClasses();

	return <img className={classes.mediaAsset} src={pdfLogo} onClick= {() => utils.openWindow(props.asset.src)}/>;
}

PdfAsset.propTypes = {
	asset: PropTypes.object.isRequired,
};

function AssetGalleryTile(props) {
	let { classes } = useClasses();
	let assetDiv = useRef(null);
	let { asset, allowEdit = true, onTitleChanged } = props;

	let uiElement;
	switch (asset.type) {
		case CONSTANTS.AssetTypes.Image:
			uiElement = <ImageAsset asset={asset}/>;
			break;
		case CONSTANTS.AssetTypes.Video:
			uiElement = <VideoAsset asset={asset}/>;
			break;
		case CONSTANTS.AssetTypes.PDF:
			uiElement = <PdfAsset asset={asset}/>;
			break;
		default:
			break;
	}

	return (
		<div className={classes.assetGalleryTile}>
			<div ref={assetDiv} className={classes.mediaAssetWrap}>
				{uiElement}
			</div>
			{allowEdit &&
				<div>
					<EditableLabel
						value={asset.title}
						onChange={(name, val) => onTitleChanged?.(val, asset.id)}
					/>
				</div>
			}
		</div>
	);
}

const ASSET_VIEW_MODEL = PropTypes.shape({
	id: PropTypes.string,
	src: PropTypes.string,
	thumbnail: PropTypes.string,
	title: PropTypes.string,
	type: PropTypes.string,
	isSelected: PropTypes.bool,
});

AssetGalleryTile.propTypes = {
	asset: ASSET_VIEW_MODEL,
	allowEdit: PropTypes.bool,
	onTitleChanged: PropTypes.func,
};

export default AssetGalleryTile;
