import React from 'react';
import PropTypes from 'prop-types';
import { BasePage } from 'app-center-common';
import EventDetailsRow from './event-details-row.jsx';
import EventEditModal from './event-edit-modal.jsx';
import {messageBox, AddButtonFAB, InfiniteScroll} from 'ui-core';

import {api, logger, utils} from 'client-services';

import './events-list.css';

const FETCH_COUNT = 100;
const SEARCH_SCHEMA = {
	name: {},
	unlockCode: {},
	id: { $ref: '#/definitions/MongoObjectID' },
};


class EventsList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			search: '',
			hasMore: true,
			showEditModal: false,
			selectedEvent: undefined,
			events: [],
			total: 0
		};

		this.page = 0;

		this.loadMore = this.loadMore.bind(this);
		this.onEventAction = this.onEventAction.bind(this);
		this.onEventUpdate = this.onEventUpdate.bind(this);
		this.searchChanged = this.searchChanged.bind(this);
	}

	openEditModal() {
		this.setState({selectedEvent: undefined, showEditModal: true});
	}

	deleteEvent(id) {
		messageBox('Are you sure?', '', ['Delete', messageBox.Buttons.Cancel]).promise.then(action => {
			if (action !== 'Delete') {return null;}
			logger.trace('Deleting event:', id);
			return api.event.deleteEvent({id}).send();
		}).then(res => {
			if (!res) {return;}
			logger.trace('event `%s` deleted', id);
			var idx = this.state.events.findIndex(p => p.id === id);
			if (idx >= 0) {
				let events = [...this.state.events];
				events.splice(idx, 1);
				this.setState({events});
			}
		}).catch(err => {
			logger.error(err);
			messageBox('Server error', err.message);
		});
	}

	onEventAction(id, oper) {
		var event = this.state.events.find(p => p.id === id);
		if (!event) {
			logger.error('Got event operation with invalid id, event: `%s`, oper: `%s`', id, oper);
			return;
		}

		switch (oper) {
			case 'Edit':
				// For editing purposes owner/project should be strings
				var e = utils.extend(true, {}, event);
				this.setState({selectedEvent: e, showEditModal: true});
				break;
			case 'Delete':
				this.deleteEvent(id);
				break;
			default:
				logger.error('Got invalid event operation, event: `%s`, oper: `%s`', id, oper);
		}
	}

	onEventUpdate(data) {
		if (!data) {
			this.setState({showEditModal: false, selectedEvent: undefined});
			return;
		}

		logger.trace('Upserting event with params:', data);
		var req = data.id ? api.event.updateEvent(data) : api.event.insertEvent(data);
		var eventRes;
		req.send().then(res => {
			eventRes = res;
			logger.trace('event upsert success, result:', eventRes);
			var event = eventRes.event;

			var idx = this.state.events.findIndex(e => e.id === event.id);
			let events = [...this.state.events];
			if (idx >= 0) {
				events.splice(idx, 1, event);
			} else {
				events.splice(0, 0, event);
			}

			this.setState({showEditModal: false, selectedEvent: undefined, events});
		}).catch(err => {
			logger.error(err);
			messageBox('Server error', err.message);
		});
	}

	searchChanged(search) {
		this.setState({ search, events: [] }, () => {
			this.page = 0;
			this.loadMore();
		});
	}

	async loadMore() {
		var params = {count: FETCH_COUNT, skip: this.page * FETCH_COUNT, q: this.state.search};
		var eventsRes;
		logger.trace('Fetching events with params:', params);

		try {
			var res = await api.event.getEvents(params).send();
			eventsRes = res;
			logger.trace('Got events response:', eventsRes);

			// Must be called before setState so it will be applied when we re-render
			let total = eventsRes.total >= 0 ? eventsRes.total : this.state.total;
			var hasMore = (eventsRes.events.length === FETCH_COUNT);
			++this.page;
			this.setState({ hasMore, total, events: [...this.state.events, ...eventsRes.events] });
		} catch (ex) {
			logger.error('Error loading events, error:', ex);
		}
	}

	componentDidMount() {
		this.loadMore();
	}

	renderEvents(events) {
		return events.map(p => <EventDetailsRow key={p.id} event={p} onAction={this.onEventAction}/>);
	}

	render() {
		return (
			<BasePage
				title="Events"
				subTitle={`(${this.state.total} events)`}
				className='events-list'
				showSearch={true}
				search={this.state.search}
				searchFields={SEARCH_SCHEMA}
				onSearch={ this.searchChanged }
			>
				<InfiniteScroll scrollOn=".events-list" hasMore={this.state.hasMore} loadMore={this.loadMore}>
					<div className="events">
						{this.renderEvents(this.state.events)}
						<AddButtonFAB onClick={() => this.openEditModal()} />
						{ this.state.showEditModal && <EventEditModal event={this.state.selectedEvent} open={this.state.showEditModal} onClose={this.onEventUpdate}/> }
					</div>
				</InfiniteScroll>
			</BasePage>
		);
	}
}

EventsList.contextTypes = {
	setAppBarComponents: PropTypes.func
};

export default EventsList;
