import { compileSchema } from 'ui-core';

/** @type {import("@rjsf/utils").RJSFSchema} */
var schema = {
	title: 'Instructor',
	type: 'object',
	required: ['name', 'email'],
	properties: {
		id: { type: 'string', readOnly: true },
		name: { type: 'string' },
		email: { type: 'string', format: 'email' },
		avatar: { $ref: '#/definitions/uri' }
	}
};

schema = compileSchema(schema);

export default schema;
