import React from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from '../../lib/constants.js';
import { useParams, useSearchParams } from 'react-router-dom';
import { messageBox, compileSchema, snackbar, Toolbar, ToolbarIcon, JSONModal } from 'ui-core';
import { BasePage } from 'app-center-common';
import Form from '../form/form.jsx';
import * as Fields from './custom-fields';
import { config, api, logger, utils } from 'client-services';

import ExperienceSchema from './experience.schema.js';
import ExperienceSchemaUI from './experience.ui.schema.js';

import './experience-page.css';

const TAGS_FIELDS = ['tags', 'atmosphere', 'colors', 'medium', 'artStyle', 'artists'];
const EXP_DEEP_LINK = (config.get('services:experience:expDeepLinkTemplate') || '').replace(/\/$/, '');

class ExperiencePage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			experience: null,
			originalExperience: null,
			showJsonModal: false,
			expSummaryViewed: false
		};

		this.setupTagsFields();
		this.form = null;
		this.customFields = {
			toolsList: Fields.ToolsListField,
			expPreview: Fields.ExpPreviewField
		};

		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onToolClick = this.onToolClick.bind(this);
		this.openExpSummary = this.openExpSummary.bind(this);
	}

	// #region Methods

	setupTagsFields() {
		TAGS_FIELDS.forEach(f => {
			var searchProps = {
				autocompleteProps: {
					multiple: true,
					allowDrag: true,
					selectOnSpace: f === 'artists' ? false : true,
					onSearch: this.searchTag.bind(this, f.toLowerCase())
				}
			};

			ExperienceSchemaUI[f]['ui:options'] = searchProps;
		});
	}

	async searchTag(category, search) {
		if (search.length < 2) { return []; }
		logger.trace('Searching for tags, category: `%s`, search: `%s`', category, search);
		try {
			var res = await api.experience.searchTags({ category, name: search }).send();
			logger.trace('Got search result for tags, category: `%s`, search `%s`, result:', category, search, res.tags);
			return res.tags.map(t => t.name);
		} catch (err) {
			logger.error('Error searching tags, category: `%s`, search `%s`, error:', category, search, err);
			return [];
		}
	}

	async load() {
		var eid = this.props.params.eid;
		if (!eid) {
			return;
		}

		if (eid === 'new') {
			var experience = await this.newExperienceModel(this.props.searchParams);

			this.reloadExperience(experience);
			return;
		}

		logger.trace('Fetching experience:', eid);
		try {
			var res = await api.experience.getExperience({ id: eid }).send();
			logger.trace('Got experience:', res);
			this.reloadExperience(res.experience);
		} catch (ex) {
			logger.error('Error fetching experience:', ex);
			messageBox('Error loading experience', ex.message);
		}
	}

	async newExperienceModel(urlParams) {
		// Check if duplicate from another exp
		var expId = urlParams.get('exp');
		if (expId) {
			let res = await api.experience.getExperience({ id: expId }).send();
			if (!res.experience) { return { id: '' }; }
			res.experience.id = '';
			res.experience.status = CONSTANTS.ExperienceStatus.Draft;
			delete res.experience.publishDate;
			return res.experience;
		}

		return { id: '' };
	}

	reloadExperience(exp) {
		// In case this is a new item we will want to first unmount all the components and then re-render the new experience
		if (this.state.experience && this.state.experience.id !== exp.id) {
			this.setState({ experience: null }, () => {
				this.reloadExperience(exp);
			});

			return;
		}

		var originalExperience = utils.extend(true, {}, exp);

		// Remove the "null" video object or the duration of 0 if there is no url
		if (Array.isArray(exp.sessions)) {
			exp.sessions.forEach(s => {
				if (!s.video) { delete s.video; }
				if (!s.video?.url && !s.video?.duration) { delete s.video?.duration; }
			});
		}

		if (!exp.preview) { delete exp.preview; }

		this.setState({ experience: exp, originalExperience, expSummaryViewed: false });
	}

	async beforeSaveValidation(params) {
		let { originalExperience, expSummaryViewed } = this.state;

		if (params.status === CONSTANTS.ExperienceStatus.Public) {
			if (params.slug !== originalExperience?.slug) {
				let msg = <div>
					<div>It is not recommended to chang the Slug of a Public experience</div>
					<div>Do that only if the experience name has changed significantly</div>
					<div>Do you want continue saving?</div>
				</div>;

				let res = await messageBox('WARNING: Slug Change', msg, [messageBox.Buttons.No, messageBox.Buttons.Yes]).promise;
				if (res === messageBox.Buttons.No) {
					return false;
				}
			}

			// make sure all sessions have video (except for live)
			if (!Array.isArray(params.sessions) || params.sessions.length < 1) {
				messageBox('Error', 'Public experiences must have at least one Creation Session');
				return false;
			}

			if (!params.isLive && params.sessions.some(s => !s.video?.url)) {
				messageBox('Error', 'Non-live Public experiences must have at least one Creation Session with a video');
				return false;
			}
		}

		// When publishing experience make sure the exp summary was opened at least once
		if (originalExperience.status !== CONSTANTS.ExperienceStatus.Public &&
			params.status === CONSTANTS.ExperienceStatus.Public &&
			!params.isLive && !expSummaryViewed) {

			let res = await messageBox('WARNING: Blog Summary Validation', 'It is recommended to validate the experience summary before publishing, continue without validation?', [messageBox.Buttons.No, messageBox.Buttons.Yes]).promise;
			if (res === messageBox.Buttons.No) {
				return false;
			}
		}

		if (params.isLive && (!params.publishDate || new Date(params.publishDate).getTime() < Date.now())) {
			let msg = <div>
				<div>Experience is marked as live but publishDate is in the past</div>
				<div>Do you want continue saving?</div>
			</div>;
			let res = await messageBox('Publish Date has passed', msg, [messageBox.Buttons.No, messageBox.Buttons.Yes]).promise;
			if (res === messageBox.Buttons.No) {
				return false;
			}
		}

		if (params.status === CONSTANTS.ExperienceStatus.RC && (!params.publishDate || new Date(params.publishDate).getTime() < Date.now())) {
			let msg = <div>
				<div>Experience with RC status but publishDate is in the past</div>
				<div>Do you want continue saving?</div>
			</div>;
			let res = await messageBox('Publish Date has passed', msg, [messageBox.Buttons.No, messageBox.Buttons.Yes]).promise;
			if (res === messageBox.Buttons.No) {
				return false;
			}
		}

		// Check the "kind" prop matches the "creationTabs"
		if (!params.creationTabs.includes(CONSTANTS.CreationTabs.Artwork) &&
			params.kind === CONSTANTS.ExperienceKind.Creative) {

			let msg = <div>
				<div>This experience has no Trace tab but is marked as Creative</div>
				<div>Please consider changing the experience `kind` to `Informative` (under SEO)</div>
				<div>Do you want continue saving?</div>
			</div>;
			let res = await messageBox('No Trace tab', msg, [messageBox.Buttons.No, messageBox.Buttons.Yes]).promise;
			if (res === messageBox.Buttons.No) {
				return false;
			}
		}

		// Make sure we have tools
		if (params.kind === CONSTANTS.ExperienceKind.Creative && (!params.tools || params.tools.length < 1)) {
			let msg = <div>
				<div>This experience has no Tools but is marked as Creative</div>
				<div>Do you want continue saving?</div>
			</div>;
			let res = await messageBox('Missing Tools', msg, [messageBox.Buttons.No, messageBox.Buttons.Yes]).promise;
			if (res === messageBox.Buttons.No) {
				return false;
			}
		}

		return true;
	}

	async fetchImagesNamesForUpdate(images) {
		let origImgs = this.state.originalExperience?.images ?? [];
		images = images || [];
		if (images.length < 1 && origImgs.length < 1) {
			return;
		}

		// If the length is the same check the content
		if (images.length === origImgs.length) {
			let i = 0;
			for (i = 0; i < images.length; ++i) {
				if (!origImgs.includes(images[i])) {
					break;
				}
			}

			if (i === images.length) {
				return;
			}
		}

		if (images.length < 1) { return []; }

		// Finally fetch images names.
		let q = images.map(id => `id:${id}`).join(' ');
		let mb = messageBox('Fetching images names', '', [], true);
		try {
			let res = await api.gallery.getImages({ count: images.length, q, fields: ['id', 'name'] }).send();
			mb.close();
			return res.images.map(i => ({ id: i.id, name: i.name }));
		} catch (ex) {
			logger.error('Error fetching images names, error:', ex);
			mb.setTitle('Error', false);
			mb.setBody('Images names will not be updated, this will affect search indexing, error:' + ex.message);
		}
	}

	openDiff() {
		// @ts-ignore
		this.setState({ experience: this.form.getData(), showJsonModal: true });
	}

	cloneExp() {
		if (!this.state.experience?.id) { return; }
		var url = `/experience/new?exp=${this.state.experience.id}`;
		utils.openWindow(url);
	}

	async copyDeepLink() {
		if (!this.state.experience?.id) { return; }
		var url = `${EXP_DEEP_LINK}/${this.state.experience.id}`;
		if (await utils.copyToClipboard(url)) {
			snackbar('Deep link copied to clipboard');
		} else {
			snackbar('Error in copy to clipboard');
		}
	}

	openSpace() {
		if (!this.state.experience?.space) {
			snackbar('This experience has no space');
			return;
		}
		var url = `/space-posts?space=${this.state.experience.space}`;
		utils.openWindow(url);
	}

	async openExpSummary() {
		let experienceAssetId = this.state.experience?.sessions?.[0].video?.id;
		if (!experienceAssetId) {
			messageBox('Experience has no creation video');
			return;
		}

		let mb = messageBox('Fetching data...');
		let res;
		try {
			res = await api.mediaAsset.getExperienceAssetMetadata({ experienceAssetId, fields: ['info', 'createdAt'] }).send();
			if (!res.experienceAssetMetadata?.info?.summary) {
				mb.setTitle('Oops');
				mb.setBody('Experience has no summary. It can take several minutes since a video has been uploaded until a summary is generated.');
				return;
			}
		} catch (ex) {
			mb.setTitle('Error');
			mb.setBody(ex.message);
			return;
		}

		mb.close(10);
		this.setState({ expSummaryViewed: true }, () => {
			this.showExpSummaryModal(experienceAssetId, res.experienceAssetMetadata.info);
		});
	}

	showExpSummaryModal(experienceAssetId, expAssetMetadataInfo) {
		let summary = expAssetMetadataInfo.summary;
		let keyMoments = expAssetMetadataInfo.keyMoments || [];
		let takeaways = expAssetMetadataInfo.takeaways || [];

		let mb;

		async function onFormClosed(data) {
			mb.close(50);
			if (!data) { return; }

			let mbb = messageBox('Saving experience summary...', null, null, true);
			try {
				data.experienceAssetId = experienceAssetId;
				let res = await api.mediaAsset.updateExperienceAssetMetadata(data).send();
				if (!res) {
					throw new Error('Media asset metadata not found - weird');
				}

				mbb.close();
			} catch (ex) {
				mbb.setTitle('Error', false);
				mbb.setBody(ex.message);
			}
		}

		var schema = compileSchema({
			type: 'object',
			required: ['summary'],
			properties: {
				summary: { type: 'string' },
				keyMoments: { type: 'array', items: {type: 'string'} },
				takeaways: { type: 'array', items: {type: 'string'} }
			}
		});

		const uiSchema = {
			summary: { 'ui:widget': 'textarea', 'ui:options': { rows: 8 } }
		};

		let formData = { summary, keyMoments, takeaways };
		var body =
			<Form
				formData={formData}
				schema={schema}
				uiSchema={uiSchema}
				submitText="Update"
				cancelText="Close"
				buttonsAlign="right"
				submit={onFormClosed.bind(this)}
			/>;

		mb = messageBox('Experience Blog Summary', body, [], false, 'lg');
	}

	// #endregion Methods

	// #region EventHandlers

	onToolClick(toolbar, oper) {
		switch (oper) {
			case 'Diff':
				this.openDiff();
				break;
			case 'Clone':
				this.cloneExp();
				break;
			case 'Save':
				this.form?.submit();
				break;
			case 'DeepLink':
				this.copyDeepLink();
				break;
			case 'Space':
				this.openSpace();
				break;
			case 'Summary':
				this.openExpSummary();
				break;
		}
	}

	onChange(data) {
		if (!data) { return; }

		let upd = {};

		// We update the slug automatically only if:
		// 1) Name was changed
		// 2) Experience is not Public
		// 3) Experience had no slug
		if (data.name !== this.state.experience?.name &&
			this.state.originalExperience?.status !== CONSTANTS.ExperienceStatus.Public &&
			!this.state.originalExperience?.slug) {

			upd.slug = data.name?.toLowerCase().replace(/'/, '').replace(/[^a-z0-9]{1,}/g, '-').replace(/-$/, '') ?? '';
		}

		if (data.thumbnail && data.thumbnail !== this.state.experience?.thumbnail) {
			upd.thumbnail = data.thumbnail.replace('%TRANS%', 't_media_thumb');
			upd.thumbnailLarge = data.thumbnail.replace('%TRANS%', 't_media');
		}

		let o = Object.assign({}, data, upd);
		this.setState({ experience: o });
	}

	async onSubmit(formData) {
		if (!formData) { return; }

		var params = utils.extend(true, {}, formData);

		// Set publish date
		if (this.state.originalExperience.status !== CONSTANTS.ExperienceStatus.Public &&
			params.status === CONSTANTS.ExperienceStatus.Public && !params.isLive) {
			params.publishDate = new Date().toISOString();
		}

		let cont = await this.beforeSaveValidation(params);
		if (!cont) { return; }

		// fetch imagesNames if needed
		params.imagesNames = await this.fetchImagesNamesForUpdate(params.images);

		var res;
		var mb = messageBox('Updating...', '', messageBox.Buttons.OK, true);
		var req = api.experience.updateExperience(params);
		try {
			res = await req.send();
		} catch (e) {
			logger.error('Error updating experience:', e);
			mb.setTitle('Error updating experience', false);
			mb.setBody(e.message);
			return;
		}

		logger.trace('Got update experience response:', res);
		var experience = utils.extend(true, {}, res.experience);
		mb.setBody('experience updated successfully', false);

		if (!this.state.experience.id) {
			window.history.pushState(null, null, `/experience/${experience.id}`);
		}

		this.reloadExperience(experience);
	}

	// #endregion EventHandlers

	// #region Component Lifecycle

	componentDidMount() {
		this.load();
	}

	componentDidUpdate(prevProps) {
		if (this.props.params.eid === prevProps.params.eid) {
			return;
		}

		this.load();
	}

	// #endregion Component Lifecycle

	// #region Render

	renderToolbar(experience) {
		let expAsset = experience?.sessions?.[0]?.video?.id ?? '';
		return (
			<Toolbar onAction={this.onToolClick}>
				<ToolbarIcon oper="Diff" size="medium" name="track_changes">Diff</ToolbarIcon>
				{this.state.experience?.id && <ToolbarIcon oper="Clone" size="medium" name="content_copy">Clone</ToolbarIcon>}
				<ToolbarIcon oper="Save" size="medium" name="save">Save</ToolbarIcon>
				<ToolbarIcon oper="DeepLink" size="medium" name="link">Deep Link</ToolbarIcon>
				<ToolbarIcon oper="Space" size="medium" name="chat_bubble">Space</ToolbarIcon>
				{expAsset && <ToolbarIcon oper="Summary" size="medium" name="pages" outlined>Summary</ToolbarIcon>}
			</Toolbar>
		);
	}

	render() {
		let experience = this.state.experience;
		if (!experience) {
			return <div>Loading...</div>;
		}

		return (
			<BasePage title="Experience" className="exp-page" toolbar={this.renderToolbar(experience)} toolbarOpen={true}>
				<Form
					ref={f => { this.form = f; }}
					schema={ExperienceSchema}
					uiSchema={ExperienceSchemaUI}
					formData={experience}
					customUIFields={this.customFields}
					showButtons={false}
					onChange={this.onChange}
					submit={this.onSubmit}
					showErrorMessageBox={true}
					outlinedFields
				/>
				<JSONModal open={this.state.showJsonModal} diff={true} original={this.state.originalExperience} data={experience} onClose={() => this.setState({ showJsonModal: false })} />
			</BasePage>
		);
	}

	// #endregion Render
}

ExperiencePage.propTypes = {
	params: PropTypes.object,
	searchParams: PropTypes.object
};

export default function WrappedExperiencePage(props) {
	let params = useParams();
	let [searchParams] = useSearchParams();
	return <ExperiencePage params={params} searchParams={searchParams} {...props} />;
}
