import { compileSchema } from 'ui-core';

/** @type {import("@rjsf/utils").RJSFSchema} */
var schema = {
	title: 'Paper Background',
	type: 'object',
	required: ['name', 'thumbnail', 'file'],
	properties: {
		id: { type: 'string', readOnly: true },
		name: { type: 'string' },
		thumbnail: { $ref: '#/definitions/uri' },
		file: {
			type: 'string',
			pattern: '^(https?:\/\/.*\.pdf)?$'
		}
	}
};

schema = compileSchema(schema);

export default schema;
