import React from 'react';
import PropTypes from 'prop-types';
import { InfiniteScroll, MaterialIcon } from 'ui-core';
import AssetGalleryTile from '../asset-gallery-tile/asset-gallery-tile.jsx';
import clsx from 'clsx';

import './asset-gallery-view.css';

class AssetGalleryView extends React.Component {
	constructor(props) {
		super(props);
	}

	commit() {}

	renderGallery(assets) {
		let { allowSelect, allowDelete, onTitleChanged, onSelectedToggle, onDelete } = this.props;
		let showTools = allowSelect || allowDelete;
		return (
			<div className='gallery'>
				{assets.map(asset => (
					<div key={asset.id} className='item'>
						<AssetGalleryTile asset={asset} onTitleChanged={(newVal, id) => {onTitleChanged(newVal, id);}}/>
						<div className={clsx('selectorBackdrop', { 'no-select': !showTools, selected: asset.isSelected })}>
							{allowSelect && <div className='selector' onClick={() => onSelectedToggle(asset.id)}>
								<MaterialIcon name="check" />
							</div>}
							{allowDelete && !asset.isDeleted && !asset.isSelected && <div className='selector del' onClick={() => onDelete(asset.id, true)}>
								<MaterialIcon name="delete" />
							</div>}
							{allowDelete && asset.isDeleted && !asset.isSelected && <div className='selector' onClick={() => onDelete(asset.id, false)}>
								<MaterialIcon name="restore_from_trash" />
							</div>}
						</div>
					</div>
				))}
			</div>
		);
	}

	renderNoResults() {
		return (
			<div className='no-results'>
				<div>No media found, try to search for something else...</div>
				<div>Hint: you can search for `*` to see everything (but we do not recommend it...)</div>
				<div>Or Tap the `+` above to upload new file</div>
			</div>
		);
	}

	render() {
		const {assets, hasMore} = this.props;

		return (
			<div className='asset-gallery-view'>
				<InfiniteScroll scrollOn=".asset-gallery-view" hasMore={hasMore} loadMore={this.props.loadMore}>
					{assets.length > 0 && this.renderGallery(assets)}
					{assets.length < 1 && this.renderNoResults()}
				</InfiniteScroll>
			</div>
		);
	}
}

const ASSET_VIEW_MODEL = PropTypes.arrayOf(PropTypes.shape({
	id: PropTypes.string,
	src: PropTypes.string,
	thumbnail: PropTypes.string,
	title: PropTypes.string,
	type: PropTypes.string,
	isDeleted: PropTypes.bool,
	isSelected: PropTypes.bool,
}));

AssetGalleryView.propTypes = {
	assets: ASSET_VIEW_MODEL,
	hasMore: PropTypes.bool,
	allowSelect: PropTypes.bool,
	allowDelete: PropTypes.bool,
	loadMore: PropTypes.func,
	onSelectedToggle: PropTypes.func,
	onDelete: PropTypes.func,
	onTitleChanged: PropTypes.func,
};

function nop() { }
AssetGalleryView.defaultProps = {
	assets: [],
	hasMore: false,
	allowSelect: true,
	allowDelete: false,
	loadMore: nop,
	onSelectedToggle: nop,
	onDelete: nop,
	onTitleChanged: nop,
};

export default AssetGalleryView;
