import React from 'react';
import { BasePage } from 'app-center-common';
import { Button } from '@mui/material';
import { messageBox } from 'ui-core';
import { api, logger } from 'client-services';

class LivePage extends React.Component {
	constructor(props) {
		super(props);
	}

	async resetStreamKey(showResult) {
		let mb = messageBox('Resetting stream key...', null, messageBox.Buttons.OK, true);
		logger.trace('Calling reset stream key api');
		try {
			let res = await api.event.resetLiveChannelStreamKey().send();
			if (!res?.streamKey) {
				logger.error('Error resetting stream key, no `streamKey` on response nor error');
				mb.setTitle('Error', false);
				mb.setBody('Got invalid response from api server, no `streamKey`');
				return;
			}

			mb.setTitle('Success', false);
			if (showResult) {
				mb.setBody(res.streamKey);
			}
		} catch (ex) {
			logger.error('Error resetting stream key, error:', ex);
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}
	}

	render() {
		return (
			<BasePage title="Live Config">
				<Button variant="contained" color="secondary" style={{marginRight: 10}} onClick={() => this.resetStreamKey()}>Stop Live</Button>
				<Button variant="contained" color="primary" onClick={() => this.resetStreamKey(true)}>Start Live</Button>
			</BasePage>
		);
	}
}

export default LivePage;
