import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from '../../../lib/constants.js';
import { ToolbarIcon, snackbar, messageBox } from 'ui-core';
import MediaAssetModal from '../../media-asset-picker/media-asset-modal/media-asset-modal.jsx';
import AssetPickerPreview from '../../media-asset-picker/asset-picker-preview.jsx';
import { api, utils } from 'client-services';

function SingleMediaAssetField(props) {
	var [pickerOpen, setPickerOpen] = useState(false);
	var { formData, registry, schema, uiSchema } = props;
	var TitleField = registry.templates.TitleFieldTemplate;

	// Compute the title
	var title;
	if (uiSchema['ui:titleField'] && formData[uiSchema['ui:titleField']]) {
		title = formData[uiSchema['ui:titleField']];
	} else {
		title = uiSchema['ui:title'] || schema.title || '';
	}

	var options = uiSchema['ui:mediaAsset'] || {};

	function onClose(mediaAsset) {
		if (!mediaAsset) {
			setPickerOpen(false);
			return;
		}

		if (options.roundDuration) {
			mediaAsset.duration = Math.round(mediaAsset.duration || 0);
		}

		var resProp = options.resultProp;
		if (!resProp || typeof mediaAsset[resProp] === 'undefined') {
			props.onChange(mediaAsset);
		} else {
			props.onChange(mediaAsset[resProp]);
		}

		setPickerOpen(false);
	}

	async function onExpMediaAssetInfo() {
		let mb = messageBox('Loading...', null, null, true);
		try {
			let asset = await api.mediaAsset.getMediaAsset({ id: formData.id, isExperience: true }).send();
			mb.setTitle('Asset Info', false);
			mb.setBody(<pre>{JSON.stringify(asset, null, '  ')}</pre>);
		} catch (ex) {
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}
	}

	let src = options.previewProp && formData ? formData[options.previewProp] : formData;
	return (
		<div>
			<TitleField title={
				<div>
					<span style={{marginRight: 15, verticalAlign: 'middle'}}>{title}</span>
					<ToolbarIcon key="library_add" name="library_add" title="Manage" onClick={e => {
						e.stopPropagation();
						setPickerOpen(true);
					}} />
					{src && <ToolbarIcon outlined key="content_copy" name="content_copy" title="Copy URL" onClick={e => {
						if (utils.copyToClipboard(src)) {
							snackbar('URL Copied');
						}
					}} />}
					{options.isExperience && formData?.id && <ToolbarIcon outlined key="info" name="info" title="Info" onClick={onExpMediaAssetInfo} />}
				</div>}
			/>
			<AssetPickerPreview
				type={options.assetType || CONSTANTS.AssetTypes.Image}
				src={src}
				onClick={() => setPickerOpen(true)}
			/>
			<MediaAssetModal
				open={pickerOpen}
				isExperience={!!options.isExperience}
				mode={options.openMode || CONSTANTS.AssetViewModes.Edit}
				assetTypes={[options.assetType || CONSTANTS.AssetTypes.Image]}
				maxFileSize={options.maxFileSize}
				onClose={onClose}
			/>
		</div>
	);
}

SingleMediaAssetField.propTypes = {
	registry: PropTypes.shape({
		templates: PropTypes.object.isRequired,
	}).isRequired,
	formData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	schema: PropTypes.object,
	uiSchema: PropTypes.object,
	onChange: PropTypes.func
};

export default SingleMediaAssetField;
