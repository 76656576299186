.image-editor-toolbar {
	margin-bottom: 1px;
}

.opts-wrap .text-num {
	border: none;
	border-bottom: 1px solid #cccccc;
	outline: none;
}

.image-editor-toolbar .tool-text i {
	position: relative;
}

.image-editor-toolbar .tool-text i:after {
	font-size: 8px;
	font-family: roboto, sans-serif;
	position: absolute;
	top: 100%;
	margin-top: -1px;
	left: 50%;
	transform: translateX(-50%);
}

.image-editor-toolbar .tool-text.svg i:after {
	content: "SVG";
}

.image-editor-toolbar .tool-text.png i:after {
	content: "PNG";
}
