import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from '../../../../lib/constants.js';
import { ToolbarIcon } from 'ui-core';
import MediaAssetModal from '../../../media-asset-picker/media-asset-modal/media-asset-modal.jsx';
import AssetPickerPreview from '../../../media-asset-picker/asset-picker-preview.jsx';
import { utils } from 'client-services';
import { makeStyles } from 'tss-react/mui';

const IMG_MAX_SIZE = 2 * 1024 * 1024; // 2MB
const VID_MAX_SIZE = 1000 * 1024 * 1024; // 1GB

const useClasses = makeStyles()(theme => ({
	typeTitle: {
		color: '#646464',
		borderBottom: '1px solid #ccc',
		marginBottom: 10,
	},
	preview: {
		display: 'flex',
		flexDirection: 'row',
		'& > div': {
			marginRight: 40
		}
	}
}));

function MediaUIElement(props) {
	let { formData, onChange } = props;
	let { classes } = useClasses();
	var [pickerType, setPickerType] = useState('');
	var { type } = formData;

	function onAssetPickerClose(mediaAsset) {
		if (!mediaAsset?.url) {
			setPickerType(''); // will close the picker modal
			return;
		}

		let data = {
			aspectRatio: undefined,
			params: {}
		};

		if (type === CONSTANTS.FeedItemUIElementType.FullCardImage) {
			if (pickerType === CONSTANTS.AssetTypes.Image) {
				data.aspectRatio = mediaAsset.aspectRatio;
				data.params.url = mediaAsset.thumbnail || mediaAsset.url;
			}
		} else {
			if (pickerType === CONSTANTS.AssetTypes.Image) {
				data.aspectRatio = mediaAsset.aspectRatio;
				data.params.poster = mediaAsset.thumbnail || mediaAsset.url;
			} else if (pickerType === CONSTANTS.AssetTypes.Video) {
				data.params.url = mediaAsset.url;
			}
		}

		onChange(utils.extend(true, {}, formData, data));

		setPickerType('');
	}

	return (
		<>
			<div className={classes.preview}>
				<MediaField
					uiElement={formData}
					assetType={CONSTANTS.AssetTypes.Image}
					openPicker={setPickerType}
				/>
				{type === CONSTANTS.FeedItemUIElementType.FullCardVideo &&
					<MediaField
						uiElement={formData}
						assetType={CONSTANTS.AssetTypes.Video}
						openPicker={setPickerType}
					/>
				}
			</div>
			<MediaAssetModal
				open={!!pickerType}
				mode={CONSTANTS.AssetViewModes.Edit}
				assetTypes={[pickerType]}
				maxFileSize={pickerType === CONSTANTS.AssetTypes.Image ? IMG_MAX_SIZE : VID_MAX_SIZE}
				onClose={onAssetPickerClose}
			/>
		</>
	);
}

MediaUIElement.propTypes = {
	formData: PropTypes.object,
	onChange: PropTypes.func
};

// #region MediaField

function MediaField(props) {
	let { classes } = useClasses();
	var { uiElement, assetType, openPicker } = props;
	var { url, poster } = (uiElement.params || {});
	var src = url;
	if (uiElement.type === CONSTANTS.FeedItemUIElementType.FullCardVideo && assetType === CONSTANTS.AssetTypes.Image) {
		src = poster;
	}

	var title = (assetType === CONSTANTS.AssetTypes.Image) ? 'Poster' : 'Video';
	return (
		<div>
			<div className={classes.typeTitle}>
				<span>{title}</span>
				<ToolbarIcon key="library_add" name="library_add" title="Manage" onClick={e => {
					e.stopPropagation();
					openPicker(assetType);
				}} />
			</div>
			<AssetPickerPreview
				type={assetType}
				src={src}
				onClick={() => openPicker(assetType)}
			/>
		</div>
	);
}

MediaField.propTypes = {
	uiElement: PropTypes.object.isRequired,
	assetType: PropTypes.string.isRequired,
	openPicker: PropTypes.func.isRequired
};

// #endregion MediaField

export default MediaUIElement;
