.blending-page {
	width: 70%;
	padding: 5% 0 20px 0;
	margin: auto;
}

@media print {
	.blending-page {
		width: 100%;
	}

	.blending-page .recipe {
		page-break-inside: avoid; /* For printing purposes */
	}
}

.blending-page .instructions {
	display: flex;
	align-items: center;
	border-bottom: 1px dashed;
	padding-bottom: 20px;
}

.blending-page .instructions .details {
	flex: 1;
	overflow: auto;
}

.blending-page .instructions .details .title {
	font-weight: bold;
	font-size: 30px;
	text-transform: uppercase;
	color: #E53B78;
}

.blending-page .instructions .details .explain {
	font-size: 19px;
	margin-top: 20px;
}

.blending-page .instructions .spoons {
	width: 160px;
	margin-left: 30px;
}

.blending-page .instructions .spoons > img {
	width: 100%;
}

.blending-page .recipe {
	border-bottom: 1px dashed;
	padding: 20px 0;
	display: flex;
	align-items: center;
}

.blending-page .recipe .mixture {
	flex: 1;
	overflow: auto;
}

.blending-page .recipe .mixture .part {
	display: flex;
	padding: 10px 0;
	font-size: 18px;
}

.blending-page .recipe .mixture .part > div:first-child {
	flex: 0.5;
}

.blending-page .recipe .mixture .part .frac {
	font-weight: bold;
	font-size: 20px;
}

.blending-page .small-box {
	width: 20px;
	height: 20px;
	border-radius: 4px;
	border: solid 1px #282829;
}

.blending-page .large-box {
	width: 120px;
	height: 120px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	text-align: center;
	color: #ffffff;
}

.blending-page .large-box.bright {
	color: #000000;
}
