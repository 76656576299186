import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery } from '@mui/material';
import { MaterialIcon } from 'ui-core';
import { Gallery as GridGallery } from 'react-grid-gallery';
import { logger } from 'client-services';

var IMAGE_ROW_HEIGHT = 360;
var IMAGES_MARGIN = 10;
var LODS = ['Beginner', 'Intermidiate', 'Advanced'];

class GalleryGrid extends React.PureComponent {
	constructor(props) {
		super(props);

		this.onToolsClick = this.onToolsClick.bind(this);
	}

	onToolsClick(ev) {
		ev.preventDefault();
		ev.stopPropagation();
		var oper = ev.target.getAttribute('data-oper');
		if (!oper) {
			logger.trace('Tools div clicked but no data-oper property found, element:', ev.target);
			return;
		}

		var elem = ev.target.parentElement;
		while (elem && !elem.hasAttribute('data-image-id')) {
			elem = elem.parentElement;
		}

		if (!elem) {
			logger.error('Tool icon clicked but not parent element with data-image-id property found, target element:', ev.target);
			return;
		}

		var imgId = elem.getAttribute('data-image-id');
		if (!imgId) {
			logger.error('Got operation on image element with no data-image-id prop. element:', elem);
			return;
		}

		logger.trace('Tool `%s` clicked for image `%s`', oper, imgId);
		if (this.props.onAction) {
			this.props.onAction(oper, imgId);
		}
	}

	renderOverlay(img) {
		if (!this.props.desktop) { return null; }
		return (
			<div className="img-overlay" data-image={img.id}>
				<div className="title">
					<div>
						<span><a href={`/gallery/image/${img.id}`} target="_blank" rel="noopener noreferrer">{img.name}</a></span>
						{img.lod < 9999 ? <span className="lod">[{LODS[img.lod - 1]}]</span> : null}
					</div>
					<div className="img-tools" data-image-id={img.id} onClick={this.onToolsClick}>
						<MaterialIcon name="file_download" data-oper="Download" title="Download" />
						<MaterialIcon name="filter_3" data-oper="DownloadAll" title="Download All Files" />
						<MaterialIcon name="mode_edit" data-oper="Edit" title="Edit" />
						<MaterialIcon name="delete" data-oper="Delete" title="Delete" />
					</div>
				</div>
				<div>
					by: {img.artist ? img.artist.name : ''}
				</div>
				<div>
					{img.groups.map(g => <div className="chip img-group" key={g}>{g}</div>)}
				</div>
				<div>
					{img.tags.map(t => <div className="chip" key={t}>{t}</div>)}
				</div>
			</div>
		);
	}

	render() {
		var images = this.props.images.map(image => {
			return {
				// src: image.artwork || image.photo,
				src: image.thumbnail,
				caption: image.name,
				width: image.width || 100,
				height: image.height || 100,
				customOverlay: this.renderOverlay(image)
			};
		});

		return (
			<GridGallery
				enableImageSelection={false}
				images={images}
				rowHeight={IMAGE_ROW_HEIGHT}
				margin={IMAGES_MARGIN}
				onClick={this.props.onClick}
			/>
		);
	}
}

GalleryGrid.propTypes = {
	images: PropTypes.array.isRequired,
	showPoster: PropTypes.bool,
	desktop: PropTypes.bool,
	onAction: PropTypes.func,
	onClick: PropTypes.func
};

GalleryGrid.defaultProps = {
	onClick: () => { }
};

export default function WrappedGalleryGrid(props) {
	let theme = useTheme();
	let desktop = useMediaQuery(theme.breakpoints.up('sm'));
	return <GalleryGrid desktop={desktop} {...props} />;
}
