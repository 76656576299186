import {default as ERRORS, Severity} from '../audit-errors.js';

// Validates image resolution per kit definition
async function run(data, config) {
	validateInput(data);
	var errors = [];

	var div = document.createElement('div');
	div.innerHTML = data.svg;

	var svgElem = div.querySelector('svg');
	if (!svgElem) {
		return {pass: false, error: 'svg text is not an svg element'};
	}

	var longResolution = Math.max(...data.kit.cubes) * data.kit.cubeResolution;
	var shortResolution = Math.min(...data.kit.cubes) * data.kit.cubeResolution;

	var severity;
	var viewBox = svgElem.viewBox.baseVal;
	if (viewBox.width >= viewBox.height) {
		severity = config.severities[ERRORS.viewBoxWidth.name];
		if (severity !== Severity.Ignore && viewBox.width !== longResolution) {
			errors.push(ERRORS.viewBoxWidth(severity).formatMessage(longResolution, viewBox.width));
		}

		severity = config.severities[ERRORS.viewBoxHeight.name];
		if (severity !== Severity.Ignore && viewBox.height !== shortResolution) {
			errors.push(ERRORS.viewBoxHeight(severity).formatMessage(shortResolution, viewBox.height));
		}
	} else {
		severity = config.severities[ERRORS.viewBoxWidth.name];
		if (severity !== Severity.Ignore && viewBox.width !== shortResolution) {
			errors.push(ERRORS.viewBoxWidth(severity).formatMessage(shortResolution, viewBox.width));
		}

		severity = config.severities[ERRORS.viewBoxHeight.name];
		if (severity !== Severity.Ignore && viewBox.height !== longResolution) {
			errors.push(ERRORS.viewBoxHeight(severity).formatMessage(longResolution, viewBox.height));
		}
	}

	return {name: run.Rule, errors};
}

run.Rule = 'Image Resolution';

function validateInput(data) {
	if (typeof data.svg === 'undefined') {
		throw new Error('No `svg` provided');
	}

	if (!data.kit || !Array.isArray(data.kit.cubes) || data.kit.cubes.length !== 2) {
		throw new Error('No `kit.cubes` array provided');
	}

	if (!data.kit.cubeResolution) {
		throw new Error('no `kit.cubeResolution` provided');
	}
}

export default run;
