 .image-dropzone {
	height: 90%;
	width: 90%;
	box-sizing: border-box;
}

.image-dropzone .drop-zone.empty {
	border: none;
}

.image-dropzone .drop-wrap {
	position: relative;
	height: 100%;
}

.image-dropzone .drop-wrap > img {
	z-index: 1;
	position: absolute;
	top: 0;
    left: 50%;
    transform: translate(-50%, 0);
	background-color: white; /* for transparent images */
	max-width: 100%;
	max-height: 100%;
	pointer-events: none; /* for prevent error while dragging img */
}
