import CONSTANTS from '../../lib/constants.js';

/** @type {import("@rjsf/utils").UiSchema} */
var schema = {
	avatar: {
		'ui:field': 'singleMediaAsset',
		'ui:mediaAsset': {
			assetType: CONSTANTS.AssetTypes.Image,
			maxFileSize: 2 * 1024 * 1024, // 2MB
			resultProp: 'thumbnail'
		}
	}
};

export default schema;