import React from 'react';
import { Form as CoreForm } from 'ui-core';
import * as Fields from './custom-fields';

const CUSTOM_FIELDS = {
	singleMediaAsset: Fields.SingleMediaAssetField,
	singleEntityPicker: Fields.SingleEntityPickerField,
	multiEntityPicker: Fields.MultiEntityPickerField
};

function Form(props, ref) {
	// eslint-disable-next-line react/prop-types
	let { customUIFields, ...rest } = props;
	var customFields = Object.assign({}, CUSTOM_FIELDS, customUIFields);

	return <CoreForm ref={ref} customUIFields={customFields} {...rest} />;
};

export default React.forwardRef(Form);
