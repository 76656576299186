import React from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from '../../lib/constants.js';
import { useParams } from 'react-router-dom';
import { BasePage } from 'app-center-common';
import * as Fields from './custom-fields';
import { messageBox, JSONModal, Toolbar, ToolbarIcon } from 'ui-core';
import Form from '../form/form.jsx';
import { api, logger, utils } from 'client-services';

import FeedItemSchema from './feed-item.schema.js';
import FeedItemSchemaUI from './feed-item.ui.schema.js';

class FeedItemPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			feedItem: null,
			originalFeedItem: null,
			showJsonModal: false
		};

		this.customFields = {
			uiElement: Fields.UIElementField,
		};

		this.form = null;
		this.feed = null;
		this.onOpenDiff = this.onOpenDiff.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	// #region Methods

	async load() {
		var fid = this.props.params.fid; // feed id
		var fiid = this.props.params.fiid; // feed item id
		if (!fid || !fiid) {
			return;
		}

		try {
			this.feed = await this.loadFeed(fid);
			var newFeedItem = await this.loadFeedItem(fiid);
			this.reloadFeedItem(newFeedItem);
		} catch (ex) {
			messageBox('Error', ex.message);
			return;
		}
	}

	async loadFeed(fid) {
		if (fid === CONSTANTS.HOME_FEED_ID) {
			return { id: CONSTANTS.HOME_FEED_ID, name: 'Home' };
		}

		var res = await api.feed.getFeed({ id: fid }).send();
		if (!res.feed || !res.feed.id || !res.feed.name) {
			logger.error('Got invalid feed response:', res);
			throw new Error('Got invalid feed');
		}

		return res.feed;
	}

	async loadFeedItem(fiid) {
		if (fiid === 'new') {
			return { id: '' };
		}

		logger.trace('Fetching feedItem:', fiid);
		var res = await api.feed.getFeedItem({ id: fiid }).send();
		if (!res.feedItem || !res.feedItem.id) {
			logger.error('Got invalid feed item response:', res);
			throw new Error('Got invalid feed item');
		}

		return res.feedItem;
	}

	reloadFeedItem(feedItem) {
		// In case this is a new item we will want to first unmount all the components and then re-render the new feedItem
		if (this.state.feedItem) {
			this.setState({ feedItem: null }, () => {
				this.reloadFeedItem(feedItem);
			});

			return;
		}

		var originalFeedItem = utils.extend(true, {}, feedItem);
		this.setState({ feedItem, originalFeedItem });
	}

	preSaveWarnings(formData) {
		if (!formData) { return; };
		var warnings = [];
		if (formData.uiElement) {
			switch (formData.uiElement.type) {
				case CONSTANTS.FeedItemUIElementType.FullCardImage:
					this.checkCloudinaryUrl(formData.uiElement.params.url, 'image url', warnings);
					break;
				case CONSTANTS.FeedItemUIElementType.FullCardVideo:
					this.checkCloudinaryUrl(formData.uiElement.params.poster, 'video poster', warnings);
					break;
				case CONSTANTS.FeedItemUIElementType.Strip:
					if (formData.type !== CONSTANTS.FeedItemType.ExperienceFeed &&
						formData.type !== CONSTANTS.FeedItemType.Feed) {
						warnings.push('UI Element `Strip` can be set only for feed item of type `ExperienceFeed` or `Feed`');
					}
					break;
				case CONSTANTS.FeedItemUIElementType.Slideshow:
					if (formData.type !== CONSTANTS.FeedItemType.Feed) {
						warnings.push('UI Element `Slideshow` can be set only for feed item of type `Feed`');
					}
					break;
				case CONSTANTS.FeedItemUIElementType.FeaturedCard:
					if (formData.type !== CONSTANTS.FeedItemType.ExperienceFeed) {
						warnings.push('UI Element `FeaturedCard` can be set only for feed item of type `ExperienceFeed`');
					}
					break;
			}
		}

		return warnings;
	}

	checkCloudinaryUrl(url, prop, warnings) {
		if (typeof url !== 'string' || !url) { return; }
		if (!/^https?:\/\/res\.cloudinary\.com/.test(url)) { return; } // make sure its cloudinary url
		if (!/^https:\/\//.test(url)) {
			warnings.push(`Prop: ${prop} - Cloudinary urls should start with https`);
		}

		// If image make sure has t_feed_poster transformation
		if (/\.(jpg|png)$/.test(url) && !/\/t_feed_poster\//.test(url)) {
			warnings.push(`Prop: ${prop} - Cloudinary images should have t_feed_poster transformation, like: .../image/upload/t_feed_poster/v1...`);
		}
	}

	// #endregion Methods

	// #region EventHandlers

	onOpenDiff() {
		this.setState({ feedItem: this.form.getData(), showJsonModal: true });
	}

	async onSubmit(formData) {
		if (!formData) { return; }
		var warnings = this.preSaveWarnings(formData);
		if (warnings.length > 0) {
			var msg = <div>{warnings.map((w, i) => <div key={i}>{w}</div>)}</div>;
			let res = await messageBox('Warning, do you want to save anyway?', msg, [messageBox.Buttons.No, messageBox.Buttons.Yes]).promise;
			if (res === messageBox.Buttons.No) { return; }
		}

		var params = utils.extend(true, {}, formData);
		params.parent = this.feed.id;
		var res;
		var mb = messageBox('Updating...  ', '', messageBox.Buttons.OK, true);
		var req = api.feed.updateFeedItem(params);
		try {
			res = await req.send();
		} catch (e) {
			logger.error('Error updating feedItem:', e);
			mb.setTitle('Error updating feedItem', false);
			mb.setBody(e.message);
			return;
		}

		logger.trace('Got update feedItem response:', res);
		var feedItem = utils.extend(true, {}, res.feedItem);
		mb.setBody('feedItem updated successfully', false);

		if (!this.state.feedItem.id) {
			window.history.pushState(null, null, `/feed/${this.feed.id}/item/${feedItem.id}`);
		}

		this.reloadFeedItem(feedItem);
	}

	// #endregion EventHandlers

	// #region Component Lifecycle

	componentDidMount() {
		this.load();
	}

	componentDidUpdate(prevProps) {
		if (this.props.params.fid === prevProps.params.fid &&
			this.props.params.fiid === prevProps.params.fiid) {
			return;
		}

		this.load();
	}

	// #endregion Component Lifecycle

	// #region Render

	renderToolbar() {
		return (
			<Toolbar>
				<ToolbarIcon name="track_changes" onClick={this.onOpenDiff}>Diff</ToolbarIcon>
				<ToolbarIcon name="save" onClick={() => this.form && this.form.submit()}>Save</ToolbarIcon>
			</Toolbar>
		);
	}

	render() {
		var feedItem = this.state.feedItem;
		if (!feedItem) {
			return <div>Loading...</div>;
		}

		var feed = this.feed ? this.feed.name : 'N/A';
		return (
			<BasePage
				title={`Feed Item - ${feed}`}
				toolbar={this.renderToolbar()}
				toolbarOpen={true}
			>
				<Form
					ref={f => { this.form = f; }}
					schema={FeedItemSchema}
					uiSchema={FeedItemSchemaUI}
					formData={feedItem}
					customUIFields={this.customFields}
					showButtons={false}
					submit={this.onSubmit}
					outlinedFields
					showErrorList="bottom"
				>
					<div />
				</Form>
				<JSONModal open={this.state.showJsonModal} diff={true} original={this.state.originalFeedItem} data={feedItem} onClose={() => this.setState({ showJsonModal: false })} />
			</BasePage>
		);
	}

	// #endregion Render
}

FeedItemPage.propTypes = {
	params: PropTypes.object
};

export default function WrappedFeedItemPage(props) {
	let params = useParams();
	return <FeedItemPage params={params} {...props} />;
}
