import CONSTANTS from '../../lib/constants.js';
import ExperienceFormRenderer from './custom-fields/experience-form-renderer.jsx';

/** @type {import("@rjsf/utils").UiSchema} */
var schema = {
	// @ts-ignore
	'ui:ObjectFieldTemplate': ExperienceFormRenderer,
	'ui:order': [
		'name',
		'slug',
		'instructor',
		'status',
		'publishDate',
		'isLive',
		'creationTabs',
		'accessLevel',
		'spacePostAccessLevel',
		'personalPhotoAccessLevel',
		'description',
		'lod',
		'shareWithPartners',
		'thumbnail',
		'thumbnailLarge',
		'preview',
		'images',
		'tools',
		'paperBackgrounds',
		'sessions',
		'forEvent',
		'enableTrace',
		'kind',
		'featuredBanner',
		'featuredPosterField',
		'suggestedAudience',
		'artworkType',
		'tags',
		'medium',
		'atmosphere',
		'colors',
		'artStyle',
		'artists',
		'id',
	],
	name: {
		'ui:placeholder': 'Paint Your Pet'
	},
	slug: {
		'ui:help': 'Website experience url: https://cupixel.com/experience/{slug}'
	},
	publishDate: {
		'ui:help': 'Will be set automatically upon publish, or can be set manually'
	},
	accessLevel: {
		'ui:help': 'Who can START this experience'
	},
	spacePostAccessLevel: {
		'ui:help': 'Who can POST to the experience space. Locked means no one can.'
	},
	description: {
		'ui:help': 'Can use HTMLish syntax here',
		'ui:placeholder': 'Paint your pet using special techniques you will find here only!\n<br>\nAnyone can do it\n<br>\nCandy can do it!',
		'ui:widget': 'textarea',
		'ui:options': { rows: 5 }
	},
	thumbnail: {
		'ui:field': 'singleMediaAsset',
		'ui:mediaAsset': {
			assetType: CONSTANTS.AssetTypes.Image,
			maxFileSize: 1 * 1024 * 1024,
			resultProp: 'urlTemplate'
		},
	},
	thumbnailLarge: {'ui:widget': 'hidden'},
	preview: {
		'ui:field': 'expPreview'
	},
	creationTabs: {
		'ui:widget': 'checkboxes',
		'ui:help': 'Which tabs to enable on Creation screen',
		'ui:options': {
			inline: true
		}
	},
	instructor: {
		'ui:field': 'singleEntityPicker',
		'ui:options': { picker: 'InstructorPicker' }
	},
	images: {
		'ui:field': 'multiEntityPicker',
		'ui:options': { picker: 'GalleryPicker' }
	},
	paperBackgrounds: {
		'ui:field': 'multiEntityPicker',
		'ui:options': { picker: 'PaperBackgroundPicker' },
		'ui:help': 'PDF surfaces to print at home'
	},
	tools: {
		'ui:field': 'toolsList',
	},
	sessions: {
		items: {
			'ui:field': 'collapsible',
			'ui:titleField': 'name',
			'ui:cardTitle': 'Creation Video',
			'ui:showItemIndex': true,
			'ui:expanded': false,
			'ui:order': ['video', 'introDuration', 'name', 'thumbnail', 'chapters', 'id'],
			id: {
				'ui:widget': 'hidden'
			},
			video: {
				'ui:field': 'singleMediaAsset',
				'ui:mediaAsset': {
					assetType: CONSTANTS.AssetTypes.Video,
					isExperience: true,
					maxFileSize: 3000 * 1024 * 1024, // 3GB
					roundDuration: true,
					previewProp: 'url'
				},
			},
			name: {
				'ui:widget': 'hidden',
				'ui:help': 'Only relevant for a multi-session experience',
			},
			thumbnail: {
				'ui:widget': 'hidden',
				'ui:help': 'Only relevant for a multi-session experience',
			},
			chapters: {
				'ui:widget': 'hidden',
				items: {
					'ui:field': 'collapsible',
					'ui:titleField': 'name',
					'ui:title': '',
					'ui:expanded': false,
					start: {
						'ui:help': 'Start position in seconds'
					}
				}
			}
		}
	},
	kind: {
		'ui:seo': true,
		'ui:help': 'For search/reports purposes'
	},
	enableTrace: {
		'ui:seo': true,
		'ui:help': 'Whether to enable the Artwork/Trace tabs on Creation',
	},
	suggestedAudience: { 'ui:seo': true },
	artworkType: {
		'ui:seo': true,
		'ui:widget': 'checkboxes',
		'ui:help': 'The "main" object of the artwork',
		'ui:options': {
			inline: true
		}
	},
	forEvent: { 'ui:seo': true },
	tags: { 'ui:field': 'autocomplete', 'ui:seo': true},
	atmosphere: { 'ui:field': 'autocomplete', 'ui:seo': true},
	colors: { 'ui:field': 'autocomplete', 'ui:seo': true},
	medium: { 'ui:field': 'autocomplete', 'ui:seo': true},
	artStyle: { 'ui:field': 'autocomplete', 'ui:seo': true },
	artists: { 'ui:field': 'autocomplete', 'ui:seo': true },
	featuredBanner: { 'ui:seo': true },
	featuredPosterField: { 'ui:seo': true },
	id: {'ui:seo': true}
};

export default schema;