.exp-filter-config {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
}

.exp-tags-src {
    flex: 2;
}

.exp-tags-src .title {
	font-weight: bold;
    text-align: center;
}

.exp-tags-trg {
    flex: 1;
}

.exp-tags-trg .title {
	font-weight: bold;
    text-align: center;
}

.srcList {
    cursor: grab;
    height: 100%;
    overflow: auto;
}

.srcItem {
    display: inline-grid;
    margin: 5px 5px 5px 5px;
    cursor: grab;
    font-size: 20px;
    user-select: none;
}

.trgList {
    user-select: none;
    height: 100%;
    padding-top: 30px;
    padding-left: 5px;
}

.trgItem {
    display: inline-grid;
    margin: 5px 5px 5px 5px;
    cursor: grab;
    font-size: 20px;
    user-select: none;
}