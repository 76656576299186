import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { UICoreOptionsProvider } from 'ui-core';
import Main from './layout/main/main.jsx';

const muiTheme = createTheme ({
	palette: {
		primary: {
			main: '#2196F3',
			dark: '#1976D2',
			light: '#BBDEFB'
		},
		secondary: {
			main: '#FF4081',
			dark: '#FF4081',
			light: '#FF4081'
		}
	},
	components: {
		MuiAppBar: {
			styleOverrides: {
				colorPrimary: {
					backgroundColor: '#FF4081'
				}
			}
		}
	},
	typography: {
		subtitle2: {
			fontSize: '1rem'
		}
	}
});

var App = () => (
	<ThemeProvider theme={muiTheme}>
		<UICoreOptionsProvider options={{modalWrapperId: 'modalWrapper'}}>
			<Main />
			<div id="modalWrapper" />
		</UICoreOptionsProvider>
	</ThemeProvider>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
