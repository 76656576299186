import React from 'react';
import PropTypes from 'prop-types';
import SortableList, { SortableItem } from 'react-easy-sort';
import {Card} from 'ui-core';
import LayerElement from './layer-element/layer-element.jsx';
import { arrayMoveImmutable } from '../../../../lib/immutable-utils.js';

import './image-layers.css';

class ImageLayers extends React.Component {
	constructor(props) {
		super(props);

		this.imageLayersComponent = React.createRef();
		this.state = {
			layers: [...this.props.layers, {}] // always add one extra place holder for "new" layer element
		};

		this.onLayerNameChanged = this.onLayerNameChanged.bind(this);
		this.onLayerChanged = this.onLayerChanged.bind(this);
		this.onLayerDeleted = this.onLayerDeleted.bind(this);
		this.onSortEnd = this.onSortEnd.bind(this);
	}

	onLayerNameChanged(text, idx) {
		let {layers} = this.state;
		layers[idx].name = text;

		this.setState({layers});
	}

	onLayerChanged(file, idx) {
		let {layers} = this.state;
		layers[idx].file = file;
		layers[idx].url = file.preview;

		// if drop img to the "new" element then should create new extra place holder for new layer element
		if (idx === (layers.length - 1)) {
			layers = [...layers, {}];
		}

		this.setState({layers});
	}

	onLayerDeleted(idx) {
		let {layers} = this.state;
		layers.splice(idx,1);

		this.setState({layers});
	}

	arraysAreEqual(ary1,ary2) {
		return (ary1.join('') === ary2.join(''));
	}

	componentDidUpdate(prevProps) {
		if (this.arraysAreEqual(this.props.layers, prevProps.layers)) {
			return;
		}

		let layers = [...this.props.layers, {}]; // always add one extra place holder for "new" layer element

		this.setState({layers});
	}

	onSortEnd(oldIndex, newIndex) {
		let {layers} = this.state;
		layers = arrayMoveImmutable(layers, oldIndex, newIndex);

		this.setState({layers});
	}

	// return all layers but the last one which is the "add new layer"
	getData() {
		let layers = [...this.state.layers];
		layers.splice(layers.length - 1, 1);

		return layers;
	}

	render() {
		const { layers } = this.state;
		const layersCount = layers.length - 1;

		return (
			<Card
				className='layers-container'
				title={this.props.title}
				expandable={false}
				expanded={true}
			>
				<div id="LayersWrapper" className="layers-wrap">
					<SortableList
						onSortEnd={this.onSortEnd}
						className="list"
						draggedItemClassName="dragged">
						{layers.map((v, i) => (
							i < layersCount ?
								<SortableItem key={'sortableLayerItem' + i}>
									<div>
										<LayerElement
											key={'layerElement' + i}
											url= {v.url}
											name= {v.name}
											elementKey={i}
											onDelete= {() => this.onLayerDeleted(i)}
											onLayerAdded= {this.onLayerChanged}
											onLayerNameChange= {this.onLayerNameChanged} />
									</div>
								</SortableItem>
								:
								<div key={i} className='newLayerElement'>
									<LayerElement
										key='NewLayerElement'
										name= {v.name}
										disableDelete = {true}
										elementKey={i}
										onLayerAdded= {this.onLayerChanged}
										onLayerNameChange= {this.onLayerNameChanged}
									/>
								</div>
						))}
					</SortableList>
				</div>
			</Card>
		);
	}
}

ImageLayers.propTypes = {
	title: PropTypes.string,
	layers: PropTypes.arrayOf(PropTypes.object)
};

ImageLayers.defaultProps = {
	title: '',
	layers: []
};

export default ImageLayers;
