import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import SortableList, { SortableItem } from 'react-easy-sort';
import { snackbar, MaterialIcon } from 'ui-core';
import { utils } from 'client-services';
import clsx from 'clsx';

const useClasses = makeStyles()(theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		userSelect: 'none'
	},
	item: {
		position: 'relative',
		margin: 8,
		'&:hover .selectorBackdrop': {
			background: 'linear-gradient(180deg, #00000050, transparent)'
		},
		'&:hover .selectorBackdrop.selected': {
			background: 'transparent'
		},
		'&:hover .selector': {
			opacity: 0.7,
		}
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		cursor: 'grab',
		userSelect: 'none',
		width: 'min-content',

		'&.no-drag': {
			cursor: 'default'
		},

		'.imageWrap': {
			position: 'relative',
			height: 150,

			'.image': {
				flex: 1,
				pointerEvents: 'none',
				maxHeight: '100%'
			},
			'.copyImage': {
				position: 'absolute',
				top: 5,
				left: 5,
				fontSize: '1.0em',
				cursor: 'pointer',
				opacity: 0,
				color: 'white',
				backgroundColor: '#00000090',
				width: '24px',
				height: '24px',
				borderRadius: '50%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				'& > i': {
					fontSize: 'inherit'
				}
			},
			'&:hover .copyImage': {
				opacity: 1
			},
			'.noImgDiv': {
				border: '1px solid',
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				padding: 10,
				boxSizing: 'border-box',
				backgroundColor: '#f9f9f9',
			},
		},

		'&.compact': {
			'.imageWrap': {
				height: 50,

				'.copyImage': {
					display: 'none'
				},
			},

			'.title': {
				display: 'none'
			}
		}
	},
	selectorBackdrop: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		height: 30,
		'&.hidden': {
			display: 'none'
		},
		'&.selected .selector': {
			backgroundColor: '#4285f4',
			opacity: 1.0,
		},
		'&.selected .selector > i': {
			color: 'white'
		}
	},
	selector: {
		backgroundColor: '#eeeeee',
		borderRadius: '100%',
		width: 20,
		height: 20,
		padding: 1,
		margin: 5,
		cursor: 'pointer',
		opacity: 0,
		'& i': {
			fontSize: 20,
			color: '#999999'
		},
	},
	dragged: {
		boxShadow: '-7px 7px 6px 0px rgb(0 0 0 / 20%)',
		padding: 5,
		zIndex: 2000
	}
}));

function copyImageToClipboard(src) {
	utils.copyToClipboard(src).then(() => snackbar('Image copied'));
}

function EntityGallery(props) {
	let { classes } = useClasses();
	var { images = [], allowDrag = false, allowSelect = true, compact, onSortEnd, onSelectedToggle } = props;
	return (
		<SortableList
			onSortEnd={(oldIdx, newIdx) => onSortEnd?.(oldIdx, newIdx)}
			className={classes.root}
			draggedItemClassName={classes.dragged}
			allowDrag={allowDrag}
		>
			{images.map(img => (
				<SortableItem key={img.id}>
					<div className={classes.item}>
						<div className={clsx(classes.content, { 'no-drag': !allowDrag, compact })}>
							<div className="imageWrap">
								{img.src && <img className="image" src={img.src} alt={img.title} />}
								{img.src && <div className="copyImage" onClick={() => copyImageToClipboard(img.src)}><MaterialIcon name="content_copy" /></div>}
								{!img.src && <div className="noImgDiv">{ img.title }</div>}
							</div>
							<div className="title">{img.title || '(no name)'}</div>
						</div>
						<div className={clsx(classes.selectorBackdrop, 'selectorBackdrop', { hidden: !allowSelect, selected: img.isSelected })}>
							<div className={clsx(classes.selector, 'selector')} onClick={() => onSelectedToggle?.(img.id, !img.isSelected)}>
								<MaterialIcon name="check" />
							</div>
						</div>
					</div>
				</SortableItem>
			))}
		</SortableList>
	);
}

const IMAGE_PROPS = PropTypes.arrayOf(PropTypes.shape({
	id: PropTypes.string,
	src: PropTypes.string,
	title: PropTypes.string,
	isSelected: PropTypes.bool,
}));

EntityGallery.propTypes = {
	images: IMAGE_PROPS,
	allowDrag: PropTypes.bool,
	allowSelect: PropTypes.bool,
	compact: PropTypes.bool,
	onSortEnd: PropTypes.func,
	onSelectedToggle: PropTypes.func,
};

export default EntityGallery;
