import React from 'react';
import PropTypes from 'prop-types';
import { utils } from 'client-services';

function DefaultUIElementParams(props) {
	let { formData, registry, schema, uiSchema, errorSchema, onChange } = props;
	var SchemaField = registry.fields.SchemaField;

	return (
		// Render the aspectRatio / params fields
		<>
			<SchemaField
				{...props}
				formData={formData.aspectRatio}
				schema={schema.properties.aspectRatio}
				uiSchema={uiSchema.aspectRatio}
				errorSchema={errorSchema?.aspectRatio}
				onChange={data => onChange(utils.extend(true, {}, formData, { aspectRatio: data || '' }))}
			/>
			<SchemaField
				{...props}
				formData={formData.params}
				schema={schema.properties.params}
				uiSchema={uiSchema.properties?.params}
				errorSchema={errorSchema?.properties?.params}
				onChange={data => onChange(utils.extend(true, {}, formData, { params: data }))}
			/>
		</>
	);
}

DefaultUIElementParams.propTypes = {
	schema: PropTypes.object.isRequired,
	uiSchema: PropTypes.object.isRequired,
	errorSchema: PropTypes.object,
	registry: PropTypes.shape({
		templates: PropTypes.object.isRequired,
		fields: PropTypes.object.isRequired,
	}).isRequired,
	formData: PropTypes.object,
	onChange: PropTypes.func
};

export default DefaultUIElementParams;
