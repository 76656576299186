import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {messageBox} from 'ui-core';
import SpacePostsList from './space-posts-list/space-posts-list.jsx';
import {api, logger} from 'client-services';

function CommentsList(props) {
	let [loading, setLoading] = useState(false);
	let [comments, setComments] = useState(props.comments || []);

	async function loadPostComments(id) {
		setLoading(true);
		logger.trace('Fetching spacePost %s with all its comments.', id);
		try {
			let spacePost = (await api.spaces.getFullSpacePostsById({ id }).send());
			logger.trace('Got spacePost:', spacePost);

			setComments(spacePost.comments);
		} catch (ex) {
			logger.error('Error fetching the spacePost:', ex);
			messageBox('Error fetching the spacePost', ex.message);
		}
		setLoading(false);
	}

	useEffect(() => {
		if (Array.isArray(props.comments)) { return; }
		if (!props.postId) {
			throw new Error('Must provide at least `comments` or `postId`');
		}

		loadPostComments(props.postId);

	}, [props.postId, props.comments]);

	if (loading) {
		return <div>Loading...</div>;
	}

	if (comments.length < 1) {
		return <div>No Comments</div>;
	}

	return (
		<SpacePostsList
			spacePosts={comments}
			asCommentsList={true}
			onDelete={props.onDelete}
		/>
	);
}

CommentsList.propTypes = {
	postId: PropTypes.string,
	comments: PropTypes.arrayOf(PropTypes.object),
	onDelete: PropTypes.func
};

export default CommentsList;
