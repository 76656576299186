import React from 'react';
import { BasePage } from 'app-center-common';
import MediaAssetController from '../media-asset-picker/media-asset-controller.jsx';

import './media-library-page.css';

class MediaLibraryPage extends React.Component {

	render() {
		return (
			<BasePage title="Media Library" className="media-library-page">
				<MediaAssetController
					allowSelect={false}
					allowDelete={true}
					maxFileSize={1000 * 1024 * 1024} // 1GB
				/>
			</BasePage>
		);
	}
}

export default MediaLibraryPage;
