import React from 'react';
import EntityCRUDPage from '../entity-crud-page/entity-crud-page.jsx';
import GridHelper from './paperBackground-grid-helpers.jsx';
import { api } from 'client-services';

import PaperBackgroundSchema from './paperBackground.schema.js';
import PaperBackgroundSchemaUI from './paperBackground.ui.schema.js';

class PaperBackgroundsPage extends React.Component {
	constructor(props) {
		super(props);
	}

	async getPaperBackgrounds(params) {
		var res = await api.experience.getPaperBackgrounds(params).send();
		res.entities = res.paperBackgrounds;
		return res;
	}

	render() {
		return (
			<EntityCRUDPage
				name="paperBackground"
				pageTitle="Paper Backgrounds"
				columns={GridHelper.getColumns()}
				schema={PaperBackgroundSchema}
				uiSchema={PaperBackgroundSchemaUI}
				apiGetMulti={this.getPaperBackgrounds.bind(this)}
				apiUpdate={api.experience.updatePaperBackground}
				apiAdd={api.experience.addPaperBackground}
				apiDelete={api.experience.deletePaperBackground}
				rowHeight={GridHelper.rowHeight}
				{...this.props}
			/>
		);
	}
}

export default PaperBackgroundsPage;
