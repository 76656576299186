import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { BasePage } from 'app-center-common';
import {messageBox, Form, ToolbarIcon} from 'ui-core';
import {api, logger} from 'client-services';
import ImageDropZone from './image-dropzone/image-dropzone.jsx';
import ImageLayers from './image-layers/image-layers.jsx';
import ImageSchema from './image.schema.js';
import ImageSchemaUI from './image.ui.schema.js';

import './image-page.css';

var IMG_FIELDS = ['name', 'lod', 'artwork', 'artworkExternalId', 'photo', 'photoExternalId', 'layers', 'groups', 'tags', 'artist'];

class ImagePage extends React.Component {
	constructor(props) {
		super(props);

		this.form = null;
		this.imageLayersComponent = null;
		this.originalImages = [];
		this.state = {
			id: '',
			artwork: null,
			layers: [],
			detailsForm: this.getDetailsForm()
		};

		this.setArtistSearchProps();
		this.setTagsSearchProps();

		this.onArtworkAdded = this.onArtworkAdded.bind(this);
		this.onDetailsFormChanged = this.onDetailsFormChanged.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	// #region Private Methods

	getDetailsForm(image) {
		image = image || {};
		return {
			id: image.id || '',
			name: image.name || '',
			lod: image.lod || 9999,
			artist: image.artist || null,
			groups: image.groups || [],
			tags: image.tags || []
		};
	}

	setArtistSearchProps() {
		var artistSearchProps = {
			autocompleteProps: {
				onSearch: this.searchArtist.bind(this),
				getOptionLabel: a => typeof a === 'object' ? ((a.name || '') + (a.email ? ` (${a.email})` : '')) : a
			}
		};

		ImageSchemaUI.artist['ui:options'] = artistSearchProps;
	}

	setTagsSearchProps() {
		var tagsSearchProps = {
			autocompleteProps: {
				multiple: true,
				selectOnSpace: true,
				onSearch: this.searchTags.bind(this)
			}
		};

		ImageSchemaUI.groups['ui:options'] = tagsSearchProps;
		ImageSchemaUI.tags['ui:options'] = tagsSearchProps;
	}

	async load() {
		var id = this.props.params.imageId;
		if (!id || id === 'new') {
			this.setState({ artwork: {} });
			return;
		}

		var res;
		var image = null;

		var mb = messageBox('Fetching image data', '', null, true);
		try {
			logger.trace('Fetching image:', id);
			res = await api.gallery.getImage({id: id, fields: IMG_FIELDS}).send();
			image = res.image;
			logger.trace('Got image:', image);

			let layers = image.layers;
			let artwork = { url: image.artwork };
			let detailsForm = this.getDetailsForm(image);

			// save all images for deletion process
			this.originalImages = [];
			if (image.artwork !== '' && image.artworkExternalId !== '') {
				this.originalImages.push({
					url: image.artwork,
					externalId: image.artworkExternalId
				});
			}

			layers.forEach(layer => {
				if (layer.url !== '' && layer.externalId !== '') {
					this.originalImages.push({
						url: layer.url,
						externalId: layer.externalId
					});
				}
			});

			this.setState({id, artwork, layers, detailsForm});
		} catch (e) {
			logger.error('Error:', e);
			mb.setTitle('Error fetching image', false);
			mb.setBody(e.message);
		} finally {
			mb.close();
		}
	}

	async uploadLayers(layers, formData, mb, onError) {
		// should preserve images which are already saved until got an image to upload
		logger.trace('Getting unchanged layers');
		formData.layers = [];

		let pendingUpdate = true; // must call uploadFormData at least once to update form fields
		while (layers.length > 0) {
			if (typeof layers[0].file !== 'undefined') {
				let layer = layers.shift();
				formData.layers.push(layer);
				let res = await this.uploadFormData(formData).catch(onError);
				pendingUpdate = false;
				if (!res?.image) { return; }
				formData.id = res.image.id; // incase this was an insert set the id for subsequent updates

				// every cycle we should pass "saved layers" + "unsaved layers" so
				// we always need to update those 2 arrays with layers just been saved
				// except if we upload the last file
				if (layers.length > 0) {
					layers = res.image.layers.concat(layers);
					formData = {layers: []};
				}
			} else {
				let layer = layers.shift();
				if (!layer.url || typeof layer.url === 'undefined' || !layer.externalId || typeof layer.externalId === 'undefined') {
					logger.error(`something gone wrong while saving layer ${layer.name}`);
					mb.setTitle('Error updating image', false);
					mb.setBody('All layers must have an image');
					continue;
				}

				pendingUpdate = true;
				formData.layers.push(layer);
			}
		}

		if (pendingUpdate) {
			await this.uploadFormData(formData).catch(onError);
		}
	}

	deleteUnusedResources(layers) {
		let {id} = this.state;

		// new image so there are no unused images
		if (!id) {
			return;
		}

		let unusedResources = [];
		this.originalImages.forEach(img => {
			if (!layers.some(layer => layer.url === img.url) && img.url !== this.state.artwork.url) {
				unusedResources.push(img.externalId);
			}
		});

		logger.trace('Setting unused resources', unusedResources);
		return unusedResources;
	}

	async uploadFormData(data) {
		logger.trace('Image form submit request, data:', data);

		var formData  = new FormData();
		if (data.name) { formData.append('name', data.name); };
		if (typeof data.lod !== 'undefined') { formData.append('lod', data.lod); };
		if (typeof data.artist === 'string' && data.artist) { data.artist = {name: data.artist}; }
		if (typeof data.artist?.name !== 'undefined') { formData.append('artist[name]', data.artist.name); }
		if (typeof data.artist?.email !== 'undefined') { formData.append('artist[email]', data.artist.email); }

		// Gather groups
		if (data.groups?.length > 0) {
			data.groups.forEach((group, i) => {
				formData.append(`groups[${i}]`, group);
			});
		} else if (data.groups) {
			formData.append('groups[]', '');
		}

		// Gather tags
		if (data.tags?.length > 0) {
			data.tags.forEach((tag, i) => {
				formData.append(`tags[${i}]`, tag);
			});
		} else if (data.tags) {
			formData.append('tags[]', '');
		}

		if (data.deleteResources?.length > 0) {
			data.deleteResources.forEach((rsc,i) => {
				formData.append(`deleteResources[${i}]`, rsc);
			});
		}

		// File must be the last param
		if (data.layers?.length > 0) {
			data.layers.forEach((layer,i) => {
				if (typeof layer.file !== 'undefined') {
					formData.append(`layers[${i}][name]`, layer.name || '');
					formData.append('layers', layer.file);
				} else {
					formData.append(`layers[${i}][name]`, layer.name || '');
					formData.append(`layers[${i}][url]`, layer.url);
					formData.append(`layers[${i}][externalId]`, layer.externalId);
				}
			});
		}

		if (data.artwork) {
			formData.append('artwork', data.artwork);
		} else if (data.photo) {
			formData.append('photo', data.photo);
		}

		var req;
		if (!data.id) {
			req = api.gallery.insertImage(formData).send();
		} else {
			formData.append('id', data.id);
			req = api.gallery.updateImage(formData).send();
		}

		return req;
	}

	// #endregion Private Methods

	// #region EventHandlers

	onDetailsFormChanged(details, errors) {
		if (errors.length > 0) { return; }
		let detailsForm = Object.assign({}, this.state.detailsForm, details);
		this.setState({ detailsForm });
	}

	onArtworkAdded(file) {
		let artwork = Object.assign({}, this.state.artwork);
		artwork.file = file;
		artwork.url = file.preview;

		this.setState({artwork});
	}

	async searchArtist(search) {
		if (search.length < 2) {return [];}
		logger.trace('Searching for artist, search:', search);
		try {
			var res = await api.gallery.searchArtists({ q: search }).send();
			logger.trace('Got search result for artist, search `%s`, result:', search, res.artists);

			return res.artists.map(a => ({name: a.name, email: a.email || undefined}));
		} catch (err) {
			logger.error('Error searching artist, search `%s`, error:', search, err);
			return [];
		};
	}

	async searchTags(search) {
		if (search.length < 2) {return [];}
		logger.trace('Searching for tags, search:', search);
		try {
			var res = await api.gallery.searchTags({ q: search }).send();
			logger.trace('Got search result for tags, search `%s`, result:', search, res.tags);
			return res.tags || [];
		} catch (err) {
			logger.error('Error searching tags, search `%s`, error:', search, err);
			return [];
		}
	}

	async onSubmit(formData) {
		if (!formData) {
			return;
		}

		logger.trace('Start submit process');
		var mb = messageBox('Updating...', '', messageBox.Buttons.OK, true);

		var onError = err => {
			mb.setTitle('Error updating image', false);
			mb.setBody(`Error saving image, code: ${err.code}, message: ${err.message}`);
		};

		let layers = this.imageLayersComponent?.getData();
		let deleteResources = this.deleteUnusedResources(layers);

		formData.deleteResources = deleteResources;

		// set artwork only if artwork exist and new image or artwork is blob type (should upload new file)
		let artwork = this.state.artwork;
		if (artwork?.file) {
			logger.trace('Uploading artwork');
			formData.artwork = artwork.file;
			let res = await this.uploadFormData(formData).catch(onError);
			if (!res) {return;}

			// all form fields where already saved, no need to save them again
			// just start an update session for other changes
			formData = {id: res.image.id};
		};

		await this.uploadLayers(layers, formData, mb, onError);
		window.history.pushState(null, null, `/gallery/image/${formData.id}`);
		window.location.href = window.location.href; // reload page
	}

	// #endregion EventHandlers

	// #region Component Lifecycle

	componentDidMount() {
		this.load();
	}

	// #endregion Component Lifecycle

	// #region Render

	renderToolbar() {
		return (
			<ToolbarIcon name="save" title="Save" onClick={() => {this.form?.submit();}} />
		);
	}

	render() {
		let { artwork, detailsForm, layers } = this.state;
		if (!artwork) {
			return 'Loading...';
		}

		return (
			<BasePage
				title="Image Page"
				className="image-page"
				toolbar={this.renderToolbar()}
				toolbarOpen
			>
				<div className="details-section">
					<div className="details-form">
						<Form
							ref={f => { this.form = f; }}
							schema={ImageSchema}
							uiSchema={ImageSchemaUI}
							formData={detailsForm}
							showButtons={false}
							onChange={this.onDetailsFormChanged}
							submit={this.onSubmit}
						>
							<div />
						</Form>
					</div>
					<div className="artwork-file">
						<ImageDropZone
							url={(artwork.url)}
							onFileAdded={this.onArtworkAdded}
						/>
					</div>
				</div>
				<div className='image-page-layers-section'>
					<ImageLayers
						ref={imageLayers => { this.imageLayersComponent = imageLayers; }}
						title= 'layers'
						layers= {layers}
					/>
				</div>
			</BasePage>
		);
	}

	// #endregion Render
}

ImagePage.propTypes = {
	params: PropTypes.object
};

export default function WrappedImagePage(props) {
	let params = useParams();
	return <ImagePage params={params} {...props} />;
};
