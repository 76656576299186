import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { messageBox } from 'ui-core';
import clsx from 'clsx';
import {colors, storage} from 'client-services';

import './blending-page.css';

class BlendingPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			recipes: []
		};
	}

	// <editor-fold desc="// Methods {...}">
	// </editor-fold> // Methods

	// <editor-fold desc="// EventHandlers {...}">
	// </editor-fold> // EventHandlers

	// <editor-fold desc="// Lifecycle {...}">

	componentDidMount() {
		var id = this.props.params.id;
		if (!id) {
			return;
		}

		try {
			let recipes = storage.local.get(`blending_${id}`);
			storage.local.del(`blending_${id}`);
			recipes = JSON.parse(recipes);
			if (Array.isArray(recipes) && recipes.length > 0) {
				this.setState({recipes});
				return;
			}
		} catch (ignore) {/* ignore */}

		messageBox('Recipes not found in session storage', 'This page must be opened directly from the Image Editor');
	}

	// </editor-fold> // Lifecycle

	// <editor-fold desc="// Render {...}">

	renderRecipe(recipe) {
		var mixture = recipe.mixture || [];
		return (
			<div className="recipe" key={recipe.name}>
				<div className="mixture">
					{
						mixture.map(m =>
							m.spoons.map((s, i) =>
								<div className="part" key={`${m.tube.name}-${i}`}>
									<div>{this.renderSpoonSize(s.size, m.tube.name)}</div>
									<div className="small-box" style={{backgroundColor: m.tube.color}} />
								</div>
							)
						)
					}
				</div>
				<div className={clsx('large-box', {bright: colors.isBright(recipe.color)})} style={{backgroundColor: recipe.color}}>
					<span>{recipe.name}</span>
					<span><b>{recipe.code}</b></span>
				</div>
			</div>
		);
	}

	renderSpoonSize(size, tube) {
		return <span><span className="frac">{size === 5 ? '1' : `1\u2044${5 / size}`}</span> <b>tsp</b> of <b>{tube}</b></span>;
	}

	render() {
		if (this.state.recipes.length < 1) {return 'Loading...';}
		return (
			<div className="blending-page">
				<div className="instructions">
					<div className="details">
						<div className="title">Color Blending</div>
						<div className="explain">
							You will need to use the 4 measuring spoons for blending the colors for your painting.<br/>
							The paint tubes can be found in the Colors box. The other tools can be found in the Art Box.
						</div>
					</div>
					<div className="spoons">
						<img src="http://res.cloudinary.com/cupixel/image/upload/assets/spoons.png" />
					</div>
				</div>
				{this.state.recipes.map(r => this.renderRecipe(r))}
			</div>
		);
	}

	// </editor-fold> // Render
}

BlendingPage.propTypes = {
	params: PropTypes.object
};

export default function WrappedBlendingPage(props) {
	let params = useParams();
	return <BlendingPage params={params} {...props} />;
}
