import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery } from '@mui/material';
import { MaterialIcon } from 'ui-core';
import CONSTANTS from '../../lib/constants.js';
import { Gallery as GridGallery } from 'react-grid-gallery';
import { logger, utils } from 'client-services';

var IMAGE_ROW_HEIGHT = 360;
var IMAGES_MARGIN = 10;

class ExperienceGrid extends React.PureComponent {
	constructor(props) {
		super(props);

		this.onToolsClick = this.onToolsClick.bind(this);
	}

	onToolsClick(ev) {
		ev.preventDefault();
		ev.stopPropagation();
		var oper = ev.target.getAttribute('data-oper');
		if (!oper) {
			logger.trace('Tools div clicked but no data-oper property found, element:', ev.target);
			return;
		}

		var elem = ev.target.parentElement;
		while (elem && !elem.hasAttribute('data-exp-id')) {
			elem = elem.parentElement;
		}

		if (!elem) {
			logger.error('Tool icon clicked but not parent element with data-exp-id property found, target element:', ev.target);
			return;
		}

		var expId = elem.getAttribute('data-exp-id');
		if (!expId) {
			logger.error('Got operation on exp element with no data-exp-id prop. element:', elem);
			return;
		}

		logger.trace('Tool `%s` clicked for exp `%s`', oper, expId);
		if (this.props.onAction) {
			this.props.onAction(oper, expId);
		}
	}

	renderOverlay(exp) {
		if (!this.props.desktop) { return null; }
		let duration = exp.sessions?.[0]?.video?.duration;
		let vidUrl = exp.sessions?.[0]?.video?.url;
		var pub = typeof exp.status !== 'undefined' && exp.status !== CONSTANTS.ExperienceStatus.Draft;
		return (
			<div className="img-overlay" data-image={exp.id}>
				<div className="title">
					<span><a href={`/experience/${exp.id}`} target="_blank" rel="noopener noreferrer">{exp.name}</a></span>
					<MaterialIcon className="status-icon" name={pub ? 'visibility' : 'visibility_off'} />
				</div>
				{duration && <div className="duration">{ utils.secondsToString(duration) }</div>}
				<div className="tools" data-exp-id={exp.id} onClick={this.onToolsClick}>
					<MaterialIcon name="content_copy" title="Copy ID" data-oper="CopyID" />
					<MaterialIcon name="link" title="Copy Deep Link" data-oper="CopyDeepLink" />
					<MaterialIcon name="chat_bubble" title="Spaces" data-oper="Space" />
					<MaterialIcon name="mode_edit" title="Edit"  data-oper="Edit" />
					{vidUrl && <MaterialIcon name="movie" title="Copy video" data-oper="CopyVideoUrl" />}
					<MaterialIcon name="delete" title="Delete"  data-oper="Delete" />
				</div>
				<div>
					{exp.tags.map(t => <div className="chip" key={t}>{t}</div>)}
					{exp.medium.map(t => <div className="chip medium" key={t}>{t}</div>)}
				</div>
			</div>
		);
	}

	render() {
		var images = this.props.experiences.map(exp => {
			return {
				src: exp.thumbnail,
				caption: exp.shortName,
				width: (exp.poster && exp.poster.aspectRatio) ? exp.poster.aspectRatio * 100 : 100,
				height: 100,
				customOverlay: this.renderOverlay(exp)
			};
		});

		return (
			<GridGallery
				enableImageSelection={false}
				images={images}
				rowHeight={IMAGE_ROW_HEIGHT}
				margin={IMAGES_MARGIN}
				onClick={this.props.onClick}
			/>
		);
	}
}

ExperienceGrid.propTypes = {
	experiences: PropTypes.array.isRequired,
	desktop: PropTypes.bool,
	onAction: PropTypes.func,
	onClick: PropTypes.func
};

ExperienceGrid.defaultProps = {
	onClick: () => { }
};

export default function WrappedExperienceGrid(props) {
	let theme = useTheme();
	let desktop = useMediaQuery(theme.breakpoints.up('sm'));
	return <ExperienceGrid desktop={desktop} {...props} />;
}

