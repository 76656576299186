import React from 'react';
import { BasePage } from 'app-center-common';
import { messageBox, Grid, GridFormatters, AddButtonFAB, compileSchema } from 'ui-core';
import Form from '../form/form.jsx';
import { api, logger } from 'client-services';

let SEARCH_SCHEMA = {
	name: {},
	id: {$ref: '#/definitions/MongoObjectID', description: 'feed id'},
};

class FeedsGrid extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedFeedId: '',
			search: ''
		};

		this.grid = null;
		this.columns = [
			{ key: 'id@100', name: 'Id', formatter: GridFormatters.link('/feed/%s'),  },
			'name@200',
			{ key: 'protected@100', formatter: GridFormatters.checkbox() },
			'description',
		];

		this.loadMore = this.loadMore.bind(this);
		this.onNewFeed = this.onNewFeed.bind(this);
		this.onSelectionChange = this.onSelectionChange.bind(this);
		this.searchChanged = this.searchChanged.bind(this);
	}

	// #region Methods

	async loadMore(count, skip, sort) {
		logger.trace('Fetching feeds, count: %s, skip: %s', count, skip);
		let q = this.state.search;
		var res = await api.feed.getFeeds({q}).send();
		if (!res || !Array.isArray(res.feeds)) {
			messageBox('Error fetching feeds', 'Try to refresh the page');
			return [];
		}

		logger.trace('Got feeds:', res);
		return res.feeds;
	}

	async upsertFeed(formData = {}) {
		var insert = !formData.id;
		var mb;
		async function onFormClosed(data) {
			mb.close(50);
			if (!data) { return; }
			var mbb = messageBox('Creating feed...', null, null, true);
			try {
				if (insert) { delete data.id; }
				var req = insert ? api.feed.addFeed(data) : api.feed.updateFeed(data);
				let res = await req.send();
				if (!res.feed || !res.feed.id) {
					logger.error('Error creating new feed, invalid response:', res);
					throw new Error('Got invalid response nor error, check console');
				}

				this.setState({ selectedFeedId: '' }, () => this.grid.reset());
				mbb.close();
			} catch (ex) {
				mbb.setTitle('Error', false);
				mbb.setBody(ex.message);
			}
		}

		var schema = compileSchema({
			type: 'object',
			required: ['name'],
			properties: {
				id: { type: 'string' },
				name: { type: 'string', minLength: 1 },
				description: { type: 'string' },
				protected: { type: 'boolean', default: false }
			}
		});

		const uiSchema = {
			id: { 'ui:widget': 'hidden' }
		};

		var body =
			<Form
				formData={formData}
				schema={schema}
				uiSchema={uiSchema}
				submitText={ insert ? 'Add' : 'Edit' }
				submit={onFormClosed.bind(this)}
			/>;

		mb = messageBox('Copy to feed', body, []);
	}

	// #endregion Methods

	// #region Event Handlers

	onSelectionChange(selection) {
		var selectedFeedId = '';
		if (selection.length === 1) {
			selectedFeedId = selection[0]?.id ?? '';
		}

		this.setState({ selectedFeedId });
	}

	onNewFeed() {
		this.upsertFeed();
	}

	onUpdateFeed(feedId) {
		if (!feedId) { return; }
		var feed = this.grid.rows.find(f => f.id === feedId);
		if (!feed) { return; }
		this.upsertFeed(feed);
	}

	searchChanged(search) {
		this.setState({ search, selectedFeedId: '' }, () => this.grid.reset());
	}

	// #endregion Event Handlers

	// #region Render

	renderToolbar() {
		var items = [];
		if (this.state.selectedFeedId !== '') {
			items = [
				{
					key: 'edit',
					title: 'Edit',
					onClick: () => this.onUpdateFeed(this.state.selectedFeedId),
					icon: 'edit'
				}
			];
		}

		return items;
	}

	render() {
		return (
			<BasePage
				className="exp-feeds-grid"
				showSearch={true}
				search={this.state.search}
				searchFields={SEARCH_SCHEMA}
				onSearch={ this.searchChanged }
			>
				<Grid
					ref={g => { this.grid = g; }}
					columns={this.columns}
					keyCol="id"
					pageSize={200}
					onLoadMore={this.loadMore}
					fullScreen={true}
					enableRowSelect={true}
					selectOnClick={true}
					allowMultiSelect={false}
					toolbarItems={this.renderToolbar()}
					onSelectionChange={this.onSelectionChange}
				/>
				<AddButtonFAB onClick={this.onNewFeed} />
			</BasePage>
		);
	}

	// #endregion Render
}

FeedsGrid.propTypes = {};
FeedsGrid.defaultProps = {};

export default FeedsGrid;
