.space-posts-list.comment {
	margin-left: 20px;
}

.space-posts-list .post.comment {
	padding: 8px;
	border-bottom: none;
    background-color: #EBF7F3;
    border-radius: 10px;
}
