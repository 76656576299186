import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, Button } from '@mui/material';
import { MaterialIcon } from 'ui-core';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

var useStyles = makeStyles()({
	root: {
		position: 'relative',
		display: 'inline-block'
	},
	notification: {
		width: 15,
		height: 15,
		position: 'absolute',
		borderRadius: '50%',
		backgroundColor: 'red',
		border: '1px solid black',
		boxSizing: 'border-box',
		top: 0,
		right: 0,
		color: 'white',
		fontSize: '0.7rem',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	checkbox: {
		minWidth: 0
	},
	buttons: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingRight: 10
	},
	filter: {
		padding: 10,
		maxWidth: 400,
		display: 'flex',
		flexDirection: 'column',
		boxSizing: 'border-box',
		'& .title': {
			fontWeight: 'bold',
			textTransform: 'uppercase',
			fontSize: '0.8em',
			color: '#585858',
			marginTop: 10
		},
		'& .items': {
			display: 'flex',
			flexDirection: 'row',
			marginTop: 5,
			overflowX: 'auto',
			paddingBottom: 3,
			'&::-webkit-scrollbar': {
				height: 5
			},
			'&::-webkit-scrollbar-track': {
				background: '#ededed'
			},
			'&::-webkit-scrollbar-thumb': {
				background: 'lightblue'
			},

			'& .chip': {
				display: 'flex',
				alignItems: 'center',
				padding: 5,
				fontSize: '0.8em',
				backgroundColor: '#efefef',
				borderRadius: 5,
				cursor: 'pointer',
				whiteSpace: 'nowrap',
				userSelect: 'none',
				marginRight: 5,
				'&.selected': {
					backgroundColor: '#ff7373',
					color: 'white'
				}
			}
		},
		'& .btnApply': {
			marginTop: 10,
			marginLeft: 'auto'
		}
	}
});

function FilterPopup(props) {
	var { classes } = useStyles();
	var [open, setOpen] = useState(false);
	var [anchor, setAnchor] = useState(null);

	let { className, config, onFilterItemClick, onSubmit } = props;

	function onClose() {
		setOpen(false);
		onSubmit?.();
	}

	function onOpenPopup(e) {
		setAnchor(e.target);
		setOpen(true);
	}

	if (!config) {
		return null;
	}

	let filterCount = Object.values(config).flatMap(c => c).filter(i => !!i.selected).length;
	return (
		<div className={clsx(className, classes.root)}>
			<Button variant="text" startIcon={<MaterialIcon name="filter_list" />} onClick={onOpenPopup}>Filter</Button>
			{filterCount > 0 && <div className={classes.notification}>{filterCount}</div>}
			<Popover anchorEl={anchor} open={open} onClose={onClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
				<div className={classes.filter}>
					{Object.keys(config).map(c =>
						<div key={c}>
							<div className="title">{c}</div>
							<div className="items">
								{config[c].map((f, i) =>
									<div
										key={i}
										className={clsx('chip', {selected: !!f.selected})}
										onClick={() => onFilterItemClick(c, f)}>
										{f.name}
									</div>
								)}
							</div>
						</div>
					)}
					<Button className="btnApply" variant="contained" color="secondary" size="small" onClick={onClose}>Apply</Button>
				</div>
			</Popover>
		</div>
	);
}

FilterPopup.propTypes = {
	className: PropTypes.string,
	config: PropTypes.object,
	onFilterItemClick: PropTypes.func,
	onSubmit: PropTypes.func
};

export default FilterPopup;