.alt-color-preview {
	display: flex;
	flex-direction: column;
	border: 2px solid #e8e8e8;
}

.alt-color-preview.maximized {
	width: 100% !important; /* to override inline style */
	height: 100% !important;
}

.alt-color-preview > .image-wrap {
	overflow: auto; /* Prevent from growing out of parent */
}

.alt-color-preview > .processing {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.alt-color-preview span.label {
	margin-right: 5px;
}

.alt-color-preview span.label:not(:first-child) {
	margin-left: 5px;
}

.alt-color-preview input[type=number] {
	width: 40px;
	border: none;
	padding-left: 5px;
}
