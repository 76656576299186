import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Card, ToolbarIcon } from 'ui-core';
import * as EntityPickers from '../../entity-picker';

const HEADER_STYLE = { backgroundColor: '#efefef' };
const CONTENT_STYLE = { maxHeight: 400, overflow: 'auto', padding: 0, border: '1px solid lightgray', borderTop: 'none' };

function MultiEntityPickerField(props) {
	var [pickerOpen, setPickerOpen] = useState(false);
	var [expanded, setExpanded] = useState(false);
	var [compactPreview, setCompactPreview] = useState(false);
	var { formData, registry, schema, uiSchema } = props;
	formData = formData || [];
	var TitleField = registry.templates.TitleFieldTemplate;

	var title = uiSchema['ui:title'] || schema.title || '';
	var desc = uiSchema['ui:description'] || schema.description || '';
	var opts = uiSchema['ui:options'] || {};

	function onClose(selected) {
		if (Array.isArray(selected)) {
			var data = selected.map(g => g.id);
			props.onChange(data);
		}

		setPickerOpen(false);
	}

	let PickerComp = EntityPickers[opts.picker];
	if (!PickerComp) {
		throw new Error(`Picker ${opts.picker} not found, make sure to pass the picker under ui:options`);
	}

	return (
		<div>
			<TitleField title={title} />
			{desc && <div style={{ marginBottom: 10 }}>
				<Typography variant="caption" color="textSecondary">
					{desc}
				</Typography>
			</div>}
			<Card
				title={`${title} (${formData.length} selected)`}
				unmountOnExit={false}
				expanded={expanded}
				headerStyle={HEADER_STYLE}
				contentStyle={CONTENT_STYLE}
				onExpandChange={e => setExpanded(e)}
				titleActions={[
					<ToolbarIcon key="compact" name="background_dot_small" title="Change View" onClick={e => {
						e.stopPropagation();
						setCompactPreview(!compactPreview);
					}} />,
					<ToolbarIcon key="library_add" name="library_add" title="Manage" onClick={e => {
						e.stopPropagation(); // prevent card from closing
						setPickerOpen(true);
					}} />,
				]}
			>
				<PickerComp
					showPreSelectedPreview={expanded}
					preselectedPreviewCompactView={compactPreview}
					showPicker={pickerOpen}
					openPickerOnSelected={false}
					preSelected={formData}
					onCommit={onClose}
				/>
			</Card>
		</div>
	);
}

MultiEntityPickerField.propTypes = {
	registry: PropTypes.shape({
		templates: PropTypes.object.isRequired,
	}).isRequired,
	formData: PropTypes.array,
	schema: PropTypes.object,
	uiSchema: PropTypes.object,
	onChange: PropTypes.func
};

export default MultiEntityPickerField;
