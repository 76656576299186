import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {Toolbar, ToolbarIcon} from 'ui-core';

import {utils} from 'client-services';

import './event-details-row.css';

class EventDetailsRow extends React.Component {
	constructor(props) {
		super(props);
	}

	renderDetails(event) {
		return (
			<div className='details'>
				<div>
					<div>Enabled:</div><div><input type="checkbox" checked={event.enabled} readOnly disabled /></div>
				</div>
				<div>
					<div>Require Login:</div><div><input type="checkbox" checked={event.loginRequired} readOnly disabled /></div>
				</div>
				<div>
					<div>Description:</div><div>{event.description}</div>
				</div>
				<div>
					<div>Start Date:</div>
					<div>{utils.toDateString(event.startDate, false)} ({utils.clientTimezoneString()})</div>
				</div>
				<div>
					<div>End Date:</div>
					<div>{utils.toDateString(event.endDate, false)}{event.endDate ? ` (${utils.clientTimezoneString()})` : ''}</div>
				</div>
				<div>
					<div>Code:</div>
					<div>{event.unlockCode}</div>
				</div>
				{event.feed &&
					<div>
						<div>Feed:</div>
						<div>
							<Link to={'/experienceFeed/' + event.feed} target="_blank" >{event.feed}</Link>
						</div>
					</div>
				}
				<div>
					<div>ID:</div>
					<div className="gray">{event.id}</div>
				</div>
			</div>
		);
	}

	render() {
		var event = this.props.event;

		return (
			<div className='event-details-row'>
				<div className='info-pane'>
					<div className='title'>
						<span>{event.name}</span>
					</div>
					{this.renderDetails(event)}
					<Toolbar name={event.id} onAction={this.props.onAction}>
						<ToolbarIcon name="edit" title="Edit" oper="Edit"/>
						<ToolbarIcon name="delete" title="Delete" oper="Delete" style={{color: 'red'}}/>
					</Toolbar>
				</div>
			</div>
		);
	}
}

EventDetailsRow.propTypes = {
	event: PropTypes.object,
	onAction: PropTypes.func
};

EventDetailsRow.defaultProps = {
	onAction: function nop() {}
};

export default EventDetailsRow;
