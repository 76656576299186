import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'ui-core';
import { makeStyles } from 'tss-react/mui';

let useClasses = makeStyles()(theme => ({
	toolsEditor: {
		display: 'flex',
		flexDirection: 'row',
		'& .tools-input': {
			marginRight: 10,
			'& textarea': {
				display: 'block',
				width: '100%',
				boxSizing: 'border-box',
				padding: 5,
				outline: 'none'
			}
		},
		'& .tools-preview': {
			'& ul': {
				margin: 0,
				padding: '0px 15px'
			}
		}
	}
}));

const HEADER_STYLE = { backgroundColor: '#efefef' };
const CONTENT_STYLE = { maxHeight: 400, overflow: 'auto', padding: 0, border: '1px solid lightgray', borderTop: 'none' };

function ToolsListField(props) {
	var { formData, registry } = props;
	var TitleField = registry.templates.TitleFieldTemplate;
	formData = formData || [];
	var mandatoryTools = formData.filter(t => !t.optional).map(t => t.tool.name);
	var optionalTools = formData.filter(t => t.optional).map(t => t.tool.name);

	function onToolsChanged(tools, optional) {
		if (Array.isArray(tools)) {
			var notTouchedList = (optional ? mandatoryTools : optionalTools).map(t => ({ optional: !optional, tool: {name: t} }));
			var updatedList = tools.map(t => ({ optional, tool: {name: t} }));

			var data = [...notTouchedList, ...updatedList];
			props.onChange(data);
		}
	}

	return (
		<div>
			<TitleField title="Tools" />
			<div>
				<ToolsCard title="Recommended" tools={mandatoryTools} onChange={tools => onToolsChanged(tools, false)} />
			</div>
			<div style={{marginTop: 20}}>
				<ToolsCard title="Alternative" tools={optionalTools} onChange={tools => onToolsChanged(tools, true)} />
			</div>
		</div>
	);
}

ToolsListField.propTypes = {
	registry: PropTypes.shape({
		templates: PropTypes.object.isRequired,
	}).isRequired,
	formData: PropTypes.arrayOf(PropTypes.shape({
		tool: PropTypes.shape({
			name: PropTypes.string
		}).isRequired,
		optional: PropTypes.bool.isRequired
	})),
	onChange: PropTypes.func
};

export default ToolsListField;

// #region ToolsCard

function ToolsCard(props) {
	let { title, tools, onChange } = props;
	let [expanded, setExpanded] = useState(false);

	return (
		<Card
			title={`${title} (${tools.length})`}
			unmountOnExit={false}
			expanded={expanded}
			headerStyle={HEADER_STYLE}
			contentStyle={CONTENT_STYLE}
			onExpandChange={e => setExpanded(e)}
		>
			<ToolsEditor tools={tools} onChange={onChange} />
		</Card>
	);
}

ToolsCard.propTypes = {
	title: PropTypes.string,
	tools: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func
};

// #endregion ToolsCard

// #region ToolsEditor

function ToolsEditor(props) {
	let { tools, onChange } = props;
	let [text, setText] = useState(tools.map(t => `* ${t}`).join('\n'));
	let { classes } = useClasses();

	function onTextUpdate(e) {
		let val = e.target.value;
		setText(val);
		// remove any trailing '*'
		onChange(val.split('\n').map(t => t.replace(/^\*([\s]+)?/, '')).filter(String));
	}

	return (
		<div className={classes.toolsEditor}>
			<div className="tools-input">
				<textarea rows={10} cols={50} value={text} onChange={onTextUpdate} />
			</div>
			<div className="tools-preview">
				<ul>
					{tools.map((tool, i) => (
						<li key={i}>{tool}</li>
					))}
				</ul>
			</div>
		</div>
	);
}

ToolsEditor.propTypes = {
	tools: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func
};

// #endregion ToolsEditor