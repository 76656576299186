import {default as ERRORS, Severity} from '../audit-errors.js';
import {colors, SVGTools} from 'client-services';

// Validates the the svg file contains the Cupixel Illustrator Script stamp
async function run(data, config) {
	validateInput(data);
	var errors = [];
	var maxPaintAllowed = config.rules.colors.maxPaint;
	var maxColorsAllowed = config.rules.colors.maxColors;

	var paletteSvc = data.paletteSvc;

	var unknownColors = new Set();
	var invalidColorShapes = [];
	var transparentShapes = [];
	var overusedColors = [];
	var svg = new SVGTools(document, data.svg);

	var colorsStats = {};
	svg.itterateGeometries(e => {
		var style = window.getComputedStyle(e);
		if (hasOpacity(e)) {
			transparentShapes.push(e.id);
		}

		var c = style.fill.replace(/\s/g, '');
		if (!c || c === 'none') {return;}
		var hex = colors.css2hex(c);
		if (!paletteSvc.contains(hex)) {
			unknownColors.add(hex);
			invalidColorShapes.push(e.id);
		}

		var idx = colors.hex2int(hex);
		colorsStats[idx] = {hex, pixels: 0}; // must put pixels prop for calcStats to work
	});

	await svg.calcColorStats(colorsStats);
	Object.values(colorsStats).forEach(c => {
		if (c.pct >= maxPaintAllowed) {
			overusedColors.push(c.hex);
		}
	});

	var severity = config.severities[ERRORS.invalidColor.name];
	if (severity !== Severity.Ignore && invalidColorShapes.length > 0) {
		errors.push(ERRORS.invalidColor(severity, {ids: invalidColorShapes, colors: Array.from(unknownColors)}));
	}

	severity = config.severities[ERRORS.transparentShapes.name];
	if (severity !== Severity.Ignore && transparentShapes.length > 0) {
		errors.push(ERRORS.transparentShapes(severity, {ids: transparentShapes}));
	}

	severity = config.severities[ERRORS.tooMuchPaint.name];
	if (severity !== Severity.Ignore && overusedColors.length > 0) {
		var ids = svg.getElementsWithFill(overusedColors);
		errors.push(ERRORS.tooMuchPaint(severity, {ids, colors: overusedColors}));
	}

	severity = config.severities[ERRORS.tooMuchColors.name];
	if (severity !== Severity.Ignore && Object.keys(colorsStats).length > maxColorsAllowed) {
		errors.push(ERRORS.tooMuchColors(severity).formatMessage(maxColorsAllowed, Object.keys(colorsStats).length));
	}

	return {name: run.Rule, errors};
}

/**
 * Checks if the element has opacity including in its hierarchy
 * @param {SVGElement} e
 * @returns {boolean}
 */
function hasOpacity(e) {
	while (e) {
		var style = window.getComputedStyle(e);
		if (parseFloat(style.opacity) !== 1 || parseFloat(style.fillOpacity) !== 1) {
			return true;
		}

		if (e instanceof SVGSVGElement) {
			return false;
		}

		e = e.parentElement;
	}

	return false;
}

run.Rule = 'Colors';

function validateInput(data) {
	if (typeof data.svg === 'undefined') {
		throw new Error('No `svg` provided');
	}
}

export default run;
