import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import SortableList, { SortableItem } from 'react-easy-sort';
import { MaterialIcon } from 'ui-core';
import clsx from 'clsx';

const useClasses = makeStyles()(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		userSelect: 'none'
	},
	content: {
		alignSelf: 'flex-start',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		cursor: 'grab',
		userSelect: 'none',
		'&:hover': {
			cursor: 'pointer'
		},
		'& > div': {
			margin: 5
		}
	},
	selector: {
		backgroundColor: '#eeeeee',
		borderRadius: '100%',
		width: 20,
		height: 20,
		padding: 1,
		'&.selected': {
			backgroundColor: '#2196f3'
		},
		'&.selected > i': {
			color: 'white'
		},
		'& > i': {
			fontSize: 20,
			color: '#999999'
		},
	},
	imageWrap: {
		width: 35,
		height: 35
	},
	image: {
		flex: 1,
		pointerEvents: 'none',
		maxWidth: '100%',
		maxHeight: '100%'
	},
	dragged: {
		boxShadow: '-7px 7px 6px 0px rgb(0 0 0 / 20%)',
		padding: 5,
		zIndex: 2000
	}
}));

function EntityGrid(props) {
	let { classes } = useClasses();
	var { images, allowDrag, allowSelect, onSortEnd, onSelectedToggle } = props;
	return (
		<SortableList
			onSortEnd={onSortEnd}
			className={classes.root}
			draggedItemClassName={classes.dragged}
			allowDrag={allowDrag}
		>
			{images.map(img => (
				<SortableItem key={img.id}>
					<div className={classes.content} onClick={() => onSelectedToggle(img.id, !img.isSelected)}>
						<div className={clsx(classes.selector, {hidden: !allowSelect, selected: img.isSelected})}>
							<MaterialIcon name="check" />
						</div>
						{img.src && <div className={classes.imageWrap}>
							<img className={classes.image} src={img.src} alt={img.title} />
						</div>}
						<div>{img.title || '(no name)'}</div>
					</div>
				</SortableItem>
			))}
		</SortableList>
	);
}

const IMAGE_PROPS = PropTypes.arrayOf(PropTypes.shape({
	id: PropTypes.string,
	src: PropTypes.string,
	title: PropTypes.string,
	isSelected: PropTypes.bool,
}));

EntityGrid.propTypes = {
	images: IMAGE_PROPS,
	allowDrag: PropTypes.bool,
	allowSelect: PropTypes.bool,
	compact: PropTypes.bool,
	onSortEnd: PropTypes.func,
	onSelectedToggle: PropTypes.func,
};

function nop() { }
EntityGrid.defaultProps = {
	images: [],
	allowDrag: false,
	allowSelect: true,
	onSortEnd: nop,
	onSelectedToggle: nop
};

export default EntityGrid;
