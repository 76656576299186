import {default as ERRORS, Severity} from '../audit-errors.js';
import {config} from 'client-services';
// import Paper from 'paper';
// import MCWorker from 'worker-loader!./mc-worker.js';
import defer from 'defer-promise';

var modelsConfig = config.get('services:lod:models');

async function run(data, config) {
	// Dont run if no need
	if (config.severities[ERRORS.tinyShapes.name] === Severity.Ignore && config.severities[ERRORS.colorsPerMC.name] === Severity.Ignore) {
		return Promise.resolve({name: run.Rule, errors: []});
	}

	validateInput(data);
	// var json = await svgToJson(data.svg);

	var models = {};
	config.rules.mc.models.split(',').forEach(m => {
		var model = modelsConfig[m.trim()];
		if (!model) {
			throw new Error(`Model ${m.trim()} does not exist`);
		}

		models[m] = model;
	});

	var deferred = defer();

	// var mcWorker = new MCWorker();
	// mcWorker.postMessage({json, kit: data.kit, models, config});
	// mcWorker.onmessage = function(event) {
	// 	deferred.resolve({name: run.Rule, errors: event.data});
	// };

	return deferred.promise;
}

run.Rule = 'MCs';

// function svgToJson(svg) {
// 	return new Promise(res => {
// 		var paper = new Paper.PaperScope();
// 		paper.setup();
// 		paper.project.importSVG(svg, function svgLoaded() {
// 			var json = paper.project.exportJSON({asString: true});
// 			res(json);
// 		});
// 	});
// }

function validateInput(data) {
	if (typeof data.svg === 'undefined') {
		throw new Error('No `svg` provided');
	}

	if (!data.kit || !Array.isArray(data.kit.cubes) || data.kit.cubes.length !== 2) {
		throw new Error('No `kit.cubes` array provided');
	}

	if (!data.kit.cubeResolution) {
		throw new Error('no `kit.cubeResolution` provided');
	}
}

export default run;
